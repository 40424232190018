import React, { Component } from "react";
import Footer from "../../common/footer";
import Header from "../../common/header";
import VideoModal from "../../common/videoModal";
import Collaborative from "./collaborative";
import CollaborativeBottom from "./collaborativeBottom";
import Collections from "./collections";
import Faq from "./faq";
import Hero from "./hero";
import HowItWorks from "./howItWorks";
import Icon from "./icon";
import ImageGallery from "./imageGallery";
import Legendaries from "./legendaries";
import Rewards from "./rewards";
import RoadMap from "./roadMap";
import Synopsis from "./synopsis";
import TheProject from "./theProject";
import TheTeam from "./theTeam";
import Video from "./video";

class Home extends Component {
  state = {
    vidModal: false,
    menu: false,
  };

  toggleMenu = () => {
    this.setState({ menu: !this.state.menu }, () => {
      if (this.state.menu) {
        document.body.classList.add("show-menu");
        document.body.classList.add("overflow-hidden");
      } else {
        document.body.classList.remove("show-menu");
        document.body.classList.remove("overflow-hidden");
      }
    });
  };
  toggleModal = () => {
    this.setState({ vidModal: !this.state.vidModal }, () => {
      if (this.state.vidModal) {
        document.body.classList.add("modal-open");
      } else {
        document.body.classList.remove("modal-open");
      }
    });
  };
  render() {
    return (
      <>
        <div class="bg-wrapper-container ">
          <Header
            menu={this.state.menu}
            toggleMenu={this.toggleMenu}
            history={this.props.history}
          />
          <Hero history={this.props.history} />
        </div>
        <Video vidModal={this.state.vidModal} toggleModal={this.toggleModal} />
        <TheProject />
        <Collaborative />
        <div class="step-outer">
          <HowItWorks />
          <Synopsis />
        </div>
        <Collections />
        <ImageGallery />
        <Legendaries />
        <Rewards />
        <RoadMap />
        <CollaborativeBottom />
        <div
          class="has-bg-section"
          style={{ backgroundImage: "url('include/images/has-blue-bg.png')" }}
        >
          <Icon />
          <Faq />
        </div>
        <div class="bg-alter-container">
          <TheTeam />
          <Footer />
        </div>
        <VideoModal
          vidModal={this.state.vidModal}
          toggleModal={this.toggleModal}
        />
        {this.state.vidModal && (
          <div className="modal-backdrop fade show"></div>
        )}
      </>
    );
  }
}

export default Home;
