import React, { useState, useEffect, createRef } from "react";
import { ethers } from "ethers";
import Contract from "../web3/SuperWildClub.json";
import ContractRoyalties from "../web3/PaymentSplitterRoyalties.json";
import HeaderMint from "../../common/headerMint";
import Counter from "./Counter";
import { gsap } from "gsap";
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";

// Web3
const { MerkleTree } = require("merkletreejs");
const tokens = require("../web3/tokens.json");
const address = "0xb3076A1ef58Fc000C91AD147303c3a4ebf2E7c1e";
const addressRoyalties = "0x60dd5cb778b963777a3683A5a116fc42E232b4AB";
const owner = "0x7ec53b9e7cfbf70c602959ceb99dd20bb51aa13e"; // attention à respecter les miniscules
const crossmint = "before";
const priceCrossmint = crossmint === "whitelist" ? 20 : 25;

export default function Mint() {
  let imageRef = createRef(null);
  const [menu, setMenu] = useState(false);
  const [count, setCount] = useState(1);

  // Web3
  const [accounts, setAccounts] = useState([]);
  const [goodNetwork, setGoodNetwork] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [metamaskInstall, setMetamaskInstall] = useState(false);
  const [sellingStep, setSellingStep] = useState(null);
  const [whitelistPrice, setWhitelistPrice] = useState(0);
  const [publicPrice, setPublicPrice] = useState(0);
  const [maxSup, setMaxSup] = useState(null);
  const [currentTotalSupply, setCurrentTotalSupply] = useState(null);

  useEffect(() => {
    gsap.timeline().to(imageRef, {
      opacity: 1,
      scale: 1,
      duration: 0.6,
      delay: 1,
      ease: "power2.inOut",
    });
  }, [imageRef]);

  // const [counterNFT, setCounterNFT] = useState(1);
  const [counterStep, setCounterStep] = useState(0);
  // const [basketETH, setBasketETH] = useState(0);
  const [newWhitelistSalePrice, setNewWhitelistSalePrice] = useState(null);
  const [newPublicSalePrice, setNewPublicSalePrice] = useState(null);
  const [giftAddress, setGiftAddress] = useState(null);
  const [giftCounter, setGiftCounter] = useState(1);
  const [newMerkleRoot, setNewMerkleRoot] = useState(null);
  const [newBaseUri, setNewBaseUri] = useState(null);
  const [releaseToken, setReleaseToken] = useState(null);

  const toggleMenu = () => {
    setMenu({ menu: !menu }, () => {
      if (menu) {
        document.body.classList.add("show-menu");
        document.body.classList.add("overflow-hidden");
      } else {
        document.body.classList.remove("show-menu");
        document.body.classList.remove("overflow-hidden");
      }
    });
  };

  const pathname =
    typeof window !== undefined
      ? localStorage.getItem("lang")
        ? localStorage.getItem("lang")
        : "en"
      : "en";

  if (typeof window.ethereum !== "undefined") {
    window.ethereum.addListener("connect", async (response) => {
      getAccounts();
    });

    window.ethereum.on("accountsChanged", () => {
      window.location.reload();
    });

    window.ethereum.on("chainChanged", () => {
      window.location.reload();
    });

    window.ethereum.on("disconnect", () => {
      window.location.reload();
    });
  }

  useEffect(() => {
    async function getInfos() {
      if (typeof window.ethereum !== "undefined") {
        setMetamaskInstall(false);
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(address, Contract.abi, provider);
        try {
          const { chainId } = await provider.getNetwork();
          if (chainId !== 1) {
            setGoodNetwork(false);
            return;
          } else {
            setGoodNetwork(true);
            const step = await contract.sellingStep();
            setSellingStep(step);
            const priceWhitelistSale = await contract.whitelistSalePrice();
            setWhitelistPrice(priceWhitelistSale);
            const pricePublicSale = await contract.publicSalePrice();
            setPublicPrice(pricePublicSale);
            const maxSup = await contract.MAX_SUPPLY();
            setMaxSup(maxSup);
            const totalSupply = await contract.totalSupply();
            setCurrentTotalSupply(totalSupply);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setMetamaskInstall(true);
      }
    }

    getInfos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getAccounts() {
    if (typeof window.ethereum !== "undefined") {
      let accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      setAccounts(accounts);
    }
  }

  useEffect(() => {
    if (!metamaskInstall && goodNetwork) {
      getAccounts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goodNetwork, metamaskInstall]);

  // EVENTS
  useEffect(() => {
    if (typeof window.ethereum !== "undefined") {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(address, Contract.abi, provider);
      contract.on("Transfer", async () => {
        const totalSupply = await contract.totalSupply();
        setCurrentTotalSupply(totalSupply);
      });
    }
  }, [accounts]);

  async function setStep(_step) {
    if (typeof window.ethereum !== "undefined") {
      setLoading(true);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(address, Contract.abi, signer);

      try {
        const transaction = await contract.setStep(_step, {
          from: accounts[0],
        });
        await transaction.wait();
        setLoading(false);
      } catch (error) {
        console.log(error.message);
        setLoading(false);
      }
    }
  }

  async function whitelistSaleMint(_quantity) {
    if (typeof window.ethereum !== "undefined") {
      setLoading(true);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(address, Contract.abi, signer);

      let tab = [];
      tokens.map((token) => tab.push(token.address));
      const leaves = tab.map((address) => ethers.utils.keccak256(address));
      const tree = new MerkleTree(leaves, ethers.utils.keccak256, {
        sortPairs: true, // Attention sortPairs et non sort (crossmint)
      });
      const leaf = ethers.utils.keccak256(accounts[0]);
      const proof = tree.getHexProof(leaf);

      try {
        let overrides = {
          from: accounts[0],
          value: (whitelistPrice * _quantity).toString(),
        };
        const transaction = await contract.whitelistSaleMint(
          _quantity,
          proof,
          overrides
        );
        await transaction.wait();
        setLoading(false);
      } catch (error) {
        if (error.data) {
          setError(
            pathname === "en"
              ? "Metamask error: check your wallet, balance..."
              : pathname === "fr"
              ? "Erreur metamask: vérifiez votre wallet, balance..."
              : "Error metamask: compruebe su wallet, balanza..."
          );
          console.log(error.data.message);
        } else {
          setError(
            pathname === "en"
              ? "Error: check the correct functioning of your wallet, balance..."
              : pathname === "fr"
              ? "Erreur : vérifiez le bon fonctionnement de votre wallet, balance..."
              : "Error: compruebe el correcto funcionamiento de su wallet, balanza..."
          );
          console.log(error.message);
        }
        setLoading(false);
      }
    }
  }

  async function publicSaleMint(_quantity) {
    if (typeof window.ethereum !== "undefined") {
      setLoading(true);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(address, Contract.abi, signer);

      try {
        let overrides = {
          from: accounts[0],
          value: (publicPrice * _quantity).toString(),
          // value: ethers.utils.parseEther(calcul.toString()),
        };
        const transaction = await contract.publicSaleMint(_quantity, overrides);
        await transaction.wait();
        setLoading(false);
      } catch (error) {
        if (error.data) {
          setError(
            pathname === "en"
              ? "Metamask error: check your wallet, balance..."
              : pathname === "fr"
              ? "Erreur metamask: vérifiez votre wallet, balance..."
              : "Error metamask: compruebe su wallet, balanza..."
          );
          console.log(error.data.message);
        } else {
          setError(
            pathname === "en"
              ? "Error: check the correct functioning of your wallet, balance..."
              : pathname === "fr"
              ? "Erreur : vérifiez le bon fonctionnement de votre wallet, balance..."
              : "Error: compruebe el correcto funcionamiento de su wallet, balanza..."
          );
          console.log(error.message);
        }
        setLoading(false);
      }
    }
  }

  async function gift(_to, _quantity) {
    if (typeof window.ethereum !== "undefined") {
      setLoading(true);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(address, Contract.abi, signer);

      try {
        let overrides = {
          from: accounts[0],
        };
        const transaction = await contract.gift(_to, _quantity, overrides);
        await transaction.wait();
        setLoading(false);
      } catch (error) {
        console.log(error.message);
        setLoading(false);
      }
    }
  }

  async function setWhitelistSalePrice(_amount) {
    if (typeof window.ethereum !== "undefined") {
      setLoading(true);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(address, Contract.abi, signer);

      try {
        let overrides = {
          from: accounts[0],
        };
        const transaction = await contract.setPriceWhitelist(
          _amount,
          overrides
        );
        await transaction.wait();
        setLoading(false);
      } catch (error) {
        console.log(error.message);
        setLoading(false);
      }
    }
  }

  async function setPublicSalePrice(_amount) {
    if (typeof window.ethereum !== "undefined") {
      setLoading(true);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(address, Contract.abi, signer);

      try {
        let overrides = {
          from: accounts[0],
        };
        const transaction = await contract.setPricePublic(_amount, overrides);
        await transaction.wait();
        setLoading(false);
      } catch (error) {
        console.log(error.message);
        setLoading(false);
      }
    }
  }

  async function setBaseUri(_baseUri) {
    if (typeof window.ethereum !== "undefined") {
      setLoading(true);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(address, Contract.abi, signer);

      try {
        let overrides = {
          from: accounts[0],
        };
        const transaction = await contract.setBaseUri(_baseUri, overrides);
        await transaction.wait();
        setLoading(false);
      } catch (error) {
        console.log(error.message);
        setLoading(false);
      }
    }
  }

  async function setMerkleRoot(_merkleRoot) {
    if (typeof window.ethereum !== "undefined") {
      setLoading(true);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(address, Contract.abi, signer);

      try {
        let overrides = {
          from: accounts[0],
        };
        const transaction = await contract.setMerkleRoot(
          _merkleRoot,
          overrides
        );
        await transaction.wait();
        setLoading(false);
      } catch (error) {
        console.log(error.message);
        setLoading(false);
      }
    }
  }

  async function releaseAll() {
    if (typeof window.ethereum !== "undefined") {
      setLoading(true);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(address, Contract.abi, signer);

      try {
        let overrides = {
          from: accounts[0],
        };
        const transaction = await contract.releaseAll(overrides);
        await transaction.wait();
        setLoading(false);
      } catch (error) {
        console.log(error.message);
        setLoading(false);
      }
    }
  }

  async function releaseSpecificToken(_token) {
    if (typeof window.ethereum !== "undefined") {
      setLoading(true);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(address, Contract.abi, signer);

      try {
        let overrides = {
          from: accounts[0],
          gasLimit: 20000000,
        };
        const transaction = await contract.releaseSpecificToken(
          _token,
          overrides
        );
        await transaction.wait();
        setLoading(false);
      } catch (error) {
        console.log(error.message);
        setLoading(false);
      }
    }
  }

  async function releaseAllRoyalties() {
    if (typeof window.ethereum !== "undefined") {
      setLoading(true);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(
        addressRoyalties,
        ContractRoyalties,
        signer
      );

      try {
        let overrides = {
          from: accounts[0],
        };
        const transaction = await contract.releaseAll(overrides);
        await transaction.wait();
        setLoading(false);
      } catch (error) {
        console.log(error.message);
        setLoading(false);
      }
    }
  }

  const handleMint = (_quantity) => {
    if (accounts[0] && goodNetwork) {
      if (sellingStep === 1) {
        whitelistSaleMint(_quantity);
      } else if (sellingStep === 2) {
        publicSaleMint(_quantity);
      } else {
        setError("The mint has not started");
        return;
      }
    } else {
      setError("Connect Your Wallet");
    }
  };

  // useEffect(() => {
  //   if (sellingStep === 1) {
  //     setBasketETH(count * whitelistPrice);
  //   }
  //   if (sellingStep === 2) {
  //     setBasketETH(count * publicPrice);
  //   }
  //   if (sellingStep === 0) {
  //     setBasketETH(0);
  //   }
  // }, [sellingStep, count, whitelistPrice, publicPrice]);

  return (
    <>
      <HeaderMint menu={menu} toggleMenu={toggleMenu} />
      <div className="page-wrapper-container w-100 vh-100 position-relative">
        <div
          className="pwc-bg d-none d-md-block"
          style={{ backgroundImage: "url(include/images/mint-bg.png)" }}
        />
        <div
          className="pwc-bg d-block d-md-none"
          style={{ backgroundImage: "url(include/images/mob-mint-bg.png)" }}
        />

        <div
          class="hero-imge-area text-center m-0"
          ref={(el) => (imageRef = el)}
        >
          <img src="include/images/logo-01.svg" alt="" />
        </div>

        <div className="page-wrapper-inner d-flex align-items-center justify-content-center">
          <div className="page-wrapper-content text-center">
            {metamaskInstall ? (
              <>
                <h5 style={{ fontSize: "2em" }}>
                  {pathname === "en"
                    ? "First of all, Please Install "
                    : pathname === "fr"
                    ? "Tout d'abord, veuillez installer "
                    : "Primero, instale "}
                  {/* <a
                    href="https://metamask.io/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#f58216" }}
                  >
                    Metamask
                  </a> */}
                </h5>
                <a
                  className="btn btn-default"
                  href="https://metamask.io/"
                  target="_blank"
                  rel="noreferrer"
                  style={{ marginTop: "3vh" }}
                >
                  <i>
                    <img src="include/images/connect-icon.svg" alt="" />
                  </i>{" "}
                  {pathname === "en"
                    ? "Metamask"
                    : pathname === "fr"
                    ? "Metamask"
                    : "Metamask"}
                </a>
                {(crossmint === "whitelist" || crossmint === "public") && (
                  <>
                    <h5
                      style={{
                        fontSize: "2em",
                        marginTop: "5vh",
                        marginBottom: "5vh",
                      }}
                    >
                      {pathname === "en"
                        ? "Or use Crossmint"
                        : pathname === "fr"
                        ? "Ou utilisez Crossmint"
                        : "O utilice Crossmint"}
                    </h5>

                    <div class="page-count-wrapper">
                      <div class="number d-flex align-items-center">
                        <span
                          class="minus"
                          onClick={() => {
                            if (count > 1) setCount(count - 1);
                          }}
                        >
                          -
                        </span>
                        <input type="text" value={count} />
                        <span
                          class="plus"
                          onClick={() => {
                            if (count < (crossmint === "whitelist" ? 10 : 255))
                              setCount(count + 1);
                          }}
                        >
                          +
                        </span>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {crossmint === "whitelist" && (
                        <CrossmintPayButton
                          collectionTitle="SuperWild Club Whitelist"
                          collectionDescription="Join SuperWildClub, a collection of unique animals that live in a tropical, urban, and hospitable environment. Your characters will embark on numerous adventures in order to safeguard their island and its nature. Let's write their stories together, page by page, to demonstrate our identity. Exclusive utility based collections of NFT of unique animals chosen with the community for the community with a special and additional support for foundations and groups whom enjoy helping animals worldwide."
                          collectionPhoto="https://www.superwildclub.com/include/images/logo_v2.png"
                          clientId="e465d797-8a95-430d-b0f1-561195f91c92"
                          mintConfig={{
                            type: "erc-721",
                            totalPrice:
                              priceCrossmint > 0 &&
                              count > 0 &&
                              ((count * priceCrossmint) / 100).toString(),
                            _quantity: count.toString(),
                          }}
                        />
                      )}
                      {crossmint === "public" && (
                        <CrossmintPayButton
                          collectionTitle="SuperWild Club Public Sale"
                          collectionDescription="Join SuperWildClub, a collection of unique animals that live in a tropical, urban, and hospitable environment. Your characters will embark on numerous adventures in order to safeguard their island and its nature. Let's write their stories together, page by page, to demonstrate our identity. Exclusive utility based collections of NFT of unique animals chosen with the community for the community with a special and additional support for foundations and groups whom enjoy helping animals worldwide."
                          collectionPhoto="https://www.superwildclub.com/include/images/logo_v2.png"
                          clientId="0f08803a-dcc2-4731-bbfb-671ad2c23323"
                          mintConfig={{
                            type: "erc-721",
                            totalPrice:
                              priceCrossmint > 0 &&
                              count > 0 &&
                              ((count * priceCrossmint) / 100).toString(),
                            _quantity: count.toString(),
                          }}
                        />
                      )}
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                {!goodNetwork || accounts.length <= 0 ? (
                  <>
                    <h5>
                      {pathname === "en"
                        ? "Connect your metamask \n and Switch to the ETH Mainnet to mint"
                        : pathname === "fr"
                        ? "Connectez votre metamask \n et basculez sur le ETH Mainnet pour minter"
                        : "Conecta su metamask y cambia a la ETH Mainnet para mint"}
                    </h5>
                    {accounts.length <= 0 && (
                      <div className="pwc-btn d-flex align-items-center justify-content-center">
                        <button
                          className="btn btn-default"
                          onClick={() => getAccounts()}
                        >
                          <i>
                            <img src="include/images/connect-icon.svg" alt="" />
                          </i>{" "}
                          {pathname === "en"
                            ? "Connect wallet"
                            : pathname === "fr"
                            ? "Connectez wallet"
                            : "Conecta wallet"}
                        </button>
                        {/* <button
                          className="btn btn-default"
                          onClick={() => getAccounts()}
                        >
                          <i>
                            <img src="include/images/Credit card.svg" alt="" />
                          </i>{" "}
                          {pathname === "en"
                            ? "Credit Card"
                            : pathname === "fr"
                            ? "Carte Bancaire"
                            : "Tarjeta Bancaria"}
                        </button> */}
                      </div>
                    )}
                    {(crossmint === "whitelist" || crossmint === "public") && (
                      <>
                        <h5
                          style={{
                            fontSize: "2em",
                            marginTop: "5vh",
                            marginBottom: "5vh",
                          }}
                        >
                          {pathname === "en"
                            ? "Or use Crossmint"
                            : pathname === "fr"
                            ? "Ou utilisez Crossmint"
                            : "O utilice Crossmint"}
                        </h5>

                        <div class="page-count-wrapper">
                          <div class="number d-flex align-items-center">
                            <span
                              class="minus"
                              onClick={() => {
                                if (count > 1) setCount(count - 1);
                              }}
                            >
                              -
                            </span>
                            <input type="text" value={count} />
                            <span
                              class="plus"
                              onClick={() => {
                                if (
                                  count < (crossmint === "whitelist" ? 10 : 255)
                                )
                                  setCount(count + 1);
                              }}
                            >
                              +
                            </span>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {crossmint === "whitelist" && (
                            <CrossmintPayButton
                              collectionTitle="SuperWild Club Whitelist"
                              collectionDescription="Join SuperWildClub, a collection of unique animals that live in a tropical, urban, and hospitable environment. Your characters will embark on numerous adventures in order to safeguard their island and its nature. Let's write their stories together, page by page, to demonstrate our identity. Exclusive utility based collections of NFT of unique animals chosen with the community for the community with a special and additional support for foundations and groups whom enjoy helping animals worldwide."
                              collectionPhoto="https://www.superwildclub.com/include/images/logo_v2.png"
                              clientId="e465d797-8a95-430d-b0f1-561195f91c92"
                              mintConfig={{
                                type: "erc-721",
                                totalPrice:
                                  priceCrossmint > 0 &&
                                  count > 0 &&
                                  ((count * priceCrossmint) / 100).toString(),
                                _quantity: count.toString(),
                              }}
                            />
                          )}
                          {crossmint === "public" && (
                            <CrossmintPayButton
                              collectionTitle="SuperWild Club Public Sale"
                              collectionDescription="Join SuperWildClub, a collection of unique animals that live in a tropical, urban, and hospitable environment. Your characters will embark on numerous adventures in order to safeguard their island and its nature. Let's write their stories together, page by page, to demonstrate our identity. Exclusive utility based collections of NFT of unique animals chosen with the community for the community with a special and additional support for foundations and groups whom enjoy helping animals worldwide."
                              collectionPhoto="https://www.superwildclub.com/include/images/logo_v2.png"
                              clientId="0f08803a-dcc2-4731-bbfb-671ad2c23323"
                              mintConfig={{
                                type: "erc-721",
                                totalPrice:
                                  priceCrossmint > 0 &&
                                  count > 0 &&
                                  ((count * priceCrossmint) / 100).toString(),
                                _quantity: count.toString(),
                              }}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {sellingStep !== 1 && sellingStep !== 2 ? (
                      <h5>
                        {currentTotalSupply === 0
                          ? "Sold Out"
                          : pathname === "en"
                          ? "The sale has not yet started"
                          : pathname === "fr"
                          ? "La vente n'a pas encore commencé"
                          : "La venta aún no ha comenzado"}
                      </h5>
                    ) : (
                      <>
                        {error && (
                          <h5 style={{ color: "red", marginBottom: "5vh" }}>
                            {error}
                          </h5>
                        )}

                        {currentTotalSupply && (
                          <h5>
                            Supply : {currentTotalSupply.toString()}/{maxSup}
                          </h5>
                        )}
                        <div class="page-count-wrapper">
                          <div class="number d-flex align-items-center">
                            <span
                              class="minus"
                              onClick={() => {
                                if (count > 1) setCount(count - 1);
                              }}
                            >
                              -
                            </span>
                            <input type="text" value={count} />
                            <span
                              class="plus"
                              onClick={() => {
                                if (
                                  count < (crossmint === "whitelist" ? 10 : 255)
                                )
                                  setCount(count + 1);
                              }}
                            >
                              +
                            </span>
                          </div>
                        </div>
                        <div className="pwc-btn d-flex align-items-center justify-content-center buttons-mint">
                          <button
                            className="btn btn-default btn-sm"
                            onClick={() => handleMint(count)}
                            disabled={loading ? 1 : 0}
                            style={{ margin: 4 }}
                          >
                            {loading
                              ? pathname === "en"
                                ? "Loading..."
                                : pathname === "fr"
                                ? "Chargement..."
                                : "Cargando..."
                              : pathname === "en"
                              ? "MINT IN ETH"
                              : pathname === "fr"
                              ? "MINT EN ETH"
                              : "MINT CON ETH"}
                          </button>
                          {sellingStep === 1 && whitelistPrice && count && (
                            <CrossmintPayButton
                              collectionTitle="SuperWild Club Whitelist"
                              collectionDescription="Join SuperWildClub, a collection of unique animals that live in a tropical, urban, and hospitable environment. Your characters will embark on numerous adventures in order to safeguard their island and its nature. Let's write their stories together, page by page, to demonstrate our identity. Exclusive utility based collections of NFT of unique animals chosen with the community for the community with a special and additional support for foundations and groups whom enjoy helping animals worldwide."
                              collectionPhoto="https://www.superwildclub.com/include/images/logo_v2.png"
                              clientId="e465d797-8a95-430d-b0f1-561195f91c92"
                              mintConfig={{
                                type: "erc-721",
                                totalPrice:
                                  whitelistPrice > 0 &&
                                  count > 0 &&
                                  ethers.utils.formatEther(
                                    (whitelistPrice * count).toString()
                                  ),
                                _quantity: count.toString(),
                              }}
                            />
                          )}
                          {sellingStep === 2 && publicPrice && count && (
                            <CrossmintPayButton
                              collectionTitle="SuperWild Club Public Sale"
                              collectionDescription="Join SuperWildClub, a collection of unique animals that live in a tropical, urban, and hospitable environment. Your characters will embark on numerous adventures in order to safeguard their island and its nature. Let's write their stories together, page by page, to demonstrate our identity. Exclusive utility based collections of NFT of unique animals chosen with the community for the community with a special and additional support for foundations and groups whom enjoy helping animals worldwide."
                              collectionPhoto="https://www.superwildclub.com/include/images/logo_v2.png"
                              clientId="0f08803a-dcc2-4731-bbfb-671ad2c23323"
                              mintConfig={{
                                type: "erc-721",
                                totalPrice:
                                  publicPrice > 0 &&
                                  count > 0 &&
                                  ethers.utils.formatEther(
                                    (publicPrice * count).toString()
                                  ),
                                _quantity: count.toString(),
                              }}
                            />
                          )}
                        </div>

                        {/* <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            marginBottom: "5vh",
                            marginTop: "5vh",
                          }}
                        >
                          <h5
                            style={{ alignSelf: "center", marginRight: "2vw" }}
                          >
                            {pathname === "en"
                              ? "Price : "
                              : pathname === "fr"
                              ? "Prix : "
                              : "Precio : "}
                            {ethers.utils.formatEther(basketETH.toString())} ETH
                          </h5>
                          <button
                            className="btn btn-default counter"
                            style={
                              loading
                                ? { fontSize: 30 }
                                : { fontSize: 30, width: "8em" }
                            }
                            onClick={() => handleMint(counterNFT)}
                            disabled={loading ? 1 : 0}
                          >
                            {loading
                              ? pathname === "en"
                                ? "Loading..."
                                : pathname === "fr"
                                ? "Chargement..."
                                : "Cargando..."
                              : "MINT"}
                          </button>
                        </div> */}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      {accounts.length > 0 && owner === accounts[0] && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 100,
          }}
        >
          <h5 fs="4em" color="red" style={{ marginTop: "10vh" }}>
            ADMIN
          </h5>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "5vh",
              marginBottom: "10vh",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h5 style={{ marginRight: "2vw" }}>Actuel : {sellingStep}</h5>

            <Counter
              counter={counterStep}
              setCounter={setCounterStep}
              limit={2}
              start={0}
            />

            <button
              className="btn btn-default counter"
              style={{ fontSize: 20, minWidth: "8em", marginLeft: "2vw" }}
              onClick={() => setStep(counterStep)}
              disabled={loading ? 1 : 0}
            >
              {loading ? "Loading..." : "SETSTEP"}
            </button>
          </div>

          <div className="admin">
            <h5
              fs="3em"
              color="green"
              style={{ alignSelf: "center", marginRight: "2vw" }}
            >
              Actuel : {ethers.utils.formatEther(whitelistPrice.toString())} ETH
            </h5>
            <input
              placeholder="New Whitelist Sale Price"
              onChange={(e) => setNewWhitelistSalePrice(e.target.value)}
            />

            <button
              className="btn btn-default counter"
              style={{ fontSize: 20, minWidth: "8em", marginLeft: "2vw" }}
              onClick={() => setWhitelistSalePrice(newWhitelistSalePrice)}
              disabled={loading ? 1 : 0}
            >
              {loading ? "Loading..." : "SET WHITELIST SALE PRICE (wei)"}
            </button>
          </div>

          <div className="admin">
            <h5
              fs="3em"
              color="green"
              style={{ alignSelf: "center", marginRight: "2vw" }}
            >
              Actuel : {ethers.utils.formatEther(publicPrice.toString())} ETH
            </h5>
            <input
              placeholder="New Public Sale Price"
              onChange={(e) => setNewPublicSalePrice(e.target.value)}
            />

            <button
              className="btn btn-default counter"
              style={{ fontSize: 20, minWidth: "8em", marginLeft: "2vw" }}
              onClick={() => setPublicSalePrice(newPublicSalePrice)}
              disabled={loading ? 1 : 0}
            >
              {loading ? "Loading..." : "SET PUBLIC SALE PRICE (wei)"}
            </button>
          </div>

          <div className="admin">
            <input
              placeholder="Address"
              onChange={(e) => setGiftAddress(e.target.value)}
              style={{ marginRight: "2vw" }}
            />

            <Counter counter={giftCounter} setCounter={setGiftCounter} />

            <button
              className="btn btn-default counter"
              style={{ fontSize: 20, minWidth: "8em", marginLeft: "2vw" }}
              onClick={() => gift(giftAddress, giftCounter)}
              disabled={loading ? 1 : 0}
            >
              {loading ? "Loading..." : "GIFT"}
            </button>
          </div>

          <div className="admin">
            <input
              placeholder="Merkle Root"
              onChange={(e) => setNewMerkleRoot(e.target.value)}
            />

            <button
              className="btn btn-default counter"
              style={{ fontSize: 20, minWidth: "8em", marginLeft: "2vw" }}
              onClick={() => setMerkleRoot(newMerkleRoot)}
              disabled={loading ? 1 : 0}
            >
              {loading ? "Loading..." : "SETMERKLEROOT"}
            </button>
          </div>

          <div className="admin">
            <input
              placeholder="Base URI"
              onChange={(e) => setNewBaseUri(e.target.value)}
            />

            <button
              className="btn btn-default counter"
              style={{ fontSize: 20, minWidth: "8em", marginLeft: "2vw" }}
              onClick={() => setBaseUri(newBaseUri)}
              disabled={loading ? 1 : 0}
            >
              {loading ? "Loading..." : "SET BASE URI"}
            </button>
          </div>

          <button
            className="btn btn-default counter"
            style={{
              fontSize: 20,
              minWidth: "8em",
              marginLeft: "2vw",
              marginBottom: "10vh",
            }}
            onClick={() => releaseAll()}
            disabled={loading ? 1 : 0}
          >
            {loading ? "Loading..." : "RELEASE ALL MONEY"}
          </button>

          <div className="admin">
            <input
              placeholder="Token Contract Address"
              onChange={(e) => setReleaseToken(e.target.value)}
            />

            <button
              className="btn btn-default counter"
              style={{ fontSize: 20, minWidth: "8em", marginLeft: "2vw" }}
              onClick={() => releaseSpecificToken(releaseToken)}
              disabled={loading ? 1 : 0}
            >
              {loading ? "Loading..." : "RELEASE SPECIFIC TOKEN"}
            </button>
          </div>

          <p>ROYALTIES PAYMENT SPLITTER</p>

          <button
            className="btn btn-default counter"
            style={{
              fontSize: 20,
              minWidth: "8em",
              marginLeft: "2vw",
              marginBottom: "10vh",
            }}
            onClick={() => releaseAllRoyalties()}
            disabled={loading ? 1 : 0}
          >
            {loading ? "Loading..." : "RELEASE ALL MONEY ROYALTIES"}
          </button>
        </div>
      )}
    </>
  );
}
