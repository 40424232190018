import React, { Component, createRef } from "react";
import gsap from "gsap";

class Synopsis extends Component {
  pictureRef = createRef(null);
  pictureRef1 = createRef(null);
  parallexRef1 = createRef(null);
  parallexRef2 = createRef(null);

  componentDidMount = () => {
    gsap.to(this.pictureRef, {
      rotation: 360,
      transformOrigin: "center",
      ease: "none",
      duration: 60,
      repeat: -1,
    });
    gsap.to(this.pictureRef1, {
      rotation: 360,
      transformOrigin: "center",
      ease: "none",
      duration: 60,
      repeat: -1,
    });
    const parallex = [this.parallexRef1, this.parallexRef2];
    parallex.forEach((cb) =>
      gsap.to(cb, {
        y: -100,
        ease: "none",
        scrollTrigger: {
          trigger: cb,
          start: "top bottom",
          scrub: true,
        },
      })
    );
  };
  render() {
    const pathname =
      typeof window !== undefined
        ? localStorage.getItem("lang")
          ? localStorage.getItem("lang")
          : "en"
        : "en";
    return (
      <div
        class="content-container single-text-container synopsis-container pb-0"
        id="synopsis"
      >
        <div class="container">
          <div class="row">
            <div class="col-md-12 offset-md-0 col-lg-10 offset-lg-1">
              <div class="stc-wrapper">
                <div class="stc-header d-flex flex-wrap position-relative justify-content-center">
                  <div class="heading-text inline">
                    <h4 class="d-none d-md-block">
                      {pathname === "en" && <span>the synopsis</span>}
                      {pathname === "fr" && <span>le synopsis</span>}
                      {pathname === "es" && <span>LA SINOPSIS</span>}
                    </h4>
                    <h4 class="d-block d-md-none">
                      {pathname === "en" && <span>the synopsis</span>}
                      {pathname === "fr" && <span>le synopsis</span>}
                      {pathname === "es" && <span>LA SINOPSIS</span>}
                    </h4>
                  </div>
                  <div class="img-block ms-auto">
                    <div class="text-img d-block d-lg-none">
                      <picture>
                        <img
                          ref={(el) => (this.pictureRef1 = el)}
                          class="rotate"
                          src="include/images/Earth-new.svg"
                          alt=""
                        />
                      </picture>
                    </div>
                    <div
                      class="ib-image d-none d-lg-block is-parallax"
                      ref={(el) => (this.parallexRef1 = el)}
                    >
                      <img src="include/images/feather-img.png" alt="" />
                    </div>
                  </div>
                </div>
                <div class="stc-body">
                  <div class="stcb-box d-flex flex-wrap align-items-center">
                    <div class="stcb-img-box">
                      <div class="text-img d-none d-lg-block">
                        <picture>
                          <img
                            ref={(el) => (this.pictureRef = el)}
                            class="rotate"
                            src="include/images/Earth-new.svg"
                            alt=""
                          />
                        </picture>
                      </div>
                      <div
                        class="ib-image d-block d-lg-none is-parallax"
                        ref={(el) => (this.parallexRef2 = el)}
                      >
                        <img src="include/images/feather-img.png" alt="" />
                      </div>
                    </div>
                    {pathname === "en" && (
                      <div class="stcb-text-box">
                        <p>
                          Two years ago, a mysterious disaster destroyed the
                          whole planet, except for one place : the Superwild
                          Island which is still stands today. No one knows why
                          and how it was saved.
                        </p>
                        <p>
                          This island is quite paradisiac, a good weather all
                          year long and a futuristic architecture which
                          highlights the blooming vegetation of this city. It is
                          also a very cosmopolitan place where people come from
                          different backgrounds. Amongst them, the egocentric
                          and arrogant Mayor of the city, Clint who does his
                          best to maintain a peaceful atmosphere while
                          protecting his companies’ interests.
                        </p>
                        <p>
                          His driver & hotheaded cousin, Lenny, is respected
                          throughout the city. As a future rap singer, it’s best
                          to stand in his way or scratch one of his precious
                          gold necklace designed by his bestfriend Janet who is
                          herself eccentric and creative. Her jewels are very
                          popular and her collections are sold out in record
                          time.
                        </p>
                        <p>
                          Every Thursday evening, she likes to take a break and
                          meet her friends in a pub called MOJO where her uncle
                          is the bartender. He is very chill and sociable, knows
                          everything about what’s going on in the city :
                          weddings, scams, politics… nothing remains hidden to
                          him, which puts him in very delicate situations. Once,
                          members of the blue squad, a terrific gang that
                          controls the Northwest of the city, threatened him to
                          get some information. Fortunately this day, his friend
                          the cook was here & rescued him thanks to his
                          diplomacy and his impressive body build.
                        </p>
                        <p>
                          Despite those frictions between Superwild Island
                          citizens, one common interest gathers them: protect
                          their island and its precious Jaoa trees from
                          externals threats, no matter what.
                        </p>
                      </div>
                    )}
                    {pathname === "fr" && (
                      <div class="stcb-text-box">
                        <p>
                          Deux ans après une mystérieuse catastrophe ayant
                          décimé la planète entière, l’île de SuperWild est la
                          seule encore sur pied. (Nul ne sait pourquoi ni
                          comment celle-ci a été épargnée.)
                        </p>
                        <p>
                          Son climat agréable toute l’année, sa végétation
                          luxuriante et son architecture futuriste caractérisent
                          cette île exceptionnelle. Et c’est sans compter sur la
                          diversité de ses habitants, plus différents les uns
                          que les autres.
                        </p>
                        <p>
                          On compte parmi eux l’égocentrique et arrogant maire
                          de la ville, Clint, qui fait de son mieux pour
                          maintenir un climat social paisible tout en protégeant
                          les intérêts de ses différentes entreprises. Son loyal
                          cousin et chauffeur au sang chaud, Lenny, est respecté
                          dans toute la ville. Chanteur de hip-hop en devenir,
                          il ne vaut mieux pas lui griller une priorité ou rayer
                          un de ses colliers en or confectionné par Janet, sa
                          meilleur amie. Excentrique et créative, ses bijoux
                          s’arrachent comme des petits pains dans tout
                          SuperWild.
                        </p>
                        <p>
                          Chaque jeudi soir pour décompresser, elle aime
                          rejoindre ses amis au bar MOJO, tenu par son oncle
                          Freddy. Serveur chill et sociable, il est au courant
                          de tout ce qui se passe dans la ville : mariage,
                          querelles, arnaques, plans politiques… rien ne lui
                          échappe si bien qu’il se retrouve parfois dans des
                          situations plus délicate. C’est le cas lorsque des
                          membres du gang des Bleus qui sèment la terreur dans
                          le quartier nord-ouest de Superwild ont essayé de lui
                          soutirer certaines informations… Heureusement, ce jour
                          là son ami cuisiner à la carrure impressionnante a su
                          faire preuve de diplomatie et l’a tiré d’affaire
                          in-extremis.
                        </p>
                        <p>
                          Malgré ses petites frictions entre citoyens de l’île
                          de SuperWild, un intérêt commun les anime: protéger à
                          tout prix leur île et les précieux arbres Jaoa des
                          menaces extérieures, quoiqu’il en coûte.
                        </p>
                      </div>
                    )}
                    {pathname === "es" && (
                      <div class="stcb-text-box">
                        <p>
                          Hace dos años, un misterioso desastre destruyó todo el
                          planeta, a excepción de un lugar: la isla Superwild,
                          que aún sigue en pie. Nadie sabe por qué ni cómo se
                          salvó.
                        </p>
                        <p>
                          Esta isla es bastante paradisíaca, buen clima todo el
                          año y una arquitectura futurista que destaca la
                          floreciente vegetación de esta ciudad. También es un
                          lugar muy cosmopolita donde la gente proviene de
                          diferentes lugares. Entre ellos, el egocéntrico y
                          arrogante alcalde de la ciudad, Clint, que hace todo
                          lo posible por mantener un ambiente tranquilo mientras
                          protege los intereses de sus empresas.
                        </p>
                        <p>
                          Su conductor e hiperactivo primo, Lenny, es respetado
                          en toda la ciudad. Como posible futuro cantante de
                          rap, lo mejor es estar cerca de él para poder recibir
                          uno de sus preciosos collares de oro diseñados por su
                          mejor amiga Janet, quien es excéntrica y creativa. Sus
                          joyas son muy populares y sus colecciones se agotan en
                          un tiempo récord.
                        </p>
                        <p>
                          Todos los jueves por la noche, le gusta tomarse un
                          descanso y reunirse con sus amigos en un pub llamado
                          MOJO, donde su tío es el cantinero. Es muy tranquilo y
                          sociable, sabe todo sobre lo que pasa en la ciudad:
                          bodas, estafas, política… nada le queda oculto, lo que
                          le pone en situaciones muy delicadas. Una vez, los
                          miembros del escuadrón azul, una pandilla formidable
                          que controla el noroeste de la ciudad, lo amenazaron
                          para obtener información. Afortunadamente ese día, su
                          amigo, el cocinero estaba ahí y lo rescató gracias a
                          su diplomacia y su impresionante físico.
                        </p>
                        <p>
                          A pesar de esas fricciones entre los ciudadanos de
                          Superwild Island, un interés común los reúne: proteger
                          su isla y sus preciosos árboles Jaoa de amenazas
                          externas, pase lo que pase.
                        </p>
                      </div>
                    )}
                  </div>
                  <div class="stcb-button text-md-end">
                    <a
                      href="https://discord.com/invite/superwildclub"
                      target="_blank"
                      class="btn btn-default btn-lg"
                    >
                      <i class="fab fa-discord"></i>{" "}
                      {pathname === "en" && "join the club"}
                      {pathname === "fr" && "rejoins le club"}
                      {pathname === "es" && "ÚNETE AL CLUB"}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Synopsis;
