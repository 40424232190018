import React, { Component } from "react";
import Footer from "./footer";
import Header from "./header";
import HeaderMint from "./headerMint";

class TermCondn extends Component {
  componentDidMount = () => {
    window.scrollTo({ top: "0", behavior: "smooth" });
  };

  render() {
    const pathname =
      typeof window !== undefined
        ? localStorage.getItem("lang")
          ? localStorage.getItem("lang")
          : "en"
        : "en";
    return (
      <>
        <HeaderMint />
        <div class="content-container t-and-c-container">
          <div class="container">
            <div class="row">
              <div class="col-md-10 offset-md-1">
                <div class="heading-text text-start">
                  <h4>
                    <span>
                      {pathname === "en" && "SUPER WILD CLUB NFT Terms of Use"}
                      {pathname === "fr" &&
                        "Conditions d'utilisation du SUPER WILD CLUB NFT"}
                      {pathname === "es" &&
                        "Condiciones de uso de SUPER WILD CLUB NFT "}
                    </span>
                  </h4>
                </div>
                <h6>
                  {pathname === "en" && "Last revised: MAY 27, 2022"}
                  {pathname === "fr" && "Dernière révision : 27 MAI 2022"}
                  {pathname === "es" && "Última revisión: 27 DE MAYO DE 2022"}
                </h6>

                {/* <b>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor ?
                </b> */}
                {pathname === "en" && (
                  <>
                    <p>
                      These Terms of Use (“Terms”) apply to your purchase, sale,
                      and display of Super Wild Club non-fungible tokens (“SWC
                      NFTs”). Some purchases of SWC NFTs may include special
                      [experience opportunities]. Please view terms regarding
                      such [experiences here] (“Experience Terms”). To the
                      extent there is a conflict between these Terms and the
                      Experience Terms, these Terms control.
                    </p>
                    <p>
                      These Terms are entered into between you and Super Wild
                      Club (“SWC” “Company,” “we,” or “us”). These Terms
                      expressly incorporate any other documents referenced
                      herein (such as our Privacy Policy) and govern your access
                      to and use of this site{" "}
                      <a
                        href="www.superwildclub.com"
                        target="_blank"
                        style={{ textDecoration: "underline" }}
                      >
                        www.superwildclub.com
                      </a>{" "}
                      (the “Site”), as well as all content, functionality, and
                      services offered on or through the Site, including the
                      Super Wild Club NFTs.
                    </p>
                  </>
                )}
                {pathname === "fr" && (
                  <>
                    <p>
                      Ces conditions d'utilisation ("Conditions") s'appliquent à
                      votre achat, vente et affichage de jetons non fongibles
                      Super Wild Club ("SWC NFT"). Certains achats de SWC NFT
                      peuvent inclure des [opportunités d'expérience] spéciales.
                      Veuillez consulter les conditions relatives à ces
                      [expériences ici] ("Conditions d'expérience"). En cas de
                      conflit entre les présentes Conditions et les Conditions
                      de l'expérience, ces Conditions prévalent.
                    </p>{" "}
                    <p>
                      Ces conditions sont conclues entre vous et Super Wild Club
                      ("SWC" "Société", "nous" ou "nous"). Ces conditions
                      incorporent expressément tous les autres documents
                      référencés ici (tels que notre politique de
                      confidentialité) et régissent votre accès et votre
                      utilisation de ce site{" "}
                      <a
                        href="www.superwildclub.com"
                        target="_blank"
                        style={{ textDecoration: "underline" }}
                      >
                        www.superwildclub.com
                      </a>{" "}
                      (le « site »), ainsi que tout le contenu, les
                      fonctionnalités et les services offerts sur ou via le
                      Site, y compris les NFT du Super Wild Club.
                    </p>
                  </>
                )}
                {pathname === "es" && (
                  <p>
                    Estos Términos de uso ("Términos") se aplican a su compra,
                    venta y exhibición de tokens no fungibles de Super Wild Club
                    ("NFT de SWC"). Algunas compras de NFT de SWC pueden incluir
                    [oportunidades de experiencia] especiales. Consulte los
                    términos relacionados con dichas [experiencias aquí]
                    ("Términos de experiencia"). En la medida en que exista un
                    conflicto entre estos Términos y los Términos de
                    experiencia, estos Términos prevalecen. Estos Términos se
                    celebran entre usted y Super Wild Club ("SWC", "Compañía",
                    "nosotros" o "nos"). Estos Términos incorporan expresamente
                    cualquier otro documento al que se haga referencia en este
                    documento (como nuestra Política de privacidad) y rigen su
                    acceso y uso de este sitio{" "}
                    <a
                      href="www.superwildclub.com"
                      target="_blank"
                      style={{ textDecoration: "underline" }}
                    >
                      www.superwildclub.com
                    </a>{" "}
                    (el "Sitio"), así como todo el contenido, la funcionalidad y
                    los servicios ofrecidos en o a través del Sitio, incluidos
                    los NFT de Super Wild Club.
                  </p>
                )}
                <b>
                  {pathname === "en" && "Reviewing and Accepting These Terms"}
                  {pathname === "fr" &&
                    "Examen et acceptation de ces conditions"}
                  {pathname === "es" &&
                    "Revisión y aceptación de estos términos"}
                </b>
                {pathname === "en" && (
                  <p>
                    Please read these Terms carefully, as they set out your
                    rights and responsibilities when you use this Site to buy
                    Super Wild Club NFTs (the “Services”). When each Super Wild
                    Club NFT is sold for the first time, the agreement for sale
                    is between the Company and the initial purchaser. If the
                    initial purchaser decides to sell the Super Wild Club NFTs
                    through this Site, then this Site serves only as a platform
                    that facilitates transactions between a buyer and a seller
                    and the Company is not a party to any agreement between such
                    buyer and seller of Super Wild Club NFTs or between any
                    other users.
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    Veuillez lire attentivement ces Conditions, car elles
                    définissent vos droits et responsabilités lorsque vous
                    utilisez ce Site pour acheter des NFT Super Wild Club (les
                    « Services »). Lorsque chaque Super Wild Club NFT est vendu
                    pour la première fois, l'accord de vente est conclu entre la
                    Société et l'acheteur initial. Si l'acheteur initial décide
                    de vendre les NFT Super Wild Club via ce site, ce site sert
                    uniquement de plate-forme facilitant les transactions entre
                    un acheteur et un vendeur et la société n'est partie à aucun
                    accord entre cet acheteur et ce vendeur de Super Wild Club
                    NFTs ou entre tout autre utilisateur.
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    Lea estos Términos detenidamente, ya que establecen sus
                    derechos y responsabilidades cuando utiliza este Sitio para
                    comprar NFT de Super Wild Club (los "Servicios"). Cuando
                    cada Super Wild Club NFT se vende por primera vez, el
                    acuerdo de venta es entre la Compañía y el comprador
                    inicial. Si el comprador inicial decide vender los NFT de
                    Super Wild Club a través de este Sitio, entonces este Sitio
                    sirve solo como una plataforma que facilita las
                    transacciones entre un comprador y un vendedor y la Compañía
                    no es parte de ningún acuerdo entre dicho comprador y
                    vendedor de NFT de Super Wild Club o entre cualquier otro
                    usuario.
                  </p>
                )}
                {pathname === "en" && (
                  <p>
                    All Super Wild Club NFTs are stored on and accessible
                    through the Ethereum blockchain. As such, the Company does
                    not maintain the Super Wild Club NFTs on this Site and,
                    aside from transferring control of the Super Wild Club NFT
                    to the initial purchaser of the Super Wild Club NFT, the
                    Company has no control over the transfer, storage, ownership
                    or maintenance of the Super Wild Club NFT.
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    Tous les NFT du Super Wild Club sont stockés et accessibles
                    via la blockchain Ethereum. En tant que tel, la Société ne
                    maintient pas les NFT Super Wild Club sur ce Site et, à part
                    le transfert du contrôle du Super Wild Club NFT à l'acheteur
                    initial du Super Wild Club NFT, la Société n'a aucun
                    contrôle sur le transfert. , le stockage, la propriété ou
                    l'entretien du Super Wild Club NFT.
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    Todos los NFT de Super W ild Club se almacenan y son
                    accesibles a través de la cadena de bloques Ethereum. Como
                    tal, la Compañía no mantiene los NFT de Super Wild Club en
                    este Sitio y, aparte de transferir el control del Super Wild
                    Club NFT al comprador inicial del Super Wild Club NFT, la
                    Compañía no tiene control sobre la transferencia,
                    almacenamiento, propiedad o mantenimiento del Super Wild
                    Club NFT.
                  </p>
                )}
                {pathname === "en" && (
                  <p>
                    When you connect your cryptocurrency wallet to the Site
                    using a trusted service provider such as MetaMask or Wallet
                    Connect, you accept and agree to be bound and abide by these
                    Terms and all of the terms incorporated herein by reference.
                    By agreeing to these Terms, you hereby certify that you are
                    at least 18 years of age. If you do not agree to these
                    Terms, you must not access or use the Site.
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    Lorsque vous connectez votre portefeuille de crypto-monnaie
                    au site à l'aide d'un fournisseur de services de confiance
                    tel que MetaMask ou Wallet Connect, vous acceptez et
                    convenez d'être lié et de respecter ces conditions et toutes
                    les conditions incorporées ici par référence. En acceptant
                    ces Conditions, vous certifiez par la présente que vous avez
                    au moins 18 ans. Si vous n'acceptez pas ces conditions, vous
                    ne devez pas accéder ou utiliser le site.
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    Cuando conecta su billetera de criptomonedas al Sitio
                    utilizando un servicio de confianza proporcionado como
                    MetaMask o Wallet Connect, acepta y acepta estar obligado y
                    cumplir con estos Términos y todos los términos incorporados
                    aquí por referencia. Al aceptar estos Términos, usted
                    certifica que tiene al menos 18 años de edad. Si no está de
                    acuerdo con estos Términos, no debe acceder ni utilizar el
                    Sitio.
                  </p>
                )}
                {pathname === "en" && (
                  <>
                    <p>
                      Please note that Section 17 contains an arbitration clause
                      and class action waiver. By agreeing to these Terms, you
                      agree to resolve all disputes through binding individual
                      arbitration, which means that you waive any right to have
                      the dispute decided by a judge or jury, and you waive any
                      right to participate in collective action, whether that be
                      a class action, class arbitration, or representative
                      action. You have the ability to opt-out of this
                      arbitration clause by sending us notice of your intent to
                      do so within seven (7) days of your initial agreement to
                      these Terms. We reserve the right to change or modify
                      these Terms at any time and in our sole discretion. You
                      agree and understand that by accessing or using the Site
                      following any change to these Terms, you are agreeing to
                      the revised Terms and all of the terms incorporated
                      therein by reference.
                    </p>
                    <p>
                      Review the Terms each time you access the Site to ensure
                      that you understand how the Terms apply to your activities
                      on the Site.
                    </p>
                  </>
                )}
                {pathname === "fr" && (
                  <>
                    <p>
                      Veuillez noter que l'article 17 contient une clause
                      d'arbitrage et une renonciation au recours collectif. En
                      acceptant ces Conditions, vous acceptez de résoudre tous
                      les litiges par un arbitrage individuel contraignant, ce
                      qui signifie que vous renoncez à tout droit de faire
                      trancher le litige par un juge ou un jury, et vous
                      renoncez à tout droit de participer à une action
                      collective, qu'il s'agisse d'un recours collectif,
                      arbitrage collectif ou action représentative. Vous avez la
                      possibilité de vous retirer de cette clause d'arbitrage en
                      nous envoyant un avis de votre intention de le faire dans
                      les sept (7) jours suivant votre accord initial avec les
                      présentes Conditions.
                    </p>
                    <p>
                      Nous nous réservons le droit de changer ou de modifier ces
                      Conditions à tout moment et à notre seule discrétion. Vous
                      acceptez et comprenez qu'en accédant ou en utilisant le
                      Site suite à toute modification de ces Conditions, vous
                      acceptez les Conditions révisées et toutes les conditions
                      qui y sont incorporées par référence.
                    </p>
                    <p>
                      Passez en revue les Conditions chaque fois que vous
                      accédez au Site pour vous assurer que vous comprenez
                      comment les Conditions s'appliquent à vos activités sur le
                      Site.
                    </p>
                  </>
                )}
                {pathname === "es" && (
                  <p>
                    Tenga en cuenta que la Sección 17 contiene una cláusula de
                    arbitraje y una renuncia a la demanda colectiva. Al aceptar
                    estos Términos, usted acepta resolver todas las disputas a
                    través de un arbitraje individual vinculante, lo que
                    significa que renuncia a cualquier derecho a que la disputa
                    sea decidida por un juez o jurado, y renuncia a cualquier
                    derecho a participar en una acción colectiva, ya sea una
                    demanda colectiva, arbitraje colectivo o acción
                    representativa. Usted tiene la capacidad de optar por no
                    participar en esta cláusula de arbitraje enviándonos un
                    aviso de su intención de hacerlo dentro de los siete (7)
                    días posteriores a su aceptación inicial de estos Términos.
                    Nos reservamos el derecho de cambiar o modificar estos
                    Términos en cualquier momento y a nuestra entera discreción.
                    Usted acepta y entiende que al acceder o utilizar el Sitio
                    después de cualquier cambio en estos Términos, está
                    aceptando los Términos revisados y todos los términos
                    incorporados en ellos por referencia. Revise los Términos
                    cada vez que acceda al Sitio para asegurarse de que
                    comprende cómo se aplican los Términos a sus actividades en
                    el Sitio.
                  </p>
                )}
                <b>
                  {pathname === "en" && "Linking Your Cryptocurrency Wallet"}
                  {pathname === "fr" &&
                    "Lier votre portefeuille de crypto-monnaie"}
                  {pathname === "es" &&
                    "Vincular su billetera de criptomonedas"}
                </b>
                {pathname === "en" && (
                  <p>
                    When you link your cryptocurrency wallet, you understand and
                    agree that you are solely responsible for maintaining the
                    security of your wallet and your control over any
                    wallet-related authentication credentials, private or public
                    cryptocurrency keys, non-fungible tokens or cryptocurrencies
                    that are stored in or are accessible through your wallet.
                    Any unauthorized access to your cryptocurrency wallet by
                    third parties could result in the loss or theft of Super
                    Wild Club NFTs and/or funds held in your wallet and any
                    associated wallets, including any linked financial
                    information such as bank account(s) or credit card(s). We
                    are not responsible for managing and maintaining the
                    security of your cryptocurrency wallet nor for any
                    unauthorized access to or use of your cryptocurrency wallet.
                    If you notice any unauthorized or suspicious activity in
                    your cryptocurrency wallet that seems to be related to this
                    Site, please notify us immediately.
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    Lorsque vous liez votre portefeuille de crypto-monnaie, vous
                    comprenez et acceptez que vous êtes seul responsable du
                    maintien de la sécurité de votre portefeuille et de votre
                    contrôle sur les identifiants d'authentification liés au
                    portefeuille, les clés de crypto-monnaie privées ou
                    publiques, les jetons non fongibles ou les crypto-monnaies
                    qui sont stockées dans ou sont accessibles via votre
                    portefeuille. Tout accès non autorisé à votre portefeuille
                    de crypto-monnaie par des tiers pourrait entraîner la perte
                    ou le vol de NFT Super Wild Club et/ou de fonds détenus dans
                    votre portefeuille et tout portefeuille associé, y compris
                    toute information financière liée telle que le(s) compte(s)
                    bancaire(s) ou le crédit. cartes). Nous ne sommes pas
                    responsables de la gestion et du maintien de la sécurité de
                    votre portefeuille de crypto-monnaie ni de tout accès non
                    autorisé ou utilisation de votre portefeuille de
                    crypto-monnaie. Si vous remarquez une activité non autorisée
                    ou suspecte dans votre portefeuille de crypto-monnaie qui
                    semble être liée à ce site, veuillez nous en informer
                    immédiatement.
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    Cuando vincula su billetera de criptomonedas, comprende y
                    acepta que es el único responsable de mantener la seguridad
                    de su billetera y su control sobre cualquier credencial de
                    autenticación relacionada con la billetera, claves de
                    criptomonedas privadas o públicas, tokens no fungibles o
                    criptomonedas que se almacenan o son accesibles a través de
                    su billetera. Cualquier acceso no autorizado a su billetera
                    de criptomonedas por parte de terceros podría resultar en la
                    pérdida o robo de NFT de Super W ild Club y / o fondos
                    retenidos en su billetera y cualquier billetera asociada,
                    incluida cualquier información financiera vinculada, como
                    cuentas bancarias o tarjetas de crédito. No somos
                    responsables de administrar y mantener la seguridad de su
                    billetera de criptomonedas ni de ningún acceso o uso no
                    autorizado de su billetera de criptomonedas. Si nota alguna
                    actividad no autorizada o sospechosa en su billetera de
                    criptomonedas que parezca estar relacionada con este Sitio,
                    notifíquenos de inmediato.
                  </p>
                )}
                <b>
                  {pathname === "en" && "Ownership"}
                  {pathname === "fr" && "La possession"}
                  {pathname === "es" && "Propiedad"}
                </b>
                {pathname === "en" && (
                  <>
                    <p>
                      Unless otherwise indicated in writing by us, the Site, all
                      content, and all other materials contained therein,
                      including, without limitation, our logos, and all designs,
                      text, graphics, pictures, information, data, software,
                      sound files, other files, and the selection and
                      arrangement thereof (collectively, “Site Content”) are the
                      proprietary property of Super Wild Club or our affiliates,
                      licensors, or users, as applicable. The SWC logo and any
                      Super Wild Club product or service names, logos, or
                      slogans that may appear on the Site or elsewhere are
                      trademarks of Super Wild Club or our affiliates, and may
                      not be copied, imitated or used, in whole or in part,
                      without our prior written permission.
                    </p>
                    <p>
                      You may not use any Site Content or link to the Site
                      without our prior written permission. You may not use
                      framing techniques to enclose any Site Content without our
                      express written consent. In addition, the look and feel of
                      the Site Content, including without limitation, all page
                      headers, custom graphics, button icons, and scripts
                      constitute the service mark, trademark, or trade dress of
                      SWC and may not be copied, imitated, or used, in whole or
                      in part, without our prior written permission.
                    </p>
                  </>
                )}
                {pathname === "fr" && (
                  <>
                    <p>
                      Sauf indication contraire par écrit de notre part, le
                      Site, tout le contenu et tout autre matériel qu'il
                      contient, y compris, sans s'y limiter, nos logos et tous
                      les dessins, textes, graphiques, images, informations,
                      données, logiciels, fichiers audio, autres fichiers ,
                      ainsi que leur sélection et leur disposition
                      (collectivement, le « contenu du site ») sont la propriété
                      exclusive de Super Wild Club ou de nos sociétés affiliées,
                      concédants de licence ou utilisateurs, selon le cas. Le
                      logo SWC et tous les noms, logos ou slogans de produits ou
                      de services Super Wild Club qui peuvent apparaître sur le
                      site ou ailleurs sont des marques déposées de Super Wild
                      Club ou de nos sociétés affiliées, et ne peuvent être
                      copiés, imités ou utilisés, en tout ou en partie. , sans
                      notre autorisation écrite préalable.
                    </p>
                    <p>
                      Vous ne pouvez pas utiliser le contenu du site ou un lien
                      vers le site sans notre autorisation écrite préalable.
                      Vous ne pouvez pas utiliser de techniques de cadrage pour
                      joindre tout contenu du site sans notre consentement écrit
                      exprès. En outre, l'aspect et la convivialité du contenu
                      du site, y compris, sans s'y limiter, tous les en-têtes de
                      page, les graphiques personnalisés, les icônes de bouton
                      et les scripts constituent la marque de service, la marque
                      commerciale ou l'habillage commercial de SWC et ne peuvent
                      être copiés, imités ou utilisés. , en tout ou en partie,
                      sans notre autorisation écrite préalable.
                    </p>
                  </>
                )}
                {pathname === "es" && (
                  <p>
                    A menos que indiquemos lo contrario por escrito, el Sitio,
                    todo el contenido y todos los demás materiales contenidos en
                    el mismo, incluidos, entre otros, nuestros logotipos y todos
                    los diseños, texto, gráficos, imágenes, información, datos,
                    software, archivos de sonido, otros archivos y la selección
                    y disposición de los mismos (colectivamente, "Contenido del
                    sitio") son propiedad de Super Wild Club o nuestros
                    afiliados, licenciantes, o usuarios, según corresponda. El
                    logotipo de SWC y cualquier nombre, logotipo o eslogan de
                    productos o servicios de Super Wild Club que pueda aparecer
                    en el Sitio o en cualquier otro lugar son marcas comerciales
                    de Super Wild Club o de nuestros afiliados, y no pueden
                    copiarse, imitarse o usarse, en su totalidad o en parte, sin
                    nuestro permiso previo por escrito. No puede usar ningún
                    Contenido del Sitio o enlace al Sitio sin nuestro permiso
                    previo por escrito. No puede utilizar técnicas de enmarcado
                    para incluir ningún Contenido del Sitio sin nuestro
                    consentimiento expreso por escrito. Además, la apariencia
                    del Contenido del Sitio, incluidos, entre otros, todos los
                    encabezados de página, gráficos personalizados, iconos de
                    botones y scripts constituyen la marca de servicio, marca
                    comercial o imagen comercial de SWC y no pueden copiarse,
                    imitarse o usarse, en su totalidad o en parte, sin nuestro
                    permiso previo por escrito.
                  </p>
                )}
                <b>
                  {pathname === "en" && "Ownership of NFT"}
                  {pathname === "fr" && "Propriété de NFT"}
                  {pathname === "es" && "Propiedad de NFT"}
                </b>
                {pathname === "en" && (
                  <p>
                    i. You Own the NFT. Each SWC is an NFT on the Ethereum
                    blockchain. When you purchase an NFT, you own the underlying
                    SWC, the Art, completely. Ownership of the NFT is mediated
                    entirely by the Smart Contract and the Ethereum Network.
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    je. Vous possédez le NFT. Chaque SWC est un NFT sur la
                    blockchain Ethereum. Lorsque vous achetez un NFT, vous
                    possédez entièrement le SWC sous-jacent, l'Art. La propriété
                    du NFT est entièrement médiatisée par le Smart Contract et
                    le réseau Ethereum.
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    i. Usted es el propietario de la NFT. Cada SWC es un NFT en
                    la cadena de bloques de Ethereum. Cuando compra un NFT,
                    usted es el propietario del SWC subyacente, el Art, por
                    completo. La propiedad del NFT está mediada en su totalidad
                    por el Contrato Inteligente y la Red Ethereum.
                  </p>
                )}
                {pathname === "en" && (
                  <p>
                    ii. Personal Use. Subject to your continued compliance with
                    these Terms, Isuno LLC grants you a worldwide, royalty-free
                    license to use, copy, and display the purchased Art, along
                    with any extensions that you choose to create or use, solely
                    for the following purposes: (i) for your own personal,
                    non-commercial use; (ii) as part of a marketplace that
                    permits the purchase and sale of your SWC / NFT, provided
                    that the marketplace cryptographically verifies each SWC
                    owner’s rights to display the Art for their SWC to ensure
                    that only the actual owner can display the Art; or (iii) as
                    part of a third-party website or application that permits
                    the inclusion, involvement, or participation of your SWC,
                    provided that the website/application cryptographically
                    verifies each SWC owner’s rights to display the Art for
                    their SWC to ensure that only the actual owner can display
                    the Art, and provided that the Art is no longer visible once
                    the owner of the SWC leaves the website/application.
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    ii. Usage personnel. Sous réserve de votre respect continu
                    de ces Conditions, Isuno LLC vous accorde une licence
                    mondiale libre de droits pour utiliser, copier et afficher
                    l'Art acheté, ainsi que toutes les extensions que vous
                    choisissez de créer ou d'utiliser, uniquement aux fins
                    suivantes : ( i) pour votre usage personnel et non
                    commercial ; (ii) dans le cadre d'un marché qui permet
                    l'achat et la vente de votre SWC / NFT, à condition que le
                    marché vérifie par cryptographie les droits de chaque
                    propriétaire de SWC d'afficher l'Art pour son SWC afin de
                    s'assurer que seul le propriétaire réel peut afficher
                    l'Art ; ou (iii) dans le cadre d'un site Web tiers ou d'une
                    application permettant l'inclusion, l'implication ou la
                    participation de votre SWC, à condition que le site Web /
                    l'application vérifie de manière cryptographique les droits
                    de chaque propriétaire de SWC d'afficher l'Art pour son SWC
                    afin de s'assurer que seuls le propriétaire réel peut
                    afficher l'Art, et à condition que l'Art ne soit plus
                    visible une fois que le propriétaire du SWC quitte le site
                    Web/l'application.
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    ii. Uso personal. Sujeto a su cumplimiento continuo de estos
                    Términos, Isuno LLC le otorga una licencia mundial y libre
                    de regalías para usar, copiar y mostrar el Arte comprado,
                    junto con cualquier extensión que elija crear o usar,
                    únicamente para los siguientes propósitos: (i) para su
                    propio uso personal y no comercial; (ii) como parte de un
                    mercado que permita la compra y venta de su SWC / NFT,
                    siempre que el mercado verifique criptográficamente los
                    derechos de cada propietario de SWC para mostrar el Arte
                    para su SWC para garantizar que solo el propietario real
                    pueda mostrar el Arte; o (iii) como parte de un sitio web o
                    aplicación de terceros que permita la inclusión,
                    participación o participación de su SWC, siempre que el
                    sitio web / aplicación verifique criptográficamente los
                    derechos de cada propietario de SWC para mostrar el Arte
                    para su SWC para garantizar que solo el propietario real
                    pueda mostrar el Arte, y siempre que el Arte ya no sea
                    visible una vez que el propietario del SWC abandone el sitio
                    web / aplicación.
                  </p>
                )}
                {pathname === "en" && (
                  <p>
                    iii. Commercial Use. Subject to your continued compliance
                    with these Terms, Isuno LLC grants you an unlimited,
                    worldwide license to use, copy, and display the purchased
                    Art for the purpose of creating derivative works based upon
                    the Art (“Commercial Use”). Examples of such Commercial Use
                    would e.g. be the use of the Art to produce and sell
                    merchandise products (T-Shirts etc.) displaying copies of
                    the Art. For the sake of clarity, nothing in this Section
                    will be deemed to restrict you from (i) owning or operating
                    a marketplace that permits the use and sale of SWC
                    generally, provided that the marketplace cryptographically
                    verifies each SWC owner’s rights to display the Art for
                    their SWC to ensure that only the actual owner can display
                    the Art; (ii) owning or operating a third-party website or
                    application that permits the inclusion, involvement, or
                    participation of SWC generally, provided that the
                    third-party website or application cryptographically
                    verifies each SWC owner’s rights to display the Art for
                    their SWC to ensure that only the actual owner can display
                    the Art, and provided that the Art is no longer visible once
                    the owner of the Purchased SWC leaves the
                    website/application; or (iii) earning revenue from any of
                    the foregoing.
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    iii. Un usage commercial. Sous réserve de votre respect
                    continu des présentes Conditions, Isuno LLC vous accorde une
                    licence mondiale illimitée pour utiliser, copier et afficher
                    l'Art acheté dans le but de créer des œuvres dérivées basées
                    sur l'Art ("Utilisation commerciale"). Des exemples d'une
                    telle utilisation commerciale seraient par exemple
                    l'utilisation de l'Art pour produire et vendre des produits
                    dérivés (T-shirts, etc.) affichant des copies de l'Art. Par
                    souci de clarté, rien dans cette section ne sera réputé vous
                    empêcher de (i) posséder ou exploiter un marché qui permet
                    l'utilisation et la vente de SWC en général, à condition que
                    le marché vérifie par cryptographie les droits de chaque
                    propriétaire de SWC d'afficher l'Art pour leur SWC pour
                    s'assurer que seul le propriétaire réel peut afficher
                    l'œuvre d'art ; (ii) posséder ou exploiter un site Web ou
                    une application tiers qui permet l'inclusion, l'implication
                    ou la participation de SWC en général, à condition que le
                    site Web ou l'application tiers vérifie de manière
                    cryptographique les droits de chaque propriétaire de SWC
                    d'afficher l'Art pour leur SWC afin de s'assurer que seul le
                    propriétaire réel peut afficher l'Art, et à condition que
                    l'Art ne soit plus visible une fois que le propriétaire du
                    SWC acheté quitte le site Web/l'application ; ou (iii) tirer
                    des revenus de l'un des éléments susmentionnés.
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    iii. Uso comercial. Sujeto a su cumplimiento continuo de
                    estos Términos, Isuno LLC le otorga una licencia ilimitada y
                    mundial para usar, copiar y mostrar el Arte comprado con el
                    fin de crear trabajos derivados basados en el Arte ("Uso
                    comercial"). Ejemplos de dicho uso comercial serían, por
                    ejemplo, el uso del arte para producir y vender productos de
                    mercancía (camisetas, etc.) que muestren copias del arte. En
                    aras de la claridad, nada en esta Sección se considerará que
                    le restringe de (i) poseer u operar un mercado que permita
                    el uso y la venta de SWC en general, siempre que el mercado
                    verifique criptográficamente los derechos de cada
                    propietario de SWC para mostrar el Arte para su SWC para
                    garantizar que solo el propietario real pueda mostrar el
                    Arte; (ii) poseer u operar un sitio web o aplicación de
                    terceros que permita la inclusión, participación o
                    participación de SWC en general, siempre que el sitio web o
                    la aplicación de terceros verifique criptográficamente los
                    derechos de cada propietario de SWC para mostrar el Arte
                    para su SWC para garantizar que solo el propietario real
                    pueda mostrar el Arte, y siempre que el Arte ya no sea
                    visible una vez que el propietario del SWC comprado abandone
                    el sitio web / aplicación; o (iii) obtener ingresos de
                    cualquiera de los anteriores.
                  </p>
                )}
                <b>
                  {pathname === "en" && "Terms of Sale"}
                  {pathname === "fr" && "Conditions de vente"}
                  {pathname === "es" && "Condiciones de venta"}
                </b>
                {pathname === "en" && (
                  <p>
                    By placing an order on the Site, you agree that you are
                    submitting a binding offer to purchase a SWC NFT or other
                    Service. If you are the initial purchaser of a SWC NFT or
                    you are purchasing a Service, then all amounts due are to be
                    paid to Isuno, LLC. If you are not the initial purchaser of
                    a SWC NFT, then amounts may be paid to the-then holder of
                    the SWC NFT. You also acknowledge and agree that Company
                    receives 10% of every subsequent sale of a SWC NFT
                    (“Royalty”). For example, if you are the initial purchaser,
                    and you sell a SWC NFT for $100 to a subsequent purchaser,
                    $10 will automatically be transferred to Company and you
                    will receive $90. Company has the right collect Royalties
                    for SWC NFT sales in perpetuity and may use those funds in
                    any manner Company sees fit. Notwithstanding the foregoing,
                    the Company has committed to donate 10% of all Royalties
                    Company receives annually to a charity of Company’s
                    choosing. The Company may make such payment at such time as
                    it desires.
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    En passant une commande sur le Site, vous acceptez de
                    soumettre une offre ferme d'achat d'un SWC NFT ou d'un autre
                    Service. Si vous êtes l'acheteur initial d'un SWC NFT ou si
                    vous achetez un service, tous les montants dus doivent être
                    payés à Isuno, LLC. Si vous n'êtes pas l'acheteur initial
                    d'un SWC NFT, des montants peuvent alors être versés au
                    titulaire du SWC NFT. Vous reconnaissez et acceptez
                    également que la Société reçoive 10 % de chaque vente
                    ultérieure d'un SWC NFT ("Redevance"). Par exemple, si vous
                    êtes l'acheteur initial et que vous vendez un SWC NFT pour
                    100 $ à un acheteur ultérieur, 10 $ seront automatiquement
                    transférés à la Société et vous recevrez 90 $. La société a
                    le droit de percevoir des redevances pour les ventes de SWC
                    NFT à perpétuité et peut utiliser ces fonds de la manière
                    que la société juge appropriée. Nonobstant ce qui précède,
                    la Société s'est engagée à faire don de 10 % de toutes les
                    redevances que la Société reçoit annuellement à un organisme
                    de bienfaisance choisi par la Société . La Société peut
                    effectuer ce paiement au moment qu'elle souhaite. En tant
                    que tel, si vous vendez un NFT SWC sur un marché NFT tiers,
                    vous acceptez d'inclure une déclaration essentiellement
                    similaire à la suivante dans la description du NFT :
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    Al realizar un pedido en el Sitio, usted acepta que está
                    enviando una oferta vinculante para comprar un SWC NFT u
                    otro Servicio. Si usted es el comprador inicial de un SWC
                    NFT o está comprando un Servicio, entonces todos los montos
                    adeudados deben pagarse a Isuno, LLC. Si usted no es el
                    comprador inicial de un SWC NFT, entonces los montos pueden
                    ser pagados al entonces titular del SWC NFT. También
                    reconoce y acepta que la Compañía recibe el 10% de cada
                    venta posterior de un SWC NFT ("Royalty"). Por ejemplo, si
                    usted es el comprador inicial y vende un SWC NFT por $ 100 a
                    un comprador posterior, $ 10 se transferirán automáticamente
                    a la Compañía y recibirá $ 90. La Compañía tiene el derecho
                    de cobrar regalías por las ventas de SWC NFT a perpetuidad y
                    puede usar esos fondos de la manera que la Compañía
                    considere adecuada. No obstante lo anterior, la Compañía se
                    ha comprometido a donar el 10% de todas las Regalías que la
                    Compañía recibe anualmente a una organización benéfica de la
                    elección de la Compañía. La Compañía puede realizar dicho
                    pago en el momento que desee.
                  </p>
                )}
                {pathname === "en" && (
                  <p>
                    As such, if you sell a SWC NFT on a third-party NFT
                    marketplace, you agree to include a statement substantially
                    similar to the following in the description of the NFT:
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    "10% de redevance s'applique. Voir les conditions SWC pour
                    plus de détails.
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    Como tal, si vende un SWC NFT en un mercado NFT de terceros,
                    acepta incluir una declaración sustancialmente similar a la
                    siguiente en la descripción del NFT:
                  </p>
                )}
                {pathname === "en" && (
                  <p>“10% Royalty Applies. See SWC Terms for details.”</p>
                )}
                {pathname === "fr" && (
                  <p>
                    De plus, lorsque vous achetez ou vendez un SWC NFT sur ce
                    Site, vous acceptez de payer tous les frais applicables
                    associés à la transaction et vous autorisez la Société à
                    facturer et à percevoir automatiquement ces frais sur votre
                    paiement. Nous afficherons toujours une ventilation de toute
                    transaction ou autres frais avant votre achat ou vente d'un
                    SWC NFT.
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    "Se aplica un 10% de regalías. Consulte los Términos de SWC
                    para obtener más información". Además, cuando compra o vende
                    un SWC NFT en este Sitio, acepta pagar todas las tarifas
                    aplicables asociadas con la transacción y autoriza a la
                    Compañía a cobrar y cobrar automáticamente dichas tarifas de
                    su pago. Siempre mostraremos un desglose de cualquier
                    transacción u otras tarifas antes de su compra o venta de un
                    SWC NFT.
                  </p>
                )}
                {pathname === "en" && (
                  <>
                    <p>
                      In addition, when you buy or sell a SWC NFT on this Site,
                      you agree to pay all applicable fees associated with the
                      transaction and you authorize Company to automatically
                      charge and collect such fees from your payment. We will
                      always display a breakdown of any transaction or other
                      fees prior to your purchase or sale of a SWC NFT.
                    </p>
                    <p>
                      No refunds are permitted except with respect to any
                      statutory warranties or guaranties that cannot be excluded
                      or limited by law.
                    </p>
                  </>
                )}
                {pathname === "fr" && (
                  <p>
                    Aucun remboursement n'est autorisé, sauf en ce qui concerne
                    les garanties légales ou les garanties qui ne peuvent être
                    exclues ou limitées par la loi.
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    No se permiten reembolsos, excepto con respecto a las
                    garantías legales o garantías que no pueden ser excluidas o
                    limitadas por la ley.
                  </p>
                )}
                <b>
                  {pathname === "en" && "Intellectual Property"}
                  {pathname === "fr" && "Propriété intellectuelle"}
                  {pathname === "es" && "Propiedad intelectual"}
                </b>
                {pathname === "en" && (
                  <p>
                    Other than Site Content, all other trademarks, product
                    names, and logos on the Site are the property of their
                    respective owners and may not be copied, imitated, or used,
                    in whole or in part, without the permission of the
                    applicable trademark holder. Without limiting the foregoing,
                    if you believe that third-party material hosted on the Site
                    infringes your copyright or trademark rights, please file a
                    notice of infringement by contacting Isuno LLC at
                    info@isunousa.com
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    À l'exception du contenu du site, toutes les autres marques,
                    noms de produits et logos sur le site sont la propriété de
                    leurs propriétaires respectifs et ne peuvent être copiés,
                    imités ou utilisés, en tout ou en partie, sans
                    l'autorisation du titulaire de la marque applicable. Sans
                    limiter ce qui précède, si vous pensez que le matériel de
                    tiers hébergé sur le site enfreint vos droits d'auteur ou de
                    marque, veuillez déposer un avis d'infraction en contactant
                    Isuno LLC à info@isunousa.com
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    Aparte del Contenido del Sitio, todas las demás marcas
                    comerciales, nombres de productos y logotipos en el Sitio
                    son propiedad de sus respectivos propietarios y no pueden
                    copiarse, imitarse o usarse, en su totalidad o en parte, sin
                    el permiso del titular de la marca comercial
                    correspondiente. Sin limitar lo anterior, si cree que el
                    material de terceros alojado en el Sitio infringe sus
                    derechos de autor o marca registrada, presente un aviso de
                    infracción poniéndose en contacto con Isuno LLC en
                    info@isunousa.com
                  </p>
                )}
                {pathname === "en" && (
                  <p>
                    The full name and a physical or electronic signature of the
                    person authorized to act on behalf of the copyright owner;
                    Identification of the copyrighted work claimed to have been
                    infringed. If multiple copyrighted works are covered by your
                    notice, you may provide a representative list of the
                    copyrighted works that you claim have been infringed;
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    Le nom complet et une signature physique ou électronique de
                    la personne autorisée à agir au nom du titulaire du droit
                    d'auteur ; Identification de l'œuvre protégée par le droit
                    d'auteur qui aurait été violée. Si plusieurs œuvres
                    protégées par le droit d'auteur sont couvertes par votre
                    avis, vous pouvez fournir une liste représentative des
                    œuvres protégées par le droit d'auteur qui, selon vous, ont
                    été enfreintes ;
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    El nombre completo y una firma física o electrónica de la
                    persona autorizada para actuar en nombre del propietario de
                    los derechos de autor;
                  </p>
                )}
                {pathname === "en" && (
                  <p>
                    Reasonably sufficient detail to enable us to identify and
                    locate the copyrighted work that is claimed to be infringing
                    (e.g. a link to the page on the Site that contains the
                    material); A mailing address, telephone number, and email
                    address where we can contact you;
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    Des détails raisonnablement suffisants pour nous permettre
                    d'identifier et de localiser l'œuvre protégée par le droit
                    d'auteur prétendument enfreinte (par exemple, un lien vers
                    la page du Site qui contient le matériel) ;
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    Identificación del trabajo protegido por derechos de autor
                    que se alega que ha sido infringido. Si su aviso cubre
                    múltiples obras con derechos de autor, puede proporcionar
                    una lista representativa de las obras con derechos de autor
                    que afirma que han sido infringidas; Detalles razonablemente
                    suficientes para permitirnos identificar y localizar el
                    trabajo protegido por derechos de autor que se alega que
                    está infringiendo (por ejemplo, un enlace a la página del
                    Sitio que contiene el material);
                  </p>
                )}
                {pathname === "en" && (
                  <p>
                    A statement that you have a good faith belief that the
                    disputed use of the copyrighted work is not authorized by
                    the copyright owner, its agent, or the law; and A statement
                    made by you, under penalty of perjury, that the information
                    in the notice is accurate and that you are the copyright
                    owner or are authorized to act on behalf of the copyright
                    owner.
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    Une adresse postale, un numéro de téléphone et une adresse
                    e-mail où nous pouvons vous contacter ;
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    Una dirección postal, número de teléfono y dirección de
                    correo electrónico donde podamos comunicarnos con usted; Una
                    declaración de que usted cree de buena fe que el uso
                    disputado del trabajo protegido por derechos de autor no
                    está autorizado por el propietario de los derechos de autor,
                    su agente o la ley; y Una declaración hecha por usted, bajo
                    pena de perjurio, de que la información en el aviso es
                    precisa y que usted es el propietario de los derechos de
                    autor o está autorizado a actuar en nombre del propietario
                    de los derechos de autor.
                  </p>
                )}
                {pathname === "en" && (
                  <p>
                    Please submit your notice to the Designated Agent below:
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    Une déclaration indiquant que vous pensez de bonne foi que
                    l'utilisation contestée de l'œuvre protégée par le droit
                    d'auteur n'est pas autorisée par le titulaire du droit
                    d'auteur, son agent ou la loi ; et
                  </p>
                )}
                {pathname === "es" && (
                  <p>Envíe su aviso al Agente Designado a continuación:</p>
                )}
                {pathname === "en" && (
                  <p>
                    Isuno LLC
                    <br /> Legal Department
                    <br /> 3255 NE 184 TH Street
                    <br /> Suite 12212
                    <br />
                    Aventura, FL 33160
                    <br /> Email: info@isunousa.com
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    Une déclaration faite par vous, sous peine de parjure, que
                    les informations contenues dans l'avis sont exactes et que
                    vous êtes le titulaire du droit d'auteur ou êtes autorisé à
                    agir au nom du titulaire du droit d'auteur.
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    Isuno LLCDepartamento Legal3255 NE 184 TH StreetSuite
                    12212Aventure, FL 33160
                  </p>
                )}
                {pathname === "en" && (
                  <p>
                    Once you provide us with an adequate notice as described
                    above, we will respond expeditiously and take whatever
                    action, in our sole discretion, that is deemed appropriate
                    including removal of the disputed copyrighted work from the
                    Site.
                  </p>
                )}
                {pathname === "fr" && (
                  <>
                    <p>
                      Veuillez soumettre votre avis à l'agent désigné
                      ci-dessous :
                    </p>
                    <p>
                      Isuno LLC
                      <br /> Service juridique <br /> 3255 NE 184 TH Street{" "}
                      <br /> Suite 12212 <br /> Aventura, FL 33160 <br />{" "}
                      Courriel : info@isunousa.com
                    </p>
                    <p>
                      Une fois que vous nous avez fourni un avis adéquat tel que
                      décrit ci-dessus, nous répondrons rapidement et prendrons
                      toutes les mesures, à notre seule discrétion, jugées
                      appropriées, y compris la suppression de l'œuvre protégée
                      par le droit d'auteur contestée du site.
                    </p>
                  </>
                )}
                {pathname === "es" && (
                  <>
                    <p>
                      Correo electrónico:{" "}
                      <a href="info@isunousa.com" target="_blank">
                        info@isunousa.com
                      </a>{" "}
                    </p>
                    <p>
                      Una vez que nos proporcione un aviso adecuado como se
                      describe anteriormente, responderemos rápidamente y
                      tomaremos cualquier acción, a nuestra entera discreción,
                      que se considere apropiada, incluida la eliminación del
                      trabajo con derechos de autor en disputa del Sitio.
                    </p>
                  </>
                )}
                <b>
                  {pathname === "en" && "Counter-Notice:"}
                  {pathname === "fr" && "Contre-avis :"}
                  {pathname === "es" && "Contraaviso:"}
                </b>
                {pathname === "en" && (
                  <p>
                    If you believe that a DMCA notice of copyright infringement
                    has been improperly submitted against you, you may submit a
                    counter-notice to the Designated Agent with the following
                    information required by the DMCA:
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    Si vous pensez qu'un avis DMCA de violation du droit
                    d'auteur a été soumis à tort contre vous, vous pouvez
                    soumettre un contre-avis à l'agent désigné avec les
                    informations suivantes requises par le DMCA :
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    Si cree que un aviso de infracción de derechos de autor de
                    la DMCA se ha enviado incorrectamente en su contra, puede
                    enviar un contraaviso al Agente designado con la siguiente
                    información requerida por la DMCA:
                  </p>
                )}
                {pathname === "en" && (
                  <p>
                    Your physical or electronic signature; Identification of the
                    copyrighted work that has been removed or to which access
                    has been disabled including a link to the page on the Site
                    that contained the material before it was removed or
                    disabled; A statement under penalty of perjury that you have
                    a good faith belief that the copyrighted work was removed or
                    disabled as a result of mistake or misidentification;
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    Votre signature physique ou électronique ; Identification de
                    l'œuvre protégée par le droit d'auteur qui a été supprimée
                    ou dont l'accès a été désactivé, y compris un lien vers la
                    page du Site qui contenait le matériel avant qu'il ne soit
                    supprimé ou désactivé ; Une déclaration sous peine de
                    parjure selon laquelle vous croyez de bonne foi que l'œuvre
                    protégée par le droit d'auteur a été supprimée ou désactivée
                    à la suite d'une erreur ou d'une identification erronée ;
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    Su firma física o electrónica; Identificación del trabajo
                    protegido por derechos de autor que se ha eliminado o al que
                    se ha deshabilitado el acceso, incluido un enlace a la
                    página en el Sitio que contenía el material antes de que se
                    eliminara o deshabilitara; Una declaración bajo pena de
                    perjurio de que usted cree de buena fe que el trabajo
                    protegido por derechos de autor fue eliminado o
                    deshabilitado como resultado de un error o identificación
                    errónea;
                  </p>
                )}
                {pathname === "en" && (
                  <p>
                    Your name, address, e-mail address, and telephone number;
                    and A statement that you (i) consent to the jurisdiction of
                    the Federal District Court in the judicial district where
                    your address is located if the address is in the United
                    States, or the United District Court for the District of
                    Delaware if your address is located outside of the United
                    States, and (ii) accept service of process from the person
                    who provided the DMCA notice of the alleged copyright
                    infringement.
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    Votre nom, adresse, adresse e-mail et numéro de téléphone ;
                    et Une déclaration selon laquelle vous (i) consentez à la
                    juridiction du tribunal de district fédéral du district
                    judiciaire où votre adresse est située si l'adresse est aux
                    États-Unis, ou du tribunal de district uni du district du
                    Delaware si votre adresse est située à l'extérieur des
                    États-Unis, et (ii) accepter la signification de la
                    procédure de la personne qui a fourni l'avis DMCA de la
                    violation présumée du droit d'auteur.
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    Su nombre, dirección, dirección de correo electrónico y
                    número de teléfono; y
                  </p>
                )}
                {pathname === "en" && (
                  <p>
                    Please submit your notice to the Designated Agent below:
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    Veuillez soumettre votre avis à l'agent désigné ci-dessous :
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    Una declaración de que usted (i) acepta la jurisdicción del
                    Tribunal Federal de Distrito en el distrito judicial donde
                    se encuentra su dirección si la dirección está en los
                    Estados Unidos, o el Tribunal de Distrito Unido para el
                    Distrito de Delaware si su dirección se encuentra fuera de
                    los Estados Unidos, y (ii) acepta la notificación del
                    proceso de la persona que proporcionó el aviso de la DMCA de
                    la presunta infracción de derechos de autor.
                  </p>
                )}
                {pathname === "en" && (
                  <p>
                    Isuno LLC
                    <br /> Legal Department
                    <br /> 3255NE 184Th Street
                    <br /> Suite 12212
                    <br />
                    Miami, FL 33160
                    <br /> Email: info@isunousa.com
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    Isuno LLC <br />
                    Service juridique <br />
                    3255NE 184Th Street <br />
                    Suite 12212 <br />
                    Miami, FL 331 60
                    <br />
                    Courriel : info@isunousa.com
                  </p>
                )}
                {pathname === "es" && (
                  <p>Envíe su aviso al Agente Designado a continuación:</p>
                )}
                {pathname === "en" && (
                  <p>
                    In the event that the Company receives a counter-notice in
                    compliance with the above requirements, we will provide the
                    person who submitted the DMCA copyright infringement notice
                    with a copy of the counter-notice, informing them that the
                    Company will replace the removed material in 10 business
                    days from the date of the counter-notice unless the Company
                    first receives notice from the person who submitted the DMCA
                    copyright infringement notice that they have filed an action
                    seeking a court order to restrain the allegedly infringing
                    activity.
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    Dans le cas où la Société recevrait un contre-avis
                    conformément aux exigences ci-dessus, nous fournirons à la
                    personne qui a soumis l'avis de violation du droit d'auteur
                    DMCA une copie du contre-avis, l'informant que la Société
                    remplacera le matériel supprimé dans 10 jours ouvrables à
                    compter de la date de la contre-notification à moins que la
                    Société ne reçoive d'abord un avis de la personne qui a
                    soumis la notification de violation du droit d'auteur DMCA
                    qu'elle a intenté une action en justice pour empêcher
                    l'activité prétendument contrefaite.
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    Isuno LLCDepartamento Legal3255NE 184Th StreetSuite
                    12212Miami, FL 33160
                  </p>
                )}
                {pathname === "en" && (
                  <p>
                    PLEASE NOTE THAT ISUNO LLC. INTENDS TO COMPLY WITH ALL
                    PROVISIONS OF THE DIGITAL MILLENNIUM COPYRIGHT ACT, BUT WILL
                    NOT UNILATERALLY TAKE RESPONSIBILITY FOR POLICING AND
                    REMOVING MATERIAL THOUGHT TO BE INFRINGING.
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    VEUILLEZ NOTER QUE ISUNO LLC. A L'INTENTION DE SE CONFORMER
                    À TOUTES LES DISPOSITIONS DU DIGITAL MILLENNIUM COPYRIGHT
                    ACT, MAIS N'ASSUMERA PAS UNILATÉRALEMENT LA RESPONSABILITÉ
                    DU CONTRÔLE ET DE LA SUPPRESSION DU MATÉRIEL PENSÉ COMME
                    VIOLANT.
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    Correo electrónico:{" "}
                    <a href="info@isunousa.com" target="_blank">
                      info@isunousa.com
                    </a>{" "}
                  </p>
                )}
                {pathname === "en" && (
                  <p>
                    We hereby grant you a limited, non-exclusive,
                    non-transferable, revocable license to access and use the
                    Site Content. In return, you agree not to engage, or assist,
                    in any activity that violates any law, statute, ordinance,
                    regulation, or sanctions program, including but not limited
                    to the U.S. Department of Treasury’s Office of Foreign
                    Assets Control (“OFAC”), or that involves proceeds of any
                    unlawful activity; not to engage in any other activity or
                    behavior that poses a threat to Isuno LLC, (e.g., by
                    distributing a virus or other harmful code, or through
                    unauthorized access to the Site or other users’
                    cryptocurrency wallets and not to interfere with other
                    users’ access to or use of the Services.
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    Nous vous accordons par la présente une licence limitée, non
                    exclusive, non transférable et révocable pour accéder et
                    utiliser le contenu du site. En retour, vous acceptez de ne
                    pas vous engager ou d'aider à toute activité qui viole une
                    loi, un statut, une ordonnance, un règlement ou un programme
                    de sanctions, y compris, mais sans s'y limiter, l'Office of
                    Foreign Assets Control ("OFAC") du Département du Trésor des
                    États-Unis. , ou qui implique le produit d'une activité
                    illégale ; de ne pas s'engager dans toute autre activité ou
                    comportement qui constitue une menace pour Isuno LLC, (par
                    exemple, en distribuant un virus ou un autre code nuisible,
                    ou par un accès non autorisé au site ou aux portefeuilles de
                    crypto-monnaie d'autres utilisateurs et de ne pas interférer
                    avec les autres utilisateurs l'accès ou l'utilisation des
                    Services. Vous acceptez également de ne pas (1) distribuer,
                    publier, diffuser, reproduire, copier, retransmettre ou
                    afficher publiquement tout contenu du site ; (2) modifier ou
                    créer des œuvres dérivées du contenu du site, ou de toute
                    partie de celui-ci ; (3) utiliser des méthodes d'exploration
                    de données, des robots ou des méthodes similaires de
                    collecte ou d'extraction de données sur le contenu du site ;
                    (4) télécharger toute partie du contenu du site, autre qu'à
                    des fins de mise en cache des pages, sauf autorisation
                    expresse de notre part.
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    En el caso de que la Compañía reciba una contranotificación
                    de conformidad con los requisitos anteriores,
                    proporcionaremos a la persona que envió el aviso de
                    infracción de derechos de autor de la DMCA una copia del
                    contraaviso, informándole que la Compañía reemplazará el
                    material eliminado en 10 días hábiles a partir de la fecha
                    de la contranotificación, a menos que la Compañía reciba
                    primero un aviso de la persona que envió el aviso de
                    infracción de derechos de autor de la DMCA de que ha
                    presentado una acción. solicitar una orden judicial para
                    restringir la actividad presuntamente infractora.
                  </p>
                )}
                {pathname === "en" && (
                  <p>
                    You also agree not to (1) distribute, publish, broadcast,
                    reproduce, copy, retransmit, or publicly display any Site
                    Content; (2) modify or create derivative works from the Site
                    Content, or any portion thereof; (3) use any data mining,
                    robots, or similar data gathering or extraction methods on
                    the Site Content; (4) download any portion of the Site
                    Content, other than for purposes of page caching, except as
                    expressly permitted by us.
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    En ce qui concerne les SWC NFT, chaque acheteur d'un SWC NFT
                    se voit accorder une licence exclusive et limitée sur ce SWC
                    NFT et son contenu pour accéder, utiliser ou stocker ce SWC
                    NFT et son contenu uniquement à des fins personnelles et non
                    commerciales. Les NFT SWC sont une création numérique en
                    édition limitée basée sur un contenu qui peut être déposé
                    et/ou protégé par le droit d'auteur par la Société. Sauf
                    indication contraire, votre achat d'un SWC NFT ne vous donne
                    pas le droit d'afficher, d'exécuter, de distribuer, de
                    vendre ou de reproduire publiquement le SWC NFT ou son
                    contenu à des fins commerciales. Vous acceptez en outre que
                    vous ne recevez aucun droit d'auteur sur le SWC NFT ou son
                    contenu, et en effet, vous acceptez que la Société puisse
                    vendre, concéder sous licence, modifier, afficher, diffuser
                    et créer des œuvres dérivées basées sur votre SWC NFT ou son
                    contenu. Toute exploitation commerciale du SWC NFT pourrait
                    vous exposer à des réclamations pour violation du droit
                    d'auteur. Si vous vendez un SWC NFT via le Site, vous
                    acceptez de ne pas avoir de recours contre nous pour toute
                    violation de ces Conditions par un acheteur. Si vous achetez
                    un SWC NFT sur le Site, vous acceptez par la présente de
                    nous dégager, ainsi que le vendeur de ce SWC NFT, de toute
                    responsabilité en cas de violation ou de violation des
                    présentes Conditions.
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    TENGA EN CUENTA QUE ISUNO LLC. TIENE LA INTENCIÓN DE CUMPLIR
                    CON TODAS LAS DISPOSICIONES DE LA LEY DE DERECHOS DE AUTOR
                    DEL MILENIO DIGITAL, PERO NO ASUMIRÁ UNILATERALMENTE LA
                    RESPONSABILIDAD DE VIGILAR Y ELIMINAR EL MATERIAL QUE SE
                    CONSIDERE INFRACTOR.
                  </p>
                )}
                {pathname === "en" && (
                  <>
                    <p>
                      With respect to the SWC NFTs, each purchaser of a SWC NFT
                      is granted an exclusive, limited license to such SWC NFT
                      and its content to access, use, or store such SWC NFT and
                      its content solely for their personal, non-commercial
                      purposes. SWC NFTs are a limited-edition digital creation
                      based upon content that may be trademarked and/or
                      copyrighted by Company. Unless otherwise specified, your
                      purchase of a SWC NFT does not give you the right to
                      publicly display, perform, distribute, sell or otherwise
                      reproduce the SWC NFT or its content for any commercial
                      purpose. You further agree that you are not receiving any
                      copyright interest in the SWC NFT or its content, and
                      indeed, you agree that Company may sell, license, modify,
                      display, broadcast and create derivative works based upon
                      your SWC NFT or its content. Any commercial exploitation
                      of the SWC NFT could subject you to claims of copyright
                      infringement. If you sell a SWC NFT through the Site, you
                      agree that you will not have any claims against us for any
                      breach of these Terms by a purchaser. If you purchase a
                      SWC NFT on the Site, you hereby agree to hold us and the
                      seller of such SWC NFT harmless from and against any and
                      all violations or breaches of these Terms.
                    </p>
                    <p>
                      If you are unsure whether a contemplated use of the Site
                      Content or a SWC NFT and its content would violate these
                      Terms, please contact us at info@superwildclub.com.
                    </p>
                  </>
                )}
                {pathname === "fr" && (
                  <p>
                    Si vous ne savez pas si une utilisation envisagée du contenu
                    du site ou d'un SWC NFT et de son contenu violerait ces
                    conditions, veuillez nous contacter à
                    info@superwildclub.com.
                  </p>
                )}
                {pathname === "es" && (
                  <>
                    <p>
                      Por la presente, le otorgamos una licencia limitada, no
                      exclusiva, intransferible y revocable para acceder y
                      utilizar el Contenido del Sitio. A cambio, usted acepta no
                      participar, ni ayudar, en ninguna actividad que viole
                      cualquier ley, estatuto, ordenanza, regulación o programa
                      de sanciones, incluyendo pero no limitado a la Oficina de
                      Control de Activos Extranjeros ("OFAC") del Departamento
                      del Tesoro de los Estados Unidos, o que involucre el
                      producto de cualquier actividad ilegal; no participar en
                      ninguna otra actividad o comportamiento que represente una
                      amenaza para Isuno LLC, (por ejemplo, mediante la
                      distribución de un virus u otro código dañino, o a través
                      del acceso no autorizado al Sitio o a las billeteras de
                      criptomonedas de otros usuarios y no interferir con el
                      acceso o uso de los Servicios por parte de otros usuarios.
                    </p>
                    <p>
                      También acepta no (1) distribuir, publicar, transmitir,
                      reproducir, copiar, retransmitir o mostrar públicamente
                      ningún Contenido del Sitio; (2) modificar o crear trabajos
                      derivados del Contenido del Sitio, o cualquier parte del
                      mismo; (3) utilizar cualquier minería de datos, robots o
                      métodos similares de recopilación o extracción de datos en
                      el Contenido del Sitio; (4) descargar cualquier parte del
                      Contenido del Sitio, excepto para fines de almacenamiento
                      en caché de la página, excepto según lo expresamente
                      permitido por nosotros. Con respecto a los NFT de SWC, a
                      cada comprador de un SWC NFT se le concede una licencia
                      exclusiva y limitada para dicho SWC NFT y su contenido
                      para acceder, usar o almacenar dicho SWC NFT y su
                      contenido únicamente para sus fines personales y no
                      comerciales. Los NFT de SWC son una creación digital de
                      edición limitada basada en contenido que puede ser
                      registrado y / o protegido por derechos de autor por la
                      Compañía. A menos que se especifique lo contrario, su
                      compra de un SWC NFT no le otorga el derecho de mostrar
                      públicamente, realizar, distribuir, vender o reproducir de
                      otro modo el SWC NFT o su contenido para cualquier
                      propósito comercial. Además, acepta que no está recibiendo
                      ningún interés de derechos de autor en SWC NFT o su
                      contenido, y de hecho, acepta que la Compañía puede
                      vender, licenciar, modificar, mostrar, transmitir y crear
                      trabajos derivados basados en su SWC NFT o su contenido.
                      Cualquier explotación comercial de SWC NFT podría
                      someterlo a reclamaciones de infracción de derechos de
                      autor. Si vende un SWC NFT a través del Sitio, acepta que
                      no tendrá ningún reclamo contra nosotros por cualquier
                      incumplimiento de estos Términos por parte de un
                      comprador. Si compra un SWC NFT en el Sitio, por la
                      presente acepta eximirnos a nosotros y al vendedor de
                      dicho SWC NFT de y contra todas y cada una de las
                      violaciones o incumplimientos de estos Términos.
                    </p>
                    <p>
                      Si no está seguro de si un uso contemplado del Contenido
                      del Sitio o de un SWC NFT y su contenido violaría estos
                      Términos, comuníquese con nosotros en
                      info@superwildclub.com.
                    </p>
                  </>
                )}

                <b>
                  {pathname === "en" && "Taxes"}
                  {pathname === "fr" && "Impôts"}
                  {pathname === "es" && "Impuestos"}
                </b>
                {pathname === "en" && (
                  <p>
                    We are not responsible for determining the withholding,
                    sales, use, value added, transfer or other taxes, together
                    with any interest and penalties imposed with respect thereto
                    (“Taxes”), that may apply to transactions on this Site. You
                    agree that you are solely responsible for determining what,
                    if any, Taxes apply to your transactions and to withhold,
                    collect, report and remit the correct amounts of Taxes to
                    the appropriate taxing authorities. Unless otherwise
                    indicated on an applicable invoice, amounts due on this Site
                    are exclusive of sale, use, value added or similar Taxes
                    (“Sales Taxes”). This means that Sales Taxes become your
                    sole responsibility. Upon our request, you agree to promptly
                    provide a properly executed Internal Revenue Service Form
                    W-9 or applicable Internal Revenue Service W-8 and any other
                    tax form that is reasonably required by us to comply with
                    our tax reporting obligations.
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    Nous ne sommes pas responsables de la détermination des
                    retenues à la source, des ventes, de l'utilisation, de la
                    valeur ajoutée, des transferts ou autres, ainsi que des
                    intérêts et pénalités imposés à cet égard ("Taxes"), qui
                    peuvent s'appliquer aux transactions sur ce Site. Vous
                    convenez que vous êtes seul responsable de déterminer
                    quelles Taxes, le cas échéant, s'appliquent à vos
                    transactions et de retenir, collecter, déclarer et remettre
                    les montants corrects de Taxes aux autorités fiscales
                    appropriées. Sauf indication contraire sur une facture
                    applicable, les montants dus sur ce site s'entendent hors
                    taxes de vente, d'utilisation, sur la valeur ajoutée ou
                    similaires (« taxes de vente »). Cela signifie que les taxes
                    de vente deviennent votre seule responsabilité. À notre
                    demande, vous acceptez de fournir rapidement un formulaire
                    W-9 de l'administration fiscale dûment rempli ou un
                    formulaire W-8 de l'administration fiscale applicable et
                    tout autre formulaire fiscal dont nous avons raisonnablement
                    besoin pour nous conformer à nos obligations en matière de
                    déclaration fiscale.
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    No somos responsables de determinar la retención, las
                    ventas, el uso, el valor agregado, la transferencia u otros
                    impuestos, junto con los intereses y multas impuestos con
                    respecto a los mismos ("Impuestos"), que puedan aplicarse a
                    las transacciones en este Sitio. Usted acepta que es el
                    único responsable de determinar qué Impuestos, si los hay,
                    se aplican a sus transacciones y de retener, recaudar,
                    informar y remitir los montos correctos de impuestos a las
                    autoridades fiscales correspondientes. A menos que se
                    indique lo contrario en una factura aplicable, los montos
                    adeudados en este Sitio no incluyen los impuestos de venta,
                    uso, valor agregado o similares ("Impuestos sobre las
                    ventas"). Esto significa que los impuestos sobre las ventas
                    se convierten en su única responsabilidad. A petición
                    nuestra, usted acepta proporcionar de inmediato un
                    Formulario W-9 del Servicio de Impuestos Internos
                    correctamente ejecutado o el Servicio de Impuestos Internos
                    W-8 aplicable y cualquier otro formulario de impuestos que
                    sea razonablemente requerido por nosotros para cumplir con
                    nuestras obligaciones de declaración de impuestos.
                  </p>
                )}
                <b>
                  {pathname === "en" && "Privacy"}
                  {pathname === "fr" && "Confidentialité"}
                  {pathname === "es" && "Privacidad"}
                </b>
                {pathname === "en" && (
                  <p>
                    You acknowledge and agree to the collection, use, and
                    disclosure of your personal information in accordance with
                    our Privacy Policy, which is incorporated into these Terms.
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    Vous reconnaissez et acceptez la collecte, l'utilisation et
                    la divulgation de vos informations personnelles conformément
                    à notre politique de confidentialité, qui est intégrée aux
                    présentes conditions.
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    Usted reconoce y acepta la recopilación, el uso y la
                    divulgación de su información personal de acuerdo con
                    nuestra Política de privacidad, que se incorpora a estos
                    Términos.
                  </p>
                )}
                <b>
                  {pathname === "en" && "Modifications"}
                  {pathname === "fr" && "Modifications"}
                  {pathname === "es" && "Modificaciones"}
                </b>
                {pathname === "en" && (
                  <p>
                    You agree and understand that we may modify part or all of
                    this Site or the Services without notice, and that we may
                    update these Terms and any other document incorporated by
                    reference therein at any time.
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    Vous acceptez et comprenez que nous pouvons modifier tout ou
                    partie de ce Site ou des Services sans préavis, et que nous
                    pouvons mettre à jour ces Conditions et tout autre document
                    incorporé par référence à tout moment.
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    Usted acepta y entiende que podemos modificar parte o la
                    totalidad de este Sitio o los Servicios sin previo aviso, y
                    que podemos actualizar estos Términos y cualquier otro
                    documento incorporado por referencia en los mismos en
                    cualquier momento.
                  </p>
                )}
                <b>
                  {pathname === "en" && "Risks"}
                  {pathname === "fr" && "Des risques"}
                  {pathname === "es" && "Riesgos"}
                </b>
                {pathname === "en" && (
                  <p>
                    Please note the following risks in accessing, purchasing,
                    selling or using SWC NFTs: The price and liquidity of
                    blockchain assets, including SWC NFTs, are extremely
                    volatile and may be subject to large fluctuations.
                    Fluctuations in the price of other digital assets could
                    materially and adversely affect SWC NFTs, which may also be
                    subject to significant price volatility. Legislative and
                    regulatory changes or actions at the state, federal, or
                    international level may adversely affect the use, transfer,
                    exchange, and value of SWC NFTs. SWC NFTs are not
                    legal tender and are not backed by any government.
                    Transactions in SWC NFTs may be irreversible, and,
                    accordingly, losses due to fraudulent or accidental
                    transactions may not be recoverable. Some transactions in
                    SWC NFTs shall be deemed to be made when recorded on a
                    public ledger, which is not necessarily the date or time
                    that you initiated the transaction. The value of SWC NFTs
                    may be derived from the continued willingness of market
                    participants to exchange fiat currency or digital assets for
                    SWC NFTs, which may result in the potential for permanent
                    and total loss of value of a particular SWC NFT should the
                    market for that SWC NFT disappear.
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    Veuillez noter les risques suivants lors de l'accès, de
                    l'achat, de la vente ou de l'utilisation des NFT SWC : Le
                    prix et la liquidité des actifs de la blockchain, y compris
                    les NFT SWC, sont extrêmement volatils et peuvent être
                    soumis à de fortes fluctuations. Les fluctuations du prix
                    d'autres actifs numériques pourraient affecter de manière
                    significative et négative les NFT SWC, qui peuvent également
                    être soumis à une volatilité des prix importante. Les
                    modifications ou actions législatives et réglementaires au
                    niveau de l'État, fédéral ou international peuvent avoir un
                    effet négatif sur l'utilisation, le transfert, l'échange et
                    la valeur des NFT SWC. Les NFT de SWC n'ont pas cours
                    légal et ne sont soutenus par aucun gouvernement. Les
                    transactions dans les NFT SWC peuvent être irréversibles et,
                    par conséquent, les pertes dues à des transactions
                    frauduleuses ou accidentelles peuvent ne pas être
                    récupérables. Certaines transactions dans les NFT SWC sont
                    réputées avoir été effectuées lorsqu'elles sont enregistrées
                    dans un grand livre public, ce qui n'est pas nécessairement
                    la date ou l'heure à laquelle vous avez initié la
                    transaction. La valeur des NFT SWC peut découler de la
                    volonté continue des acteurs du marché d'échanger de la
                    monnaie fiduciaire ou des actifs numériques contre des NFT
                    SWC, ce qui peut entraîner une perte de valeur permanente et
                    totale d'un NFT SWC particulier si le marché de ce NFT SWC
                    NFT disparaissent.
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    Tenga en cuenta los siguientes riesgos al acceder, comprar,
                    vender o usar NFT de SWC: El precio y la liquidez de los
                    activos de blockchain, incluidos los NFT de SWC, son
                    extremadamente volátiles y pueden estar sujetos a grandes
                    fluctuaciones. Las fluctuaciones en el precio de otros
                    activos digitales podrían afectar material y negativamente a
                    los NFT de SWC, que también pueden estar sujetos a una
                    volatilidad significativa de los precios. Los cambios o
                    acciones legislativas y reglamentarias a nivel estatal,
                    federal o internacional pueden afectar negativamente el uso,
                    la transferencia, el intercambio y el valor de los NFT de
                    SWC. Los NFT de SWC no son de curso legal y no están
                    respaldados por ningún gobierno. Las transacciones en NFT de
                    SWC pueden ser irreversibles y, en consecuencia, las
                    pérdidas debidas a transacciones fraudulentas o accidentales
                    pueden no ser recuperables. Algunas transacciones en los NFT
                    de SWC se considerarán realizadas cuando se registren en un
                    libro mayor público, que no es necesariamente la fecha u
                    hora en que inició la transacción. El valor de los NFT de
                    SWC puede derivarse de la voluntad continua de los
                    participantes en el mercado de intercambiar moneda
                    fiduciaria o activos digitales por NFT de SWC, lo que puede
                    dar lugar a la posibilidad de pérdida permanente y total de
                    valor de un NFT de SWC en particular en caso de que
                    desaparezca el mercado de ese NFT de SWC.
                  </p>
                )}
                {pathname === "en" && (
                  <p>
                    You agree and understand that you are solely responsible for
                    determining the nature, potential value, suitability, and
                    appropriateness of these risks for yourself, and that we do
                    not give advice or recommendations regarding SWC NFTs,
                    including the suitability and appropriateness of, and
                    investment strategies for, SWC NFTs. You agree and
                    understand that you access and use this Site at your own
                    risk; however, this brief statement does not disclose all of
                    the risks associated with SWC NFTs and other digital assets.
                    You agree and understand that we will not be responsible for
                    any communication failures, disruptions, errors, distortions
                    or delays you may experience when using SWC NFT, however
                    caused.
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    Vous acceptez et comprenez que vous êtes seul responsable de
                    la détermination de la nature, de la valeur potentielle, de
                    l'adéquation et de la pertinence de ces risques pour
                    vous-même, et que nous ne donnons pas de conseils ou de
                    recommandations concernant les SWC NFT, y compris la
                    pertinence et la pertinence des stratégies d'investissement.
                    pour les SWC NFT. Vous acceptez et comprenez que vous
                    accédez et utilisez ce site à vos propres risques ;
                    cependant, cette brève déclaration ne divulgue pas tous les
                    risques associés aux NFT SWC et autres actifs numériques.
                    Vous acceptez et comprenez que nous ne serons pas
                    responsables des pannes de communication, des perturbations,
                    des erreurs, des distorsions ou des retards que vous
                    pourriez rencontrer lors de l'utilisation de SWC NFT, quelle
                    qu'en soit la cause.
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    Usted acepta y entiende que es el único responsable de
                    determinar la naturaleza, el valor potencial, la idoneidad y
                    la idoneidad de estos riesgos para usted, y que no brindamos
                    asesoramiento o recomendaciones con respecto a los NFT de
                    SWC, incluida la idoneidad y la idoneidad de los NFT de SWC
                    y las estrategias de inversión para ellos. Usted acepta y
                    entiende que accede y utiliza este Sitio bajo su propio
                    riesgo; sin embargo, esta breve declaración no revela todos
                    los riesgos asociados con los NFT de SWC y otros activos
                    digitales. Usted acepta y entiende que no seremos
                    responsables de ningún fallo de comunicación, interrupción,
                    error, distorsión o retraso que pueda experimentar al usar
                    SWC NFT, independientemente de su causa.
                  </p>
                )}
                <b>
                  {pathname === "en" && "Disclaimers"}
                  {pathname === "fr" && "Avis de non-responsabilité"}
                  {pathname === "es" && "Renuncias"}
                </b>
                {pathname === "en" && (
                  <p>
                    EXCEPT AS EXPRESSLY PROVIDED TO THE CONTRARY IN A WRITING BY
                    ISUNO LLC, THE SITE AND ANY SITE CONTENT CONTAINED THEREIN,
                    AND ANY AND ALL SWC YACHT CLUB NFTS LISTED THEREIN ARE
                    PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT
                    WARRANTIES OR CONDITIONS OF ANY KIND, EITHER EXPRESS OR
                    IMPLIED. SWC (AND ITS SUPPLIERS) MAKE NO WARRANTY THAT THE
                    SITE WILL (1) MEET YOUR REQUIREMENTS; (2) BE AVAILABLE ON AN
                    UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS; OR (3)
                    BE ACCURATE, RELIABLE, COMPLETE, LEGAL, OR SAFE.
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    SAUF INDICATION CONTRAIRE EXPRESSE DANS UN ÉCRIT D'ISUNO
                    LLC, LE SITE ET TOUT CONTENU DU SITE QU'IL CONTIENT, AINSI
                    QUE TOUS LES NFTS DU SWC YACHT CLUB ÉNUMÉRÉS SONT FOURNIS
                    « EN L'ÉTAT » ET « SELON LA DISPONIBILITÉ », SANS GARANTIE
                    OU CONDITIONS DE QUELQUE NATURE QUE CE SOIT, EXPRESSES OU
                    IMPLICITES. SWC (ET SES FOURNISSEURS) NE GARANTISSENT PAS
                    QUE LE SITE (1) RÉPONDRA À VOS BESOINS ; (2) ÊTRE DISPONIBLE
                    DE FAÇON ININTERROMPUE, OPPORTUNE, SÉCURISÉE OU SANS
                    ERREUR ; OU (3) ÊTRE EXACT, FIABLE, COMPLET, LÉGAL OU SÛR.
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    SALVO QUE ISUNO LLC DISPONGA EXPRESAMENTE LO CONTRARIO POR
                    ESCRITO, EL SITIO Y CUALQUIER CONTENIDO DEL SITIO CONTENIDO
                    EN EL MISMO, Y TODOS Y CADA UNO DE LOS NFT DE SWC YACHT
                    CLUB ENUMERADOS EN EL MISMO SE PROPORCIONAN "TAL CUAL" Y
                    "SEGÚN DISPONIBILIDAD" SIN GARANTÍAS NI CONDICIONES DE
                    NINGÚN TIPO, YA SEAN EXPRESAS O IMPLÍCITAS. SWC (Y SUS
                    PROVEEDORES) NO GARANTIZAN QUE EL SITIO (1) CUMPLA CON SUS
                    REQUISITOS; (2) ESTAR DISPONIBLE DE FORMA ININTERRUMPIDA,
                    OPORTUNA, SEGURA O LIBRE DE ERRORES; O (3) SER PRECISO,
                    CONFIABLE, COMPLETO, LEGAL O SEGURO.
                  </p>
                )}
                {pathname === "en" && (
                  <p>
                    WE WILL NOT BE LIABLE FOR ANY LOSS OF ANY KIND FROM ANY
                    ACTION TAKEN OR NOT TAKEN IN RELIANCE ON MATERIAL OR
                    INFORMATION CONTAINED ON THE SITE. SWC DOES NOT REPRESENT OR
                    WARRANT THAT SITE CONTENT IS ACCURATE, COMPLETE, RELIABLE,
                    CURRENT OR ERROR-FREE.
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    NOUS NE SERONS PAS RESPONSABLES DE TOUTE PERTE DE QUELQUE
                    NATURE QUE CE SOIT À LA SUITE DE TOUTE MESURE PRISE OU NON
                    PRISE SUR LA BASE DU MATÉRIEL OU DES INFORMATIONS CONTENUES
                    SUR LE SITE. SWC NE REPRÉSENTE NI NE GARANTIT QUE LE CONTENU
                    DU SITE EST EXACT, COMPLET, FIABLE, ACTUEL OU SANS ERREUR.
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    NO SEREMOS RESPONSABLES DE NINGUNA PÉRDIDA DE NINGÚN TIPO
                    POR CUALQUIER ACCIÓN TOMADA O NO TOMADA EN BASE AL MATERIAL
                    O LA INFORMACIÓN CONTENIDA EN EL SITIO. SWC NO DECLARA NI
                    GARANTIZA QUE EL CONTENIDO DEL SITIO SEA PRECISO, COMPLETO,
                    CONFIABLE, ACTUAL O LIBRE DE ERRORES.
                  </p>
                )}
                {pathname === "en" && (
                  <p>
                    WHILE WE ATTEMPT TO MAKE YOUR ACCESS TO AND USE OF THE SITE
                    AND SITE CONTENT SAFE, WE CANNOT AND DO NOT REPRESENT OR
                    WARRANT THAT THE SITE, SITE CONTENT, ANY SWC NFTS LISTED ON
                    OUR SITE OR OUR SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL
                    COMPONENTS. WE CANNOT GUARANTEE THE SECURITY OF ANY DATA
                    THAT YOU DISCLOSE ONLINE. YOU ACCEPT THE INHERENT SECURITY
                    RISKS OF PROVIDING INFORMATION AND DEALING ONLINE OVER THE
                    INTERNET AND WILL NOT HOLD US RESPONSIBLE FOR ANY BREACH OF
                    SECURITY UNLESS IT IS DUE TO OUR GROSS NEGLIGENCE.
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    BIEN QUE NOUS ESSAYONS DE SÉCURISER VOTRE ACCÈS ET VOTRE
                    UTILISATION DU SITE ET DU CONTENU DU SITE, NOUS NE POUVONS
                    PAS DÉCLARER OU GARANTIR QUE LE SITE, LE CONTENU DU SITE,
                    TOUT SWC NFTS ÉNUMÉRÉ SUR NOTRE SITE OU NOS SERVEURS SONT
                    EXEMPTS DE VIRUS OU D'AUTRES NUISIBLES. COMPOSANTS. NOUS NE
                    POUVONS GARANTIR LA SÉCURITÉ DES DONNÉES QUE VOUS DIVULGUEZ
                    EN LIGNE. VOUS ACCEPTEZ LES RISQUES DE SÉCURITÉ INHÉRENTS À
                    LA FOURNITURE D'INFORMATIONS ET À LA TRANSACTION EN LIGNE
                    SUR INTERNET ET NE NOUS TENIRONS PAS RESPONSABLES DE TOUTE
                    VIOLATION À LA SÉCURITÉ SAUF SI ELLE EST DUE À NOTRE
                    NÉGLIGENCE GRAVE.
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    SI BIEN INTENTAMOS HACER QUE SU ACCESO Y USO DEL SITIO Y EL
                    CONTENIDO DEL SITIO SEAN SEGUROS, NO PODEMOS Y NO DECLARAMOS
                    NI GARANTIZAMOS QUE EL SITIO, EL CONTENIDO DEL SITIO,
                    CUALQUIER SWC NFTS ENUMERADO EN NUESTRO SITIO O NUESTROS
                    SERVIDORES ESTÉN LIBRES DE VIRUS U OTROS COMPONENTES
                    DAÑINOS. NO PODEMOS GARANTIZAR LA SEGURIDAD DE LOS DATOS QUE
                    USTED DIVULGUE EN LÍNEA. USTED ACEPTA LOS RIESGOS DE
                    SEGURIDAD INHERENTES DE PROPORCIONAR INFORMACIÓN Y TRATAR EN
                    LÍNEA A TRAVÉS DE INTERNET Y NO NOS HARÁ RESPONSABLES DE
                    NINGUNA VIOLACIÓN DE LA SEGURIDAD A MENOS QUE SE DEBA A
                    NUESTRA NEGLIGENCIA GRAVE.
                  </p>
                )}
                {pathname === "en" && (
                  <p>
                    WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSS AND
                    TAKE NO RESPONSIBILITY FOR, AND WILL NOT BE LIABLE TO YOU
                    FOR, ANY USE OF SUPER WILD CLUB NFTS INCLUDING BUT NOT
                    LIMITED TO ANY LOSSES, DAMAGES OR CLAIMS ARISING FROM: (A)
                    USER ERROR SUCH AS FORGOTTEN PASSWORDS, INCORRECTLY
                    CONSTRUCTED TRANSACTIONS, OR MISTYPED ADDRESSES; (B) SERVER
                    FAILURE OR DATA LOSS; (C) CORRUPTED WALLET FILES; (D)
                    UNAUTHORIZED ACCESS TO APPLICATIONS; (E) ANY UNAUTHORIZED
                    THIRD PARTY ACTIVITIES, INCLUDING WITHOUT LIMITATION THE USE
                    OF VIRUSES, PHISHING, BRUTE FORCING OR OTHER MEANS OF ATTACK
                    AGAINST THE SITE OR SUPER WILD CLUB NFTS.
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    NOUS NE SERONS PAS RESPONSABLES ENVERS VOUS POUR TOUTE PERTE
                    ET N'ASSUMONS AUCUNE RESPONSABILITÉ POUR TOUTE UTILISATION
                    DE SUPER WILD CLUB NFTS, Y COMPRIS, MAIS SANS S'Y LIMITER,
                    TOUTE PERTE, DOMMAGE OU RÉCLAMATION DÉCOULANT DE : (UN )
                    ERREUR D'UTILISATEUR TELLE QUE DES MOTS DE PASSE OUBLIÉS,
                    DES TRANSACTIONS INCORRECTEMENT CONSTRUITES OU DES ADRESSES
                    MUSTYPÉES ; (B) DÉFAILLANCE DU SERVEUR OU PERTE DE DONNÉES ;
                    (C) FICHIERS DE PORTEFEUILLE CORROMPUS ; (D) ACCÈS NON
                    AUTORISÉ AUX APPLICATIONS ; (E) TOUTE ACTIVITÉ DE TIERS NON
                    AUTORISÉE, Y COMPRIS, SANS S'Y LIMITER, L'UTILISATION DE
                    VIRUS, LE PHISHING, LE BRUTE FORCING OU D'AUTRES MOYENS
                    D'ATTAQUE CONTRE LE SITE OU SUPER WILD CLUB NFTS.
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    NO SEREMOS RESPONSABLES ANTE USTED POR NINGUNA PÉRDIDA Y NO
                    ASUMIREMOS NINGUNA RESPONSABILIDAD POR, Y NO SEREMOS
                    RESPONSABLES ANTE USTED POR, CUALQUIER USO DE SUPER WILD
                    CLUB NFTS, INCLUYENDO, ENTRE OTROS, CUALQUIER PÉRDIDA, DAÑO
                    O RECLAMO QUE SURJA DE: (A) ERROR DEL USUARIO, COMO
                    CONTRASEÑAS OLVIDADAS, TRANSACCIONES CONSTRUIDAS
                    INCORRECTAMENTE O DIRECCIONES MAL ESCRITAS; (B) FALLA DEL
                    SERVIDOR O PÉRDIDA DE DATOS; (C) ARCHIVOS DE BILLETERA
                    DAÑADOS; D) EL ACCESO NO AUTORIZADO A LAS APLICACIONES; (E)
                    CUALQUIER ACTIVIDAD NO AUTORIZADA DE TERCEROS, INCLUIDO,
                    ENTRE OTROS, EL USO DE VIRUS, PHISHING, FUERZA BRUTA U OTROS
                    MEDIOS DE ATAQUE CONTRA EL SITIO O SUPER WILD CLUB NFTS.
                  </p>
                )}
                {pathname === "en" && (
                  <p>
                    SUPER WILD CLUB NFTS ARE INTANGIBLE DIGITAL ASSETS. THEY
                    EXIST ONLY BY VIRTUE OF THE OWNERSHIP RECORD MAINTAINED IN
                    THE ETHEREUM NETWORK. ANY TRANSFER OF TITLE THAT MIGHT OCCUR
                    IN ANY UNIQUE DIGITAL ASSET OCCURS ON THE DECENTRALIZED
                    LEDGER WITHIN THE ETHEREUM PLATFORM. WE DO NOT GUARANTEE
                    THAT ISUNO LLC OR ANY ISUNO, LLC PARTY CAN AFFECT THE
                    TRANSFER OF TITLE OR RIGHT IN ANY SWC NFT.
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    Les NFTS SUPER WILD CLUB SONT DES ACTIFS NUMÉRIQUES
                    INCORPORELS. ILS EXISTENT UNIQUEMENT EN VERTU DE
                    L'ENREGISTREMENT DE PROPRIÉTÉ CONSERVÉ DANS LE RÉSEAU
                    ETHEREUM. TOUT TRANSFERT DE PROPRIÉTÉ QUI POURRAIT SE
                    PRODUIRE DANS UN ACTIF NUMÉRIQUE UNIQUE SE PRODUIT SUR LE
                    LIVRE DÉCENTRALISÉ AU SEIN DE LA PLATEFORME ETHEREUM. NOUS
                    NE GARANTISSONS PAS QU'ISUNO LLC OU TOUTE PARTIE D'ISUNO,
                    LLC PEUVENT AFFECTER LE TRANSFERT DE TITRE OU DE DROIT DANS
                    TOUT SWC NFT.
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    SI BIEN INTENTAMOS HACER QUE SU ACCESO Y USO DEL SITIO Y EL
                    CONTENIDO DEL SITIO SEAN SEGUROS, NO PODEMOS Y NO DECLARAMOS
                    NI GARANTIZAMOS QUE EL SITIO, EL CONTENIDO DEL SITIO,
                    CUALQUIER SWC NFTS ENUMERADO EN NUESTRO SITIO O NUESTROS
                    SERVIDORES ESTÉN LIBRES DE VIRUS U OTROS COMPONENTES
                    DAÑINOS. NO PODEMOS GARANTIZAR LA SEGURIDAD DE LOS DATOS QUE
                    USTED DIVULGUE EN LÍNEA. USTED ACEPTA LOS RIESGOS DE
                    SEGURIDAD INHERENTES DE PROPORCIONAR INFORMACIÓN Y TRATAR EN
                    LÍNEA A TRAVÉS DE INTERNET Y NO NOS HARÁ RESPONSABLES DE
                    NINGUNA VIOLACIÓN DE LA SEGURIDAD A MENOS QUE SE DEBA A
                    NUESTRA NEGLIGENCIA GRAVE.
                  </p>
                )}
                {pathname === "en" && (
                  <p>
                    We are not responsible for sustained casualties due to
                    vulnerability or any kind of failure, abnormal behavior of
                    software (e.g., wallet, smart contract), blockchains or any
                    other features of the SWC NFTs. ISUNO LLC is not responsible
                    for casualties due to late report by developers or
                    representatives (or no report at all) of any issues with the
                    blockchain supporting SWC NFTs including forks, technical
                    node issues or any other issues having fund losses as a
                    result.
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    Nous ne sommes pas responsables des pertes subies dues à la
                    vulnérabilité ou à tout type de défaillance, au comportement
                    anormal du logiciel (par exemple, portefeuille, contrat
                    intelligent), aux chaînes de blocs ou à toute autre
                    fonctionnalité des SWC NFT. ISUNO LLC n'est pas responsable
                    des pertes dues à un rapport tardif par des développeurs ou
                    des représentants (ou à aucun rapport du tout) de tout
                    problème avec la blockchain prenant en charge les SWC NFT, y
                    compris les fourches, les problèmes de nœuds techniques ou
                    tout autre problème entraînant des pertes de fonds.
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    No somos responsables de las bajas sufridas debido a la
                    vulnerabilidad o cualquier tipo de falla, comportamiento
                    anormal del software (por ejemplo, billetera, contrato
                    inteligente), cadenas de bloques o cualquier otra
                    característica de los NFT de SWC. ISUNO LLC no es
                    responsable de las víctimas debido al informe tardío de los
                    desarrolladores o representantes (o ningún informe en
                    absoluto) de cualquier problema con la cadena de bloques que
                    admite NFT de SWC, incluidas las bifurcaciones, problemas de
                    nodos técnicos o cualquier otro problema que tenga pérdidas
                    de fondos como resultado. Nada en estos Términos excluirá o
                    limitará la responsabilidad de cualquiera de las partes por
                    fraude, muerte o lesiones corporales causadas por
                    negligencia, violación de las leyes o cualquier otra
                    actividad que no pueda limitarse o excluirse por medios
                    legítimos.
                  </p>
                )}
                {pathname === "en" && (
                  <>
                    <p>
                      Nothing in these Terms shall exclude or limit liability of
                      either party for fraud, death or bodily injury caused by
                      negligence, violation of laws, or any other activity that
                      cannot be limited or excluded by legitimate means.
                    </p>
                    <p>
                      SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED
                      WARRANTIES IN CONTRACTS WITH CONSUMERS, SO THE ABOVE
                      EXCLUSION MAY NOT APPLY TO YOU.
                    </p>
                  </>
                )}
                {pathname === "fr" && (
                  <>
                    <p>
                      Rien dans les présentes Conditions n'exclut ou ne limite
                      la responsabilité de l'une ou l'autre des parties en cas
                      de fraude, de décès ou de blessure corporelle causée par
                      une négligence, une violation des lois ou toute autre
                      activité qui ne peut être limitée ou exclue par des moyens
                      légitimes.
                    </p>
                    <p>
                      CERTAINES JURIDICTIONS N'AUTORISENT PAS L'EXCLUSION DES
                      GARANTIES IMPLICITES DANS LES CONTRATS AVEC LES
                      CONSOMMATEURS, L'EXCLUSION CI-DESSUS PEUT NE PAS
                      S'APPLIQUER À VOUS.
                    </p>
                  </>
                )}
                {pathname === "es" && (
                  <p>
                    ALGUNAS JURISDICCIONES NO PERMITEN LA EXCLUSIÓN DE GARANTÍAS
                    IMPLÍCITAS EN LOS CONTRATOS CON LOS CONSUMIDORES, POR LO QUE
                    LA EXCLUSIÓN ANTERIOR PUEDE NO APLICARSE A USTED.
                  </p>
                )}
                <b>
                  {pathname === "en" && "Limitation of Liability"}
                  {pathname === "fr" && "Limitation de responsabilité"}
                  {pathname === "es" && "Limitación de responsabilidad"}
                </b>
                {pathname === "en" && (
                  <p>
                    TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL
                    ISUNO LLC BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST
                    PROFIT OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY,
                    INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING FROM THESE
                    TERMS, THE SITE, SITE CONTENT, THE SERVICES OR THIRD PARTY
                    SITES AND PRODUCTS, OR FOR ANY DAMAGES RELATED TO LOSS OF
                    REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED
                    SAVINGS, LOSS OF USE, LOSS OF GOODWILL, OR LOSS OF DATA,
                    WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF
                    CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE AND EVEN IF
                    ISUNO LLC HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
                    DAMAGES. ACCESS TO, AND USE OF, THE SITE, SITE CONTENT, THE
                    SERVICES OR THIRD-PARTY SITES AND PRODUCTS ARE AT YOUR OWN
                    DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR
                    ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE DEVICE OR LOSS
                    OF DATA RESULTING THEREFROM. NOTWITHSTANDING ANYTHING TO THE
                    CONTRARY CONTAINED HEREIN, IN NO EVENT SHALL THE MAXIMUM
                    AGGREGATE LIABILITY OF COMPANY ARISING OUT OF OR IN ANY WAY
                    RELATED TO THIS AGREEMENT, THE ACCESS TO AND USE OF THE
                    SITE, SITE CONTENT, SWC NFTS, OR ANY SERVICES PURCHASED ON
                    THE SITE EXCEED THE GREATER OF (A) $100 OR (B) THE AMOUNT
                    YOU HAVE PAID TO ISUNO LLC FOR THE SERVICES IN THE LAST
                    TWELVE MONTHS OUT OF WHICH LIABILITY AROSE.
                  </p>
                )}
                {pathname === "fr" && (
                  <>
                    <p>
                      DANS TOUTE LA MESURE AUTORISÉE PAR LA LOI, ISUNO LLC NE
                      SERA EN AUCUN CAS RESPONSABLE ENVERS VOUS OU TOUT TIERS
                      POUR TOUTE PERTE DE PROFIT OU TOUT DOMMAGE INDIRECT,
                      CONSÉCUTIF, EXEMPLAIRE, ACCESSOIRE, SPÉCIAL OU PUNITIF
                      DÉCOULANT DE CES CONDITIONS, DU SITE, DU CONTENU DU SITE.
                      , LES SERVICES OU LES SITES ET PRODUITS TIERS, OU POUR
                      TOUT DOMMAGE LIÉ À UNE PERTE DE REVENUS, UNE PERTE DE
                      PROFITS, UNE PERTE D'AFFAIRES OU D'ÉCONOMIES ATTENDUES,
                      UNE PERTE D'UTILISATION, UNE PERTE DE COMMERCE OU UNE
                      PERTE DE DONNÉES, QU'ELLES SOIENT CAUSÉES PAR UN DÉLIT (Y
                      COMPRIS NÉGLIGENCE), RUPTURE DE CONTRAT OU AUTRE, MÊME SI
                      PRÉVISIBLE ET MÊME SI ISUNO LLC A ÉTÉ AVISÉE DE LA
                      POSSIBILITÉ DE TELS DOMMAGES. L'ACCÈS ET L'UTILISATION DU
                      SITE, DU CONTENU DU SITE, DES SERVICES OU DES SITES ET
                      PRODUITS TIERS SONT À VOTRE PROPRE DISCRÉTION ET À VOS
                      PROPRES RISQUES, ET VOUS SEREZ SEUL RESPONSABLE DE TOUT
                      DOMMAGE À VOTRE SYSTÈME INFORMATIQUE OU APPAREIL MOBILE OU
                      DE PERTE DE DONNÉES EN RÉSULTANT.
                    </p>
                    <p>
                      NONOBSTANT TOUTE DISPOSITION CONTRAIRE CONTENUE DANS LES
                      PRÉSENTES, EN AUCUN CAS LA RESPONSABILITÉ GLOBALE MAXIMALE
                      DE LA SOCIÉTÉ DÉCOULANT OU LIÉE DE QUELQUE FAÇON QUE CE
                      SOIT À CET ACCORD, À L'ACCÈS ET À L'UTILISATION DU SITE,
                      DU CONTENU DU SITE, SWC NFTS OU DE TOUT SERVICE ACHETÉ SUR
                      LE SITE DÉPASSE LA PLUS GRANDE ENTRE (A) 100 $ OU (B) LE
                      MONTANT QUE VOUS AVEZ PAYÉ À ISUNO LLC POUR LES SERVICES
                      AU COURS DES DOUZE DERNIERS MOIS DONT LA RESPONSABILITÉ
                      EST DÉCOULÉE.
                    </p>
                  </>
                )}
                {pathname === "es" && (
                  <>
                    <p>
                      EN LA MÁXIMA MEDIDA PERMITIDA POR LA LEY, EN NINGÚN CASO
                      ISUNO LLC SERÁ RESPONSABLE ANTE USTED O CUALQUIER TERCERO
                      POR CUALQUIER PÉRDIDA DE GANANCIAS O CUALQUIER DAÑO
                      INDIRECTO, CONSECUENTE, EJEMPLAR, INCIDENTAL, ESPECIAL O
                      PUNITIVO QUE SURJA DE ESTOS TÉRMINOS, EL SITIO, EL
                      CONTENIDO DEL SITIO, LOS SERVICIOS O LOS SITIOS Y
                      PRODUCTOS DE TERCEROS, O POR CUALQUIER DAÑO RELACIONADO
                      CON LA PÉRDIDA DE INGRESOS, PÉRDIDA DE BENEFICIOS, PÉRDIDA
                      DE NEGOCIOS O AHORROS ANTICIPADOS, PÉRDIDA DE USO, PÉRDIDA
                      DE FONDO DE COMERCIO O PÉRDIDA DE DATOS, YA SEA CAUSADA
                      POR AGRAVIO (INCLUIDA LA NEGLIGENCIA), INCUMPLIMIENTO DE
                      CONTRATO O DE OTRO MODO, INCLUSO SI ES PREVISIBLE E
                      INCLUSO SI ISUNO LLC HA SIDO ADVERTIDA DE LA POSIBILIDAD
                      DE TALES DAÑOS. EL ACCESO Y EL USO DEL SITIO, EL CONTENIDO
                      DEL SITIO, LOS SERVICIOS O LOS SITIOS Y PRODUCTOS DE
                      TERCEROS SON A SU PROPIA DISCRECIÓN Y RIESGO, Y USTED SERÁ
                      EL ÚNICO RESPONSABLE DE CUALQUIER DAÑO A SU SISTEMA
                      INFORMÁTICO O DISPOSITIVO MÓVIL O PÉRDIDA DE DATOS QUE
                      RESULTE DE ELLO.
                    </p>
                    <p>
                      SIN PERJUICIO DE CUALQUIER DISPOSICIÓN EN CONTRARIO
                      CONTENIDA EN ESTE DOCUMENTO, EN NINGÚN CASO LA
                      RESPONSABILIDAD TOTAL MÁXIMA DE LA COMPAÑÍA QUE SURJA DE O
                      ESTÉ RELACIONADA DE ALGUNA MANERA CON ESTE ACUERDO, EL
                      ACCESO Y EL USO DEL SITIO, EL CONTENIDO DEL SITIO, SWC
                      NFTS O CUALQUIER SERVICIO COMPRADO EN EL SITIO EXCEDERÁ EL
                      MAYOR DE (A) $ 100 O (B) LA CANTIDAD QUE HA PAGADO A ISUNO
                      LLC POR LOS SERVICIOS EN LOS ÚLTIMOS DOCE MESES DE LOS
                      CUALES LA RESPONSABILIDAD SURGIÓ.
                    </p>
                  </>
                )}
                <b>
                  {pathname === "en" && "Indemnification"}
                  {pathname === "fr" && "Indemnité"}
                  {pathname === "es" && "Indemnización"}
                </b>
                {pathname === "en" && (
                  <p>
                    To the fullest extent permitted by applicable law, you agree
                    to indemnify, defend and hold harmless ISUNO LLC, and our
                    respective past, present and future employees, officers,
                    directors, contractors, consultants, equity holders,
                    suppliers, vendors, service providers, parent companies,
                    subsidiaries, affiliates, agents, representatives,
                    predecessors, successors and assigns (individually and
                    collectively, the “Isuno Parties”), from and against all
                    actual or alleged third party claims, damages, awards,
                    judgments, losses, liabilities, obligations, penalties,
                    interest, fees, expenses (including, without limitation,
                    attorneys’ fees and expenses) and costs (including, without
                    limitation, court costs, costs of settlement and costs of
                    pursuing indemnification and insurance), of every kind and
                    nature whatsoever, whether known or unknown, foreseen or
                    unforeseen, matured or unmatured, or suspected or
                    unsuspected, in law or equity, whether in tort, contract or
                    otherwise (collectively, “Claims”), including, but not
                    limited to, damages to property or personal injury, that are
                    caused by, arise out of or are related to (a) your use or
                    misuse of the Site, Site Content, or SWC NFTs, (c) your
                    violation of these Terms, (c) your violation of the rights
                    of a third party, including another user and (e) your
                    failure to pay any Taxes or Sales Taxes in connection with
                    your transactions on this Site or to provide us with a
                    properly executed tax form described in Section 8. You agree
                    to promptly notify us of any third party Claims and
                    cooperate with the SWC Parties in defending such Claims. You
                    further agree that the SWC Parties shall have control of the
                    defense or settlement of any third party Claims. THIS
                    INDEMNITY IS IN ADDITION TO, AND NOT IN LIEU OF, ANY OTHER
                    INDEMNITIES SET FORTH IN A WRITTEN AGREEMENT BETWEEN YOU AND
                    US.
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    Dans toute la mesure permise par la loi applicable, vous
                    acceptez d'indemniser, de défendre et de dégager de toute
                    responsabilité ISUNO LLC, ainsi que nos employés,
                    dirigeants, administrateurs, sous-traitants, consultants,
                    actionnaires, fournisseurs, vendeurs, prestataires de
                    services, parents passés, présents et futurs respectifs
                    sociétés, filiales, sociétés affiliées, agents,
                    représentants, prédécesseurs, successeurs et ayants droit
                    (individuellement et collectivement, les «Parties Isuno»),
                    contre toutes les réclamations, dommages, dommages-intérêts,
                    jugements, pertes, responsabilités, obligations, réels ou
                    présumés de tiers, pénalités, intérêts, honoraires, dépenses
                    (y compris, sans s'y limiter, les honoraires et frais
                    d'avocat) et coûts (y compris, sans s'y limiter, les frais
                    de justice, les frais de règlement et les frais de poursuite
                    d'indemnisation et d'assurance), de toute nature et de
                    quelque nature que ce soit, que ce soit connu ou inconnu,
                    prévu ou imprévu, échu ou non échu, ou suspecté ou
                    insoupçonné, en droit ou en équité, que ce soit en
                    responsabilité délictuelle, contractuelle ou autre
                    (collectivement, les « Réclamations »), inc. y compris, mais
                    sans s'y limiter, les dommages matériels ou corporels, qui
                    sont causés par, découlent de ou sont liés à (a) votre
                    utilisation ou mauvaise utilisation du site, du contenu du
                    site ou des NFT SWC, (c) votre violation de ces conditions,
                    (c) votre violation des droits d'un tiers, y compris un
                    autre utilisateur et (e) votre défaut de payer les taxes ou
                    les taxes de vente en rapport avec vos transactions sur ce
                    site ou de nous fournir un formulaire fiscal correctement
                    rempli décrit à la section 8. Vous acceptez de nous informer
                    rapidement de toute Réclamation de tiers et de coopérer avec
                    les Parties SWC pour défendre ces Réclamations. Vous
                    convenez en outre que les Parties SWC auront le contrôle de
                    la défense ou du règlement de toute Réclamation de tiers.
                    CETTE INDEMNITÉ S'AJOUTE ET NON REMPLACE TOUTE AUTRE
                    INDEMNITÉ STIPULÉE DANS UN ACCORD ÉCRIT ENTRE VOUS ET NOUS.
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    En la máxima medida permitida por la ley aplicable, usted
                    acepta indemnizar, defender y eximir de responsabilidad a
                    ISUNO LLC, y a nuestros respectivos empleados pasados,
                    presentes y futuros, funcionarios, directores, contratistas,
                    consultores, accionistas, proveedores, vendedores,
                    proveedores de servicios, compañías matrices, subsidiarias,
                    afiliadas, agentes, representantes, predecesores, sucesores
                    y cesionarios (individual y colectivamente, las "Partes de
                    Isuno"), de y contra todas las reclamaciones reales o
                    supuestas de terceros, daños, laudos, sentencias, pérdidas,
                    responsabilidades, obligaciones, multas, intereses,
                    honorarios, gastos (incluidos, entre otros, los honorarios y
                    gastos de abogados) y costos (incluidos, entre otros, los
                    costos judiciales, los costos de liquidación y los costos de
                    perseguir la indemnización y el seguro), de todo tipo y
                    naturaleza, ya sean conocidos o desconocidos, previstos o
                    imprevistos, vencidos o no maduros, o sospechosos o
                    insospechados, en derecho o en equidad, ya sea en agravio,
                    contrato o de otra manera (colectivamente, "Reclamaciones"),
                    incluidos, entre otros, daños a la propiedad o lesiones
                    personales, que sean causados por, surjan de o estén
                    relacionados con (a) su uso o mal uso del Sitio, el
                    Contenido del Sitio o los NFT de SWC, (c) su violación de
                    estos Términos, (c) su violación de los derechos de un
                    tercero, incluyendo a otro usuario y (e) su falta de pago de
                    impuestos o impuestos sobre las ventas en relación con sus
                    transacciones en este Sitio o para proporcionarnos un
                    formulario de impuestos correctamente ejecutado descrito en
                    la Sección 8. Usted acepta notificarnos de inmediato
                    cualquier Reclamación de terceros y cooperar con las Partes
                    de SWC en la defensa de dichas Reclamaciones. Además, acepta
                    que las Partes de SWC tendrán el control de la defensa o
                    liquidación de cualquier Reclamación de terceros. ESTA
                    INDEMNIZACIÓN ES ADICIONAL A, Y NO EN LUGAR DE, CUALQUIER
                    OTRA INDEMNIZACIÓN ESTABLECIDA EN UN ACUERDO ESCRITO ENTRE
                    USTED Y NOSOTROS.
                  </p>
                )}
                <b>
                  {pathname === "en" && "Governing Law"}
                  {pathname === "fr" && "Droit applicable"}
                  {pathname === "es" && "Legislación aplicable"}
                </b>
                {pathname === "en" && (
                  <p>
                    These Terms of Use, your rights and obligations, and all
                    actions contemplated by, arising out of or related to these
                    Terms shall be governed by the laws of the State of
                    Delaware, as if these Terms are a contract wholly entered
                    into and wholly performed within the State of Delaware. YOU
                    UNDERSTAND AND AGREE THAT YOUR USE OF THIS SITE AS
                    CONTEMPLATED BY THESE TERMS SHALL BE DEEMED TO HAVE OCCURRED
                    IN THE STATE OF NEW YORK AND BE SUBJECT TO THE INTERNAL LAWS
                    OF THE STATE OF Delaware WITHOUT REGARD TO ITS CONFLICTS OF
                    LAWS PROVISIONS.
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    Ces conditions d'utilisation, vos droits et obligations, et
                    toutes les actions envisagées par, découlant de ou liées à
                    ces conditions seront régies par les lois de l'État du
                    Delaware, comme si ces conditions étaient un contrat
                    entièrement conclu et entièrement exécuté dans l'État du
                    Delaware. VOUS COMPRENEZ ET ACCEPTEZ QUE VOTRE UTILISATION
                    DE CE SITE COMME ENVISAGÉE PAR CES CONDITIONS SERA RÉPUTÉ
                    AVOIR EU LIEU DANS L'ÉTAT DE NEW YORK ET ÊTRE SOUMIS AUX
                    LOIS INTERNES DE L'ÉTAT DU Delaware SANS TENIR COMPTE DE SES
                    DISPOSITIONS SUR LES CONFLITS DE LOIS.
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    Estos Términos de uso, sus derechos y obligaciones, y todas
                    las acciones contempladas por, que surjan de o estén
                    relacionadas con estos Términos se regirán por las leyes del
                    Estado de Delaware, como si estos Términos fueran un
                    contrato totalmente celebrado y realizado en su totalidad
                    dentro del Estado de Delaware. USTED ENTIENDE Y ACEPTA QUE
                    SU USO DE ESTE SITIO SEGÚN LO CONTEMPLADO EN ESTOS TÉRMINOS
                    SE CONSIDERARÁ QUE HA OCURRIDO EN EL ESTADO DE NUEVA YORK Y
                    ESTARÁ SUJETO A LAS LEYES INTERNAS DEL ESTADO DE DELAWARE
                    SIN TENER EN CUENTA SUS DISPOSICIONES DE CONFLICTOS DE
                    LEYES.
                  </p>
                )}
                <b>
                  {pathname === "en" && "Disputes and Arbitration Agreement"}
                  {pathname === "fr" && "Litiges et convention d'arbitrage"}
                  {pathname === "es" && "Disputas y Acuerdo de Arbitraje"}
                </b>
                {pathname === "en" && (
                  <p>
                    Carefully read the following arbitration agreement
                    (“Arbitration Agreement”). It requires you to arbitrate
                    disputes with ISUNO LLC and limits the manner in which you
                    can seek relief from us. Applicability of Arbitration
                    Agreement. You agree that any dispute or claim relating in
                    any way to your access or use of the Site, to any Services
                    sold or distributed through the Site, including SWC NFTs, or
                    to any aspect of your relationship with SWC, LLC will be
                    resolved by binding arbitration, rather than in court,
                    except that (1) you may assert claims in small claims court
                    if your claims qualify; and (2) you or SWC may seek
                    equitable relief in court for infringement or other misuse
                    of intellectual property rights (such as trademarks, trade
                    dress, domain names, trade secrets, copyrights, and
                    patents). All disputes arising out of or in connection with
                    these Terms, including without limitation your access or use
                    of the Site, or to any products sold or distributed through
                    the Site, will be referred to and finally resolved by
                    arbitration under the rules of the American Arbitration
                    Association. The case will be adjudicated by a single
                    arbitrator and will be administered by the American
                    Arbitration Association in accordance with its applicable
                    rules. Each party will cover its own fees and costs
                    associated with the arbitration proceedings. The place of
                    arbitration will be Florida. The award of the arbitrator
                    will be final and binding, and any judgment on the award
                    rendered by the arbitrator may be entered in any court of
                    competent jurisdiction. Notwithstanding the foregoing, we
                    may seek and obtain injunctive relief in any jurisdiction in
                    any court of competent jurisdiction.
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    Lisez attentivement la convention d'arbitrage suivante
                    ("Convention d'arbitrage"). Cela vous oblige à arbitrer les
                    différends avec ISUNO LLC et limite la manière dont vous
                    pouvez nous demander réparation.
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    Lea atentamente el siguiente acuerdo de arbitraje ("Acuerdo
                    de arbitraje"). Requiere que usted arbitre las disputas con
                    ISUNO LLC y limita la forma en que puede buscar alivio de
                    nosotros. Aplicabilidad del Acuerdo de Arbitraje. Usted
                    acepta que cualquier disputa o reclamo relacionado de alguna
                    manera con su acceso o uso del Sitio, con cualquier Servicio
                    vendido o distribuido a través del Sitio, incluidos los NFT
                    de SWC, o con cualquier aspecto de su relación con SWC, LLC
                    se resolverá mediante arbitraje vinculante, en lugar de en
                    un tribunal, excepto que (1) puede hacer valer reclamos en
                    un tribunal de reclamos menores si sus reclamos califican; y
                    (2) usted o SWC pueden buscar una reparación equitativa en
                    los tribunales por infracción u otro uso indebido de los
                    derechos de propiedad intelectual (como marcas comerciales,
                    imagen comercial, nombres de dominio, secretos comerciales,
                    derechos de autor y patentes).
                  </p>
                )}
                {pathname === "en" && (
                  <p>
                    WITH RESPECT TO ANY DISPUTE ARISING OUT OF OR RELATED TO
                    THESE TERMS, INCLUDING WITHOUT LIMITATION DISPUTES RELATED
                    TO THE SITE OR ANY PRODUCTS SOLD OR DISTRIBUTED THROUGH THE
                    SITE, OR THE SMART CONTRACTS: (I) YOU HEREBY EXPRESSLY GIVE
                    UP YOUR RIGHT TO HAVE A TRIAL BY JURY; AND (II) YOU HEREBY
                    EXPRESSLY GIVE UP YOUR RIGHT TO PARTICIPATE AS A MEMBER OF A
                    CLASS OF CLAIMANTS IN ANY LAWSUIT, INCLUDING BUT NOT LIMITED
                    TO CLASS ACTION LAWSUITS INVOLVING ANY SUCH DISPUTE.
                  </p>
                )}
                {pathname === "fr" && (
                  <>
                    <p>
                      Applicabilité de la convention d'arbitrage. Vous acceptez
                      que tout litige ou réclamation lié de quelque manière que
                      ce soit à votre accès ou à votre utilisation du site, à
                      tout service vendu ou distribué via le site, y compris les
                      NFT SWC, ou à tout aspect de votre relation avec SWC, LLC,
                      sera résolu en liant arbitrage, plutôt que devant un
                      tribunal, sauf que (1) vous pouvez faire valoir vos droits
                      devant le tribunal des petites créances si vos droits sont
                      admissibles ; et (2) vous ou SWC pouvez demander une
                      réparation équitable devant un tribunal pour violation ou
                      autre utilisation abusive des droits de propriété
                      intellectuelle (tels que les marques, les habillages
                      commerciaux, les noms de domaine, les secrets commerciaux,
                      les droits d'auteur et les brevets).
                    </p>
                    <p>
                      Tous les litiges découlant de ou en relation avec ces
                      Conditions, y compris, sans s'y limiter, votre accès ou
                      votre utilisation du Site, ou de tout produit vendu ou
                      distribué via le Site, seront renvoyés et finalement
                      résolus par arbitrage selon les règles de l'American
                      Arbitration Association. L'affaire sera jugée par un
                      arbitre unique et sera administrée par l'American
                      Arbitration Association conformément à ses règles
                      applicables. Chaque partie couvrira ses propres frais et
                      coûts associés à la procédure d'arbitrage. Le lieu de
                      l'arbitrage sera la Floride. La sentence de l'arbitre sera
                      définitive et exécutoire, et tout jugement sur la sentence
                      rendue par l'arbitre pourra être porté devant tout
                      tribunal compétent. Nonobstant ce qui précède, nous
                      pouvons demander et obtenir une injonction dans toute
                      juridiction auprès de tout tribunal compétent.
                    </p>
                    <p>
                      EN CE QUI CONCERNE TOUT LITIGE DÉCOULANT DE OU LIÉ À CES
                      CONDITIONS, Y COMPRIS, SANS S'Y LIMITER, LES LITIGES LIÉS
                      AU SITE OU À TOUT PRODUIT VENDU OU DISTRIBUÉ VIA LE SITE,
                      OU AUX CONTRATS INTELLIGENTS : (I) VOUS RENONCEZ
                      EXPRESSÉMENT PAR LA PRÉSENTE À VOTRE DROIT D'AVOIR UN
                      PROCÈS PAR JURY; ET (II) VOUS RENONCEZ EXPRESSÉMENT PAR LA
                      PRÉSENTE À VOTRE DROIT DE PARTICIPER EN TANT QUE MEMBRE
                      D'UNE CLASSE DE DEMANDEURS À TOUTE POURSUITE, Y COMPRIS,
                      MAIS SANS S'Y LIMITER, AUX RECOURS COLLECTIFS IMPLIQUANT
                      UN TEL DIFFÉREND.
                    </p>
                  </>
                )}
                {pathname === "es" && (
                  <>
                    <p>
                      Todas las disputas que surjan de o en relación con estos
                      Términos, incluyendo, sin limitación, su acceso o uso del
                      Sitio, o a cualquier producto vendido o distribuido a
                      través del Sitio, serán referidos y finalmente resueltos
                      por arbitraje bajo las reglas de la Asociación Americana
                      de Arbitraje. El caso será adjudicado por un solo árbitro
                      y será administrado por la Asociación Americana de
                      Arbitraje de acuerdo con sus reglas aplicables. Cada parte
                      cubrirá sus propios honorarios y costos asociados con el
                      procedimiento de arbitraje. El lugar del arbitraje será
                      Florida. El laudo del árbitro será definitivo y
                      vinculante, y cualquier sentencia sobre el laudo dictada
                      por el árbitro puede ser presentada en cualquier tribunal
                      de jurisdicción competente. No obstante lo anterior,
                      podemos buscar y obtener medidas cautelares en cualquier
                      jurisdicción en cualquier tribunal de jurisdicción
                      competente.
                    </p>
                    <p>
                      CON RESPECTO A CUALQUIER DISPUTA QUE SURJA DE O ESTÉ
                      RELACIONADA CON ESTOS TÉRMINOS, INCLUIDAS, ENTRE OTRAS,
                      LAS DISPUTAS RELACIONADAS CON EL SITIO O CUALQUIER
                      PRODUCTO VENDIDO O DISTRIBUIDO A TRAVÉS DEL SITIO, O LOS
                      CONTRATOS INTELIGENTES: (I) USTED RENUNCIA EXPRESAMENTE A
                      SU DERECHO A TENER UN JUICIO POR JURADO; Y (II) POR LA
                      PRESENTE RENUNCIA EXPRESAMENTE A SU DERECHO A PARTICIPAR
                      COMO MIEMBRO DE UNA CLASE DE DEMANDANTES EN CUALQUIER
                      DEMANDA, INCLUIDAS, ENTRE OTRAS, LAS DEMANDAS COLECTIVAS
                      QUE INVOLUCREN DICHA DISPUTA.
                    </p>
                  </>
                )}
                <b>
                  {pathname === "en" && "Severability"}
                  {pathname === "fr" && "Divisibilité"}
                  {pathname === "es" && "Divisibilidad"}
                </b>
                {pathname === "en" && (
                  <p>
                    Except as provided herein, if any part or parts of this
                    Arbitration Agreement are found under the law to be invalid
                    or unenforceable, then such specific part or parts shall be
                    of no force and effect and shall be severed and the
                    remainder of the Arbitration Agreement shall continue in
                    full force and effect.
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    Sauf disposition contraire des présentes, si une partie ou
                    des parties de la présente convention d'arbitrage sont
                    jugées invalides ou inapplicables en vertu de la loi, alors
                    cette ou ces parties spécifiques seront sans effet et seront
                    supprimées et le reste de la convention d'arbitrage sera
                    continuer en pleine force et effet.
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    Salvo lo dispuesto en este documento, si alguna parte o
                    partes de este Acuerdo de Arbitraje se consideran inválidas
                    o inaplicables, entonces dicha parte o partes específicas no
                    tendrán fuerza ni efecto y se separarán y el resto del
                    Acuerdo de Arbitraje continuará en pleno vigor y efecto.
                  </p>
                )}
                <b>
                  {pathname === "en" && "Survival of Agreement"}
                  {pathname === "fr" && "Survie de l'accord"}
                  {pathname === "es" && "Supervivencia del acuerdo"}
                </b>
                {pathname === "en" && (
                  <p>
                    This Arbitration Agreement will survive the termination of
                    your relationship with SWC.
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    Cette convention d'arbitrage survivra à la résiliation de
                    votre relation avec SWC.
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    Este Acuerdo de Arbitraje sobrevivirá a la terminación de su
                    relación con SWC.
                  </p>
                )}
                <b>
                  {pathname === "en" && "Modification"}
                  {pathname === "fr" && "Modification"}
                  {pathname === "es" && "Modificación"}
                </b>
                {pathname === "en" && (
                  <p>
                    Notwithstanding any provision in these Terms to the
                    contrary, we agree that if SWC makes any future material
                    change to this Arbitration Agreement, you may reject that
                    change within thirty (30) days of such change becoming
                    effective by writing to us at the following address: ISUNO
                    LLC, 3255 NE 184Th Street Suite 12212 Aventura, FL 33160,
                    Attention Legal Department.
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    Nonobstant toute disposition contraire des présentes
                    Conditions, nous convenons que si SWC apporte des
                    modifications importantes à la présente convention
                    d'arbitrage, vous pouvez rejeter cette modification dans les
                    trente (30) jours suivant l'entrée en vigueur de cette
                    modification en nous écrivant à l'adresse suivante : ISUNO
                    LLC, 3255 NE 184Th Street Suite 12212 Aventura, FL 33160,
                    Attention Legal Department.
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    A pesar de cualquier disposición en contrario en estos
                    Términos, aceptamos que si SWC realiza algún cambio material
                    futuro en este Acuerdo de arbitraje, usted puede rechazar
                    ese cambio dentro de los treinta (30) días posteriores a la
                    entrada en vigencia de dicho cambio escribiéndonos a la
                    siguiente dirección: ISUNO LLC, 3255 NE 184Th Street Suite
                    12212 Aventura, FL 33160, Atención Departamento Jurídico.
                  </p>
                )}
                <b>
                  {pathname === "en" && "Severability"}
                  {pathname === "fr" && "Divisibilité"}
                  {pathname === "es" && "Divisibilidad"}
                </b>
                {pathname === "en" && (
                  <p>
                    If any term, clause, or provision of these Terms is held
                    invalid or unenforceable, then that term, clause, or
                    provision shall be severable from these Terms and will not
                    affect the validity or enforceability of any remaining part
                    of that term, cause, or provision, or any other terms,
                    clause, or provision of these Terms.
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    Si un terme, une clause ou une disposition des présentes
                    Conditions est jugé invalide ou inapplicable, alors ce
                    terme, cette clause ou cette disposition sera dissociable
                    des présentes Conditions et n'affectera pas la validité ou
                    l'applicabilité de toute partie restante de ce terme, cause
                    ou disposition, ou toute autre condition, clause ou
                    disposition des présentes Conditions.
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    Si cualquier término, cláusula o disposición de estos
                    Términos se considera inválido o inaplicable, entonces ese
                    término, cláusula o disposición será separable de estos
                    Términos y no afectará la validez o aplicabilidad de ninguna
                    parte restante de ese término, causa o disposición, o
                    cualquier otro término, cláusula o disposición de estos
                    Términos.
                  </p>
                )}
                <b>
                  {pathname === "en" && "Entire Agreement"}
                  {pathname === "fr" && "Accord complet"}
                  {pathname === "es" && "Acuerdo completo"}
                </b>
                {pathname === "en" && (
                  <>
                    <p>
                      These Terms comprise the entire agreement between you and
                      us relating to your access to and use of the Site, Site
                      Content and any SWC NFTs you have purchased, and supersede
                      any and all prior discussions agreements, and
                      understandings of any kind. Except as otherwise provided
                      herein, these Terms are intended solely for the benefit of
                      the parties and are not intended to confer third party
                      beneficiary rights upon any other person or entity.
                    </p>
                    <p>
                      SWC may make changes to the Terms at its discretion.
                      Please check these Terms periodically for changes. Any
                      changes to the Terms will apply on the date that they are
                      made, and your continued access to or use after the Terms
                      have been updated will constitute your binding acceptance
                      of the updates. If you do not agree to any revised Terms,
                      you may not access or use the Site
                    </p>
                  </>
                )}
                {pathname === "fr" && (
                  <p>
                    Ces conditions comprennent l'intégralité de l'accord entre
                    vous et nous concernant votre accès et votre utilisation du
                    site, du contenu du site et de tous les NFT SWC que vous
                    avez achetés, et remplacent tous les accords de discussion
                    antérieurs et les accords de toute nature. Sauf disposition
                    contraire des présentes, les présentes Conditions sont
                    destinées uniquement au bénéfice des parties et ne sont pas
                    destinées à conférer des droits de tiers bénéficiaire à
                    toute autre personne ou entité. SWC peut apporter des
                    modifications aux Conditions à sa discrétion. Veuillez
                    vérifier ces conditions périodiquement pour les changements.
                    Toute modification des Conditions s'appliquera à la date à
                    laquelle elles sont apportées, et votre accès continu ou
                    votre utilisation après la mise à jour des Conditions
                    constituera votre acceptation contraignante des mises à
                    jour. Si vous n'acceptez pas les conditions révisées, vous
                    ne pouvez pas accéder ou utiliser le site
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    Estos Términos comprenden el acuerdo completo entre usted y
                    nosotros en relación con su acceso y uso del Sitio, el
                    Contenido del Sitio y cualquier NFT de SWC que haya
                    comprado, y reemplazan todos y cada uno de los acuerdos de
                    discusiones anteriores y entendimientos de cualquier tipo.
                    Salvo que se disponga lo contrario en este documento, estos
                    Términos están destinados únicamente para el beneficio de
                    las partes y no tienen la intención de conferir derechos de
                    terceros beneficiarios a ninguna otra persona o entidad. SWC
                    puede realizar cambios en los Términos a su discreción. Por
                    favor, revise estos Términos periódicamente para ver si hay
                    cambios. Cualquier cambio en los Términos se aplicará en la
                    fecha en que se realicen, y su acceso o uso continuo después
                    de que los Términos se hayan actualizado constituirá su
                    aceptación vinculante de las actualizaciones. Si no está de
                    acuerdo con los Términos revisados, no puede acceder ni
                    utilizar el Sitio
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>{" "}
        <Footer />
      </>
    );
  }
}

export default TermCondn;
