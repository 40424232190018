import React, { Component } from "react";

class VideoModal extends Component {
  stopVideo = () => {
    var iframe = document.getElementById("iframe");
    if (iframe) {
      var iframeSrc = iframe.src;
      iframe.src = iframeSrc;
    }
  };
  render() {
    return (
      <div
        class={
          this.props.vidModal
            ? "modal fade media-modal show d-block"
            : "modal fade media-modal d-none"
        }
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
      >
        <div class="modal-dialog modal-xl  modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content h-100">
            <div class="media-modal-content position-relative h-100">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.stopVideo();
                  this.props.toggleModal();
                }}
              ></button>
              <iframe
                id="iframe"
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/TgiFrUPsU-4"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VideoModal;
