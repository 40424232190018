import React, { Component, createRef } from "react";
import { gsap } from "gsap";

class CountDown extends Component {
  parallexRef1 = createRef(null);
  parallexRef2 = createRef(null);

  state = {
    days: "00",
    hours: "00",
    minutes: "00",
    sec: "00",
  };

  startTime = () => {
    const countDownDate = new Date("June 30 2022 00:00:00").getTime();
    this.interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const sec = Math.floor((distance % (1000 * 60)) / 1000);
      if (distance < 0) {
        //stop timer
        clearInterval(this.interval.current);
      } else {
        //update Timer
        this.setState({ days, hours, minutes, sec });
      }
    }, 1000);
  };

  componentDidMount = () => {
    const parallex = [this.parallexRef1, this.parallexRef2];
    parallex.forEach((cb) =>
      gsap.to(cb, {
        y: -100,
        ease: "none",
        scrollTrigger: {
          trigger: cb,
          start: "top bottom",
          scrub: true,
          //markers:true,
        },
      })
    );
    this.startTime();
  };

  componentWillUnmount = () => {
    clearInterval(this.interval.current);
  };

  render() {
    return (
      <>
        <div class="count-block-row">
          <div
            class="cbr-img-left is-parallax"
            ref={(el) => (this.parallexRef1 = el)}
          >
            <img src="include/images/Cobalt_Feather_3-2.png" alt="" />
          </div>
          <h5 class="countdown-text">
            whitelist mint{" "}
            <span style={{ marginTop: "5px" }}>(public mint : june 30th)</span>
          </h5>
          <ul class="count-cards-list d-flex justify-content-center">
            <li>
              <div class="count-cards-inner text-center">
                <div class="count-cards-box">
                  <h6>{this.state.days}</h6>
                </div>
                <h4>days</h4>
              </div>
            </li>
            <li>
              <div class="count-cards-inner text-center">
                <div class="count-cards-box">
                  <h6>{this.state.hours}</h6>
                </div>
                <h4>hours</h4>
              </div>
            </li>
            <li>
              <div class="count-cards-inner text-center">
                <div class="count-cards-box">
                  <h6>{this.state.minutes}</h6>
                </div>
                <h4>minutes</h4>
              </div>
            </li>
            <li>
              <div class="count-cards-inner text-center">
                <div class="count-cards-box">
                  <h6>{this.state.sec}</h6>
                </div>
                <h4>seconds</h4>
              </div>
            </li>
          </ul>
          <div class="row-btn text-center">
            <a
              href="https://discord.com/invite/superwildclub"
              target="_blank"
              class="btn btn-default btn-md white"
              id="btn-min-width"
            >
              <i class="fab fa-discord"></i> stay updated
            </a>
          </div>
          <div
            class="cbr-img-right is-parallax"
            ref={(el) => (this.parallexRef2 = el)}
          >
            <img src="include/images/Buffon's-Ara_Feather_3-2.png" alt="" />
          </div>
        </div>
        <h2 class="disclaimer-text">
          Disclaimer: All investments involve risk and may result in a profit as
          well as a total loss.
        </h2>
      </>
    );
  }
}
export default CountDown;
