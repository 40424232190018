import React, { Component } from "react";
import Slider from "react-slick/lib/slider";

class HowItWorks extends Component {
  render() {
    const pathname =
      typeof window !== undefined
        ? localStorage.getItem("lang")
          ? localStorage.getItem("lang")
          : "en"
        : "en";
    const settings = {
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      arrows: false,
      infinite: true,
      centerMode: false,
      responsive: [
        {
          breakpoint: 5000,
          settings: "unslick",
        },
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            adaptiveHeight: true,
          },
        },
      ],
    };
    return (
      <div
        class="content-container step-container no-shadow overflow-hidden"
        id="howItWorks"
      >
        <div class="container">
          <div class="row">
            <div class="col-md-10 offset-md-1">
              <div class="heading-text text-center medium">
                <h4>
                  {pathname === "en" && <span>how it works</span>}
                  {pathname === "fr" && <span>comment ?</span>}
                  {pathname === "es" && <span>COMO FUNCIONA</span>}
                </h4>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 offset-md-0 col-lg-10 offset-lg-1">
              <Slider
                {...settings}
                className="post-card-list post-slider add-custom-dots d-flex flex-wrap justify-content-center"
              >
                <li class="post-card-item">
                  <div class="post-card-box w-100 h-100 add-border-radius bg-white text-center">
                    <div class="post-card-icon">1</div>
                    {pathname === "en" && (
                      <>
                        <b class="text-uppercase">
                          release of the first <span>collection</span>
                        </b>
                        <p>
                          Macaws will be the first ! Others species will come in
                          the future, and you will have to decide which one you
                          want to see.
                        </p>
                        <p>
                          Holding a Super Animal will allow you to get royalties
                          from the benefits of the serie.
                        </p>
                      </>
                    )}
                    {pathname === "fr" && (
                      <>
                        <b class="text-uppercase">
                          sortie de la première collection
                        </b>
                        <p>
                          Les Macaws sont les premiers ! D’autres espèces
                          viendront dans le futur, et tu auras à choisir
                          laquelle tu préfères.
                        </p>
                        <p>
                          Holder un Super Animal vous permettra de récolter des
                          royalties sur les bénéfices de la série.
                        </p>
                      </>
                    )}
                    {pathname === "es" && (
                      <>
                        <b class="text-uppercase">
                          LANZAMIENTO DE LA PRIMERA COLECCIÓN
                        </b>
                        <p>
                          ¡Los guacamayos serán los primeros! Otras especies
                          vendrán en el futuro, y tendrás que decidir cuál
                          quieres ver.
                        </p>
                        <p>
                          Tener un Superwildclub NFT te permitirá obtener
                          regalías de los beneficios de la serie.
                        </p>
                      </>
                    )}
                  </div>
                </li>
                <li class="post-card-item">
                  <div class="post-card-box w-100 h-100 add-border-radius bg-white text-center">
                    <div class="post-card-icon">2</div>
                    {pathname === "en" && (
                      <>
                        <b class="text-uppercase">
                          you are a super member <span>of the club</span>
                        </b>
                        <p>
                          Good job ! You are now part of the SWC. Your opinion
                          and feedbacks will be the core of the project and its
                          evolution in the future.
                        </p>
                        <p>
                          We are also designing merchandising and derivative
                          products,...
                        </p>
                      </>
                    )}
                    {pathname === "fr" && (
                      <>
                        <b class="text-uppercase">tu es membre du club !</b>
                        <p>
                          Bien joué, tu es maintenant membre du SWC. Ton avis et
                          tes commentaires seront le socle du projet et de son
                          évolution.
                        </p>
                        <p>
                          Nous designerons aussi du merchandising, des produits
                          dérivés, ...
                        </p>
                      </>
                    )}
                    {pathname === "es" && (
                      <>
                        <b class="text-uppercase">
                          ERES UN MIEMBRO DEL SUPERWILDCLUB
                        </b>
                        <p>
                          ¡Buen trabajo! Ahora eres parte del SWC. Tu opinión y
                          feedback serán el núcleo del proyecto y su evolución
                          en el futuro. También estamos diseñando merchandising
                          y productos derivados como utilidades adicionales a
                          tus NFT.
                        </p>
                      </>
                    )}
                  </div>
                </li>
                <li class="post-card-item">
                  <div class="post-card-box w-100 h-100 add-border-radius bg-white text-center">
                    <div class="post-card-icon">3</div>
                    {pathname === "en" && (
                      <>
                        {" "}
                        <b class="text-uppercase">
                          decide what you want <span>to tell and how</span>
                        </b>
                        <p>
                          We want you to be part of the creation process to help
                          us build scenarios, produce episodes, choosing animals
                          to include in the collections, decide about clothing,
                          styles, settings, and more…
                        </p>
                      </>
                    )}
                    {pathname === "fr" && (
                      <>
                        {" "}
                        <b class="text-uppercase">
                          décide ce que tu souhaites dire et comment
                        </b>
                        <p>
                          Nous voulons que tu fasses partie intégrante du
                          process de création. Nous donner ton avis sur les
                          scénarios, choisir les espèces, les costumes, le
                          style, etc...
                        </p>
                      </>
                    )}
                    {pathname === "es" && (
                      <>
                        {" "}
                        <b class="text-uppercase">
                          DECIDE QUÉ QUIERES DECIR Y CÓMO
                        </b>
                        <p>
                          Queremos que seas parte del proceso de creación para
                          ayudarnos a construir escenarios, producir episodios
                          animados, elegir animales para incluir en las
                          colecciones, decidir sobre ropa, estilos, escenarios y
                          más…
                        </p>
                      </>
                    )}
                  </div>
                </li>
                <li class="post-card-item">
                  <div class="post-card-box w-100 h-100 add-border-radius bg-white text-center">
                    <div class="post-card-icon">4</div>
                    {pathname === "en" && (
                      <>
                        <b class="text-uppercase">
                          Building the <span>animation STUDIO</span>
                        </b>
                        <p>
                          The first collaborative animation studio in the world
                          is coming to life : hiring and building a top talents
                          team and bringing to life our Super Animals on big
                          screens, streaming platforms,...
                        </p>
                      </>
                    )}
                    {pathname === "fr" && (
                      <>
                        <b class="text-uppercase">
                          création du studio d’animation collaboratif
                        </b>
                        <p>
                          Le premier studio collaboratif au monde voit le jour :
                          nous recruterons une équipe de talents et donneront
                          vie à nos Super Animaux sur grand écran, sur les
                          plateformes de streaming, ...
                        </p>
                      </>
                    )}
                    {pathname === "es" && (
                      <>
                        <b class="text-uppercase">
                          A CONSTRUIR EL ESTUDIO DE ANIMACIÓN
                        </b>
                        <p>
                          El primer estudio de animación colaborativo del mundo
                          está cobrando vida: contratar y formar un equipo con
                          los mejores talentos y daremos vida a nuestros
                          Superwild Animales en pantallas gigantes, plataformas
                          de streaming, etc....
                        </p>
                      </>
                    )}
                  </div>
                </li>
                <li class="post-card-item">
                  <div class="post-card-box w-100 h-100 add-border-radius bg-white text-center">
                    <div class="post-card-icon">5</div>
                    {pathname === "en" && (
                      <>
                        <b class="text-uppercase">
                          we expand <span>to the metaverse </span>
                        </b>
                        <p>
                          SuperWild Club is now a well known brand. We are
                          excited to imagine creating the SuperWild Island in
                          the metaverse.
                        </p>
                      </>
                    )}
                    {pathname === "fr" && (
                      <>
                        <b class="text-uppercase">jusqu’à la metaverse</b>
                        <p>
                          SuperWild Club est maintenant connu de tous. Nous
                          sommes impatients d’imaginer pouvoir créer l’île de
                          SuperWild dans le metaverse.
                        </p>
                      </>
                    )}
                    {pathname === "es" && (
                      <>
                        <b class="text-uppercase">ENCAMINADOS AL METAVERSO</b>
                        <p>
                          SuperWild Club es ahora una marca reconocida. Estamos
                          emocionados de iniciar la creación de SuperWild Island
                          en el metaverso.
                        </p>
                      </>
                    )}
                  </div>
                </li>
              </Slider>
              {/* <div class="bott-button text-center">
                <div class="bott-button text-center">
                  <a
                    href="https://youtube.com"
                    target="_blank"
                    class="btn btn-default btn-lg"
                  >
                    {pathname==="en" && "watch the keynote"}
                    {pathname==="fr" && "regarder la keynote"}
                    {pathname==="es" && "VER EL KEYNOTE"}
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HowItWorks;
