import React, { Component, createRef } from "react";
import { gsap } from "gsap";

class Collaborative extends Component {
  // parallexRef = createRef(null);
  // componentDidMount = () => {
  //   const parallex = [this.parallexRef];
  //   parallex.forEach((cb) =>
  //     gsap.to(cb, {
  //       y: -100,
  //       ease: "none",
  //       scrollTrigger: {
  //         trigger: cb,
  //         start: "top bottom",
  //         scrub: true,
  //         //markers:true,
  //       },
  //     })
  //   );
  // };
  render() {
    const pathname =
      typeof window !== undefined
        ? localStorage.getItem("lang")
          ? localStorage.getItem("lang")
          : "en"
        : "en";

    return (
      <div
        class="content-container single-para-container collaborativeStudio-container add-shadow pb-0"
        id="collaborativeStudio"
      >
        <div class="container">
          <div class="row">
            <div class="col-md-12 position-relative">
              <div
                class="spc-bg"
                // ref={(el) => (this.parallexRef = el)}
              >
                <img src="include/images/shape-bg-1.png" alt="" />
              </div>
              <div class="single-para-text">
                <div class="single-para-image">
                  <img
                    src="include/images/Blue_Throated_Feather_1-1.png"
                    alt=""
                  />
                </div>
                {pathname === "en" && <h5>first milestone</h5>}
                {pathname === "fr" && <h5>premiere etape</h5>}
                {pathname === "es" && <h5>PRIMER HITO</h5>}
                {pathname === "en" && (
                  <h2>
                    <span>
                      <strong>the collaborative</strong>
                    </span>
                    <span>
                      <strong>animatION studio</strong>
                    </span>
                  </h2>
                )}
                {pathname === "fr" && (
                  <h2>
                    <span>
                      <strong>le studio</strong>
                    </span>
                    <span>
                      <strong>collaboratif</strong>
                    </span>
                  </h2>
                )}
                {pathname === "es" && (
                  <h2>
                    <span>
                      <strong>El estudio de animaciON</strong>
                    </span>
                    <span>
                      <strong>colaborativo</strong>
                    </span>
                  </h2>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Collaborative;
