import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import TermCondn from "./common/termCondn";
import Home from "./Component/home/home"
import PrivacyPolicy from "./common/privacyPolicy";
import Mint from "./Component/mint/mint";


function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/home-1" component={Home} />
        <Route exact path="/home-2" component={Home} />
        <Route exact path="/terms-and-condition" component={TermCondn} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/mint" component={Mint} />
        <Route exact path="/mint-1" component={Mint} />
      </Switch>
    </Router>
  );
}

export default App;
