import React, { Component, createRef } from "react";
import gsap from "gsap";

class Rewards extends Component {
  parallexRef1 = createRef(null);
  parallexRef2 = createRef(null);

  componentDidMount = () => {
    gsap.to(this.parallexRef1, {
      y: -100,
      ease: "none",
      scrollTrigger: {
        trigger: this.parallexRef1,
        start: "top bottom",
        scrub: true,
        //markers:true,
      },
    });
    gsap.to(this.parallexRef2, {
      y: -100,
      ease: "none",
      scrollTrigger: {
        trigger: this.parallexRef2,
        start: "top bottom",
        scrub: true,
        //markers:true,
      },
    });
  };
  render() {
    const pathname =
      typeof window !== undefined
        ? localStorage.getItem("lang")
          ? localStorage.getItem("lang")
          : "en"
        : "en";
    return (
      <div class="content-container benefits-container" id="rewards">
        <div class="container">
          <div class="row">
            <div class="col-md-12 offset-md-0 col-lg-10 offset-lg-1">
              <div class="heading-text">
                <h4 class="d-none d-lg-block">
                  {pathname === "en" && (
                    <span>
                      What are benefits <br /> & rewards ?
                    </span>
                  )}
                  {pathname === "fr" && (
                    <span>
                      quelles sont les
                      <br /> recompenses ?
                    </span>
                  )}
                  {pathname === "es" && (
                    <span>
                      los beneficios y <br /> recompensas
                    </span>
                  )}
                </h4>
                <h4 class="d-block d-lg-none">
                  {pathname === "en" && (
                    <span>
                      What are benefits <br /> & rewards ?
                    </span>
                  )}
                  {pathname === "fr" && (
                    <span>
                      quelles sont les
                      <br /> recompenses ?
                    </span>
                  )}
                  {pathname === "es" && (
                    <span>
                      los beneficios y <br /> recompensas
                    </span>
                  )}
                </h4>
              </div>
              <ul class="content-block-list">
                <li class="cbl-item">
                  <div class="cbl-box d-md-flex flex-md-wrap">
                    <div
                      class="cbl-img-box order-1 is-parallax"
                      ref={(el) => (this.parallexRef1 = el)}
                    >
                      <div class="cbl-img">
                        <img src="include/images/spix-feather.png" alt="" />
                      </div>
                    </div>
                    {pathname === "en" && (
                      <div class="cbl-text-box order-0">
                        <p>
                          <b>if we win, you win</b>
                        </p>
                        <p>
                          Being part of the first Super Members will allow you
                          to unlock access to exclusive rewards and benefits.
                        </p>
                        <p>
                          First and foremost, the royalties. You’ll be rewarded
                          on all the earnings from the episodes produced by the
                          SuperWild Club. Every dollars we earn will be shared.
                          More details about the process are coming in the
                          whitepaper.
                        </p>
                        <p>Because we all win together !</p>
                      </div>
                    )}
                    {pathname === "fr" && (
                      <div class="cbl-text-box order-0">
                        <p>
                          <b>si on gagne, tu gagnes</b>
                        </p>
                        <p>
                          Faire partie des premiers Super Membres te permettra
                          de débloquer l'accès à des récompenses et avantages
                          exclusifs.
                        </p>
                        <p>
                          D'abord et avant tout, les royalties. Tu seras
                          récompensé sur tous les gains des épisodes produits
                          par le SuperWild Club. Chaque dollars gagné sera
                          partagé. Plus de détails sur le processus sont à venir
                          dans le whitepaper.
                        </p>
                        <p>Parce que nous gagnons tous ensemble !</p>
                      </div>
                    )}
                    {pathname === "es" && (
                      <div class="cbl-text-box order-0">
                        <p>
                          <b>SI NOSOTROS GANAMOS, USTED GANA</b>
                        </p>
                        <p>
                          Ser parte de los primeros Superwildclub Miembros te
                          permitirá desbloquear el acceso a recompensas y
                          beneficios exclusivos.
                        </p>
                        <p>
                          En primer lugar, las regalías. Serás recompensado con
                          una parte de las ganancias producidas globalmente por
                          SuperWildClub. Parte de cada dólar que ganemos será
                          compartido. Más detalles sobre el proceso vienen en el
                          documento técnico.
                        </p>
                        <p>¡Porque todos juntos ganamos!</p>
                      </div>
                    )}
                  </div>
                </li>
                <li class="cbl-item">
                  <div class="cbl-box d-md-flex flex-md-wrap">
                    <div
                      class="cbl-img-box order-1 is-parallax"
                      ref={(el) => (this.parallexRef2 = el)}
                    >
                      <div class="cbl-img">
                        <img
                          src="include/images/buffon-ara-feather.png"
                          alt=""
                        />
                      </div>
                    </div>
                    {pathname === "en" && (
                      <div class="cbl-text-box order-0">
                        <p>
                          More than the royalties, you will also be able to get
                          some rewards from us as the project goes better and
                          better.
                        </p>
                        <p>
                          We will share with you some ideas we’ve got on the
                          Discord, so you’ll be able to tell us which one do you
                          like the most !
                        </p>
                      </div>
                    )}
                    {pathname === "fr" && (
                      <div class="cbl-text-box order-0">
                        <p>
                          En plus des royalties, tu obtiendras également des
                          récompenses de notre part, au fur et à mesure que le
                          projet évoluera.
                        </p>
                        <p>
                          Nous partagerons avec toi les idées que nous avons sur
                          le Discord, et tu pourras voter pour nous dire
                          laquelle te plaît le plus !
                        </p>
                      </div>
                    )}
                    {pathname === "es" && (
                      <div class="cbl-text-box order-0">
                        <p>
                          Además de las regalías, también podrá obtener
                          recompensas de nosotros a medida que el proyecto vaya
                          mejor y mejor.
                        </p>
                        <p>
                          ¡Compartiremos contigo algunas ideas que tenemos en
                          Discord, para que puedas decirnos cuál te gusta más!
                        </p>
                      </div>
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Rewards;
