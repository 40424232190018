import React, { Component } from "react";
import { Link } from "react-router-dom";

class Footer extends Component {
  render() {
    const pathname =
      typeof window !== undefined
        ? localStorage.getItem("lang")
          ? localStorage.getItem("lang")
          : "en"
        : "en";
    return (
      <footer id="footer">
        <div class="footer-upper">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="footer-upper-content d-flex align-items-center justify-content-between position-relative">
                  <div class="footer-btn">
                    <a
                      href="https://discord.com/invite/superwildclub"
                      target="_blank"
                      class="btn btn-default btn-md"
                    >
                      <i class="fab fa-discord"></i>
                      {pathname === "en" && "join the club"}
                      {pathname === "fr" && "rejoins le club"}
                      {pathname === "es" && "ÚNETE AL CLUB"}
                    </a>
                  </div>
                  <ul class="footer-nav d-flex align-items-center">
                    {/* <li>
                      {pathname === "en" && (
                        <Link to="/privacy-policy">privacy policy</Link>
                      )}
                      {pathname === "fr" && (
                        <Link to="/privacy-policy">
                          politique de confidentialité
                        </Link>
                      )}
                      {pathname === "es" && (
                        <Link to="/privacy-policy">
                          política de confidencialidad
                        </Link>
                      )}
                    </li> */}
                    <li>
                      {pathname === "en" && (
                        <Link to="/terms-and-condition">terms of use</Link>
                      )}
                      {pathname === "fr" && (
                        <Link to="/terms-and-condition">mentions légales</Link>
                      )}
                      {pathname === "es" && (
                        <Link to="/terms-and-condition">notas legales</Link>
                      )}
                    </li>
                  </ul>
                  <ul class="social-links d-flex align-items-center">
                    <li>
                      <a
                        href="https://www.instagram.com/superwildclub/"
                        target="_blank"
                        rel="noopener"
                      >
                        <i class="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/superwildclub"
                        target="_blank"
                        rel="noopener"
                      >
                        <i class="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://discord.com/invite/superwildclub"
                        target="_blank"
                        rel="noopener"
                      >
                        <i class="fab fa-discord"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-lower">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12 position-relative">
                <ul class="footer-nav d-flex align-items-center">
                  <li>
                    <a
                      href="https://raritysniper.com/nft-drops-calendar"
                      target="_blank"
                    >
                      drops calendar
                    </a>
                  </li>
                </ul>
                <p class="copyright">
                  superwild club &copy; 2022 all right reserved
                </p>
                <div class="design-by d-flex align-items-center justify-content-end">
                  <span class="d-flex">
                    Made with <i class="fas fa-heart"></i> by
                  </span>
                  <a
                    href="https://blackballoon.agency/en/"
                    target="_blank"
                    rel="noopener"
                  >
                    <img src="include/images/blackbaloon.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
