import React from 'react'

const Counter = ({ counter, setCounter, limit=255, start=1 }) => {

  const increase = () => {
    if (counter < limit) {
      setCounter((count) => count + 1)
    }
  }
  const decrease = () => {
    if (counter > start) {
      setCounter((count) => count - 1)
    }
  }

  return (
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
      <button className="btn btn-default counter" onClick={decrease}>-</button>
      <h5 style={{ margin: '0 15px' }}>{counter}</h5>
      <button className="btn btn-default counter" style={{fontSize: 30}} onClick={increase}>+</button>
    </div>
  )
}

export default Counter