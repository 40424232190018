import React, { Component, createRef } from "react";
import { gsap } from "gsap";
import CountDown from "./countdown";
import HeroSubSection from "./heroSubSection";

class Hero extends Component {
  imageRef = createRef(null);
  textRef1 = createRef(null);
  paraRef = createRef(null);
  paraRef1 = createRef(null);
  btnRef = createRef(null);
  interval = createRef();

  componentDidMount = () => {
    const textRef = [this.textRef1];
    gsap
      .timeline()
      .to(this.imageRef, {
        opacity: 1,
        scale: 1,
        duration: 0.6,
        delay: 1,
        ease: "power2.inOut",
      })
      .to(
        this.textRef1,
        { y: "0%", duration: 0.4, stagger: 0.2, delay: 0 },
        "-=0.3"
      )
      .to(
        this.textRef2,
        { y: "0%", duration: 0.4, stagger: 0.2, delay: 0 },
        "-=0.3"
      )
      .to(this.paraRef, { opacity: 1, duration: 0.5, delay: 0 }, "-=0.2")
      .to(this.paraRef1, { opacity: 1, duration: 0.5, delay: 0 }, "-=0.2")
      .to(this.btnRef, { opacity: 1, duration: 0.5, delay: 0 }, "-=0.2");
  };

  render() {
    const pathname =
      typeof window !== undefined
        ? localStorage.getItem("lang")
          ? localStorage.getItem("lang")
          : "en"
        : "en";
    const route = window.location.pathname;
    return (
      <div class="hero-container position-relative text-center">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="hero-content">
                <div
                  class={
                    route === "/home-1" || route === "/home-2"
                      ? "hero-imge-area logo-size"
                      : "hero-imge-area"
                  }
                  ref={(el) => (this.imageRef = el)}
                  style={{
                    marginBottom:
                      route === "/home-1" || route === "/home-2" ? "0" : "30px",
                  }}
                >
                  <img src="include/images/logo_v2.png" alt="" />
                </div>
                {route === "/home-1" && <CountDown />}
                {route === "/home-2" && (
                  <HeroSubSection history={this.props.history} />
                )}
                <h1 class="hero-text">
                  <span>
                    <strong class="create" ref={(el) => (this.textRef1 = el)}>
                      {pathname === "en" && "join the superwild club"}
                      {pathname === "fr" && "rejoins superwild club"}
                      {pathname === "es" && "UNETE AL SuperWild Club"}
                    </strong>
                  </span>
                </h1>
                {pathname === "en" && (
                  <>
                    {" "}
                    <p
                      ref={(el) => (this.paraRef = el)}
                      style={{ margin: "0 auto 24px" }}
                    >
                      Join SuperWildClub, a collection of unique animals that
                      live in a tropical, urban, and hospitable environment.
                      Your characters will embark on numerous adventures in
                      order to safeguard their island and its nature. Let's
                      write their stories together, page by page, to demonstrate
                      our identity.
                    </p>
                    <p
                      ref={(el) => (this.paraRef1 = el)}
                      style={{ margin: "0 auto 24px" }}
                    >
                      Exclusive utility based collections of NFT of unique
                      animals chosen with the community for the community with a
                      special and additional support for foundations and groups
                      whom enjoy helping animals worldwide.
                    </p>
                  </>
                )}
                {pathname === "fr" && (
                  <>
                    {" "}
                    <p
                      ref={(el) => (this.paraRef = el)}
                      style={{ margin: "0 auto 24px" }}
                    >
                      Rejoins SuperWildClub, une collection d’animaux uniques
                      vivants dans un univers tropical, urbain, et accueillant.
                      Tes personnages vont vivre différentes aventures dans le
                      but de protéger leur île et son environnement. Écrivons
                      ensemble leurs histoires, page par page.
                    </p>
                    <p
                      ref={(el) => (this.paraRef1 = el)}
                      style={{ margin: "0 auto 24px" }}
                    >
                      Des collections NFT d’animaux exclusives basées sur
                      l'utilité, choisies avec la communauté pour la communauté.
                      Sans oublier un soutien supplémentaire pour les fondations
                      et les associations qui aiment aider les animaux dans le
                      monde entier.
                    </p>
                  </>
                )}
                {pathname === "es" && (
                  <>
                    {" "}
                    <p
                      ref={(el) => (this.paraRef = el)}
                      style={{ margin: "0 auto 24px" }}
                    >
                      Únete a SuperWildClub, una colección de animales únicos
                      que viven en un ambiente tropical, urbano y hospitalario.
                      Tus personajes se embarcarán en numerosas aventuras para
                      salvaguardar su isla y su naturaleza. Escribamos juntos
                      sus historias, página por página, para demostrar nuestra
                      identidad.
                    </p>
                    <p
                      ref={(el) => (this.paraRef1 = el)}
                      style={{ margin: "0 auto 24px" }}
                    >
                      Colecciones exclusivas de NFT de animales únicos elegidos
                      con la comunidad para la comunidad con un apoyo especial y
                      adicional para fundaciones y grupos que disfrutan ayudando
                      a los animales en todo el mundo.
                    </p>
                  </>
                )}
                <div class="hero-btn" ref={(el) => (this.btnRef = el)}>
                  <a
                    href="https://discord.com/invite/superwildclub"
                    target="_blank"
                    class="btn btn-default btn-md"
                  >
                    <i class="fab fa-discord"></i>{" "}
                    {pathname === "en" && "join the club"}
                    {pathname === "fr" && "rejoins le club"}
                    {pathname === "es" && "ÚNETE AL CLUB"}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Hero;
