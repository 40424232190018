import React, { Component } from "react";

class Faq extends Component {
  state = {
    accordian: false,
    step: "",
  };
  toggleAccordian = (i) => {
    this.setState({ accordian: !this.state.accordian, step: i });
  };
  render() {
    const pathname =
      typeof window !== undefined
        ? localStorage.getItem("lang")
          ? localStorage.getItem("lang")
          : "en"
        : "en";
    return (
      <div class="content-container faq-container" id="faq">
        <div class="container">
          <div class="row">
            <div class="col-md-12 offset-md-0 col-lg-10 offset-lg-1">
              <div class="heading-text">
                <h4>
                  <span>
                    {pathname === "en" || pathname === "fr"
                      ? "f.A.Q"
                      : "PREGUNTAS MAS FRECUENTES"}
                  </span>
                </h4>
              </div>
              <div class="accordion" id="accordionPnnel">
                <div
                  class="accordion-item"
                  onClick={() => this.toggleAccordian("A")}
                >
                  <div
                    class={
                      this.state.accordian && this.state.step === "A"
                        ? "accordion-button"
                        : "accordion-button collapsed"
                    }
                    data-bs-toggle="collapse"
                    data-bs-target="#pannel-A"
                    aria-expanded={this.state.accordian ? "true" : "false"}
                  >
                    {pathname === "en" && "what is an nft?"}
                    {pathname === "fr" && "QU’EST-CE QU’UN NFT ?"}
                    {pathname === "es" && "¿QUÉ ES UN NFT?"}
                  </div>
                  <div
                    id="pannel-A"
                    class={
                      this.state.accordian && this.state.step === "A"
                        ? "accordion-collapse collapse show"
                        : "accordion-collapse collapse"
                    }
                    data-bs-parent="#accordionPnnel"
                  >
                    <div class="accordion-body">
                      {pathname === "en" && (
                        <p>
                          NFT is the abbreviation for "Non-Fungible Token". It's
                          a unique numeric asset which ownership is managed by
                          the blockchain and that the users may possess, buy and
                          trade.
                        </p>
                      )}
                      {pathname === "fr" && (
                        <p>
                          C’est l’abréviation de « non fungible token ». Il
                          s'agit d'un actif numérique unique dont la propriété
                          est gérée par la blockchain, et que les utilisateurs
                          peuvent posséder, acheter et échanger.
                        </p>
                      )}
                      {pathname === "es" && (
                        <p>
                          NFT es la abreviatura de "token no fungible". Es un
                          activo numérico único cuya propiedad es administrada
                          por la cadena de bloques y que los usuarios pueden
                          poseer, comprar e intercambiar.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  class="accordion-item"
                  onClick={() => this.toggleAccordian("B")}
                >
                  <div
                    class={
                      this.state.accordian && this.state.step === "B"
                        ? "accordion-button"
                        : "accordion-button collapsed"
                    }
                    data-bs-toggle="collapse"
                    data-bs-target="#pannel-B"
                    aria-expanded="false"
                  >
                    {pathname === "en" && "WHAT IS METAMASK?"}
                    {pathname === "fr" && "QU’EST-CE QUE METAMASK ?"}
                    {pathname === "es" && "¿QUÉ ES EL METAMASK?"}
                  </div>
                  <div
                    id="pannel-B"
                    class={
                      this.state.accordian && this.state.step === "B"
                        ? "accordion-collapse collapse show"
                        : "accordion-collapse collapse"
                    }
                    data-bs-parent="#accordionPnnel"
                  >
                    <div class="accordion-body">
                      {pathname === "en" && (
                        <p>
                          Metamask is a crypto wallet that can store your
                          Ethereum. You need it to be able to buy a SuperWild
                          Animal.
                        </p>
                      )}
                      {pathname === "fr" && (
                        <p>
                          Metamask est un porte-feuille qui peut stocker tes
                          ETH. Tu en as besoin pour pouvoir acheter un Super
                          Animal.
                        </p>
                      )}
                      {pathname === "es" && (
                        <p>
                          Metamask es una billetera criptográfica que puedes
                          almacenar tus Ethereum. Lo necesitas para poder
                          comprar un SuperWild Animal.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  class="accordion-item"
                  onClick={() => this.toggleAccordian("C")}
                >
                  <div
                    class={
                      this.state.accordian && this.state.step === "C"
                        ? "accordion-button"
                        : "accordion-button collapsed"
                    }
                    data-bs-toggle="collapse"
                    data-bs-target="#pannel-C"
                    aria-expanded="false"
                  >
                    {pathname === "en" && "HOW TO OWN A SUPERWILD ANIMAL?"}
                    {pathname === "fr" &&
                      "COMMENT OBTENIR UN NFT SUPERWILDCLUB"}
                    {pathname === "es" &&
                      "¿CÓMO OBTENER UN NFT DE SUPERWILDCLUB?"}
                  </div>
                  <div
                    id="pannel-C"
                    class={
                      this.state.accordian && this.state.step === "C"
                        ? "accordion-collapse collapse show"
                        : "accordion-collapse collapse"
                    }
                    data-bs-parent="#accordionPnnel"
                  >
                    <div class="accordion-body">
                      {pathname === "en" && (
                        <p>
                          You can only mint your Super Animal on our official
                          website. We'll announce the exact day and hour on our
                          Discord. So make sure to join us from now on by
                          clicking on the following link. If you don’t know how
                          to mint, please join our Discord and read the
                          #how-to-mint channel.
                        </p>
                      )}
                      {pathname === "fr" && (
                        <p>
                          Tu pourras seulement minter un ou plusieurs NFT de
                          SuperWild Club sur notre site officiel. Nous
                          annoncerons la date et l’heure exacte dans le serveur
                          Discord. Si tu ne sais pas comment minter, tu peux
                          rejoindre notre Discord et lire le canal #how-to-mint.
                        </p>
                      )}
                      {pathname === "es" && (
                        <p>
                          Solo puedes acuñar tu NFT de SUPERWILDCLUBl en nuestro
                          sitio web oficial. Anunciaremos el día y la hora
                          exactos en nuestro Discord. Así que asegúrese de
                          unirse a nosotros a partir de ahora haciendo clic en
                          el siguiente enlace. Si no sabe cómo acuñar, únase a
                          nuestro Discord y lea el canal #how-to-mint.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  class="accordion-item"
                  onClick={() => this.toggleAccordian("D")}
                >
                  <div
                    class={
                      this.state.accordian && this.state.step === "D"
                        ? "accordion-button"
                        : "accordion-button collapsed"
                    }
                    data-bs-toggle="collapse"
                    data-bs-target="#pannel-D"
                    aria-expanded="false"
                  >
                    {pathname === "en" &&
                      "HOW MANY SUPERWILD ANIMALS ARE THERE?"}
                    {pathname === "fr" &&
                      "COMBIEN DE NFT COMPREND LA PREMIERE COLLECTION ?"}
                    {pathname === "es" &&
                      "¿CUÁNTOS NFT TIENE LA PRIMERA COLECCIÓN?"}
                  </div>
                  <div
                    id="pannel-D"
                    class={
                      this.state.accordian && this.state.step === "D"
                        ? "accordion-collapse collapse show"
                        : "accordion-collapse collapse"
                    }
                    data-bs-parent="#accordionPnnel"
                  >
                    <div class="accordion-body">
                      {pathname === "en" && (
                        <p>
                          There are 6,530 SuperWild Macaws for the first
                          collection coming in May. More collections are coming
                          over the year (such as the Rhinos and the Lemurs), and
                          you will have to choose which one you want to be
                          dropped first.
                        </p>
                      )}
                      {pathname === "fr" && (
                        <p>
                          6530 Macaws seront disponibles pour la première
                          collection. D’autres collections sortirons cette
                          année, tel que les Rhinocéros et les Lémuriens. Tu
                          devras choisir laquelle tu souhaites voir sortir en
                          premier
                        </p>
                      )}
                      {pathname === "es" && (
                        <p>
                          Hay 6.530 guacamayos para la primera colección que se
                          realizará en mayo. Más colecciones llegarán durante el
                          año (como Rhinos y Lemurs), y tendrás que elegir cuál
                          quieres que salga primero.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  class="accordion-item"
                  onClick={() => this.toggleAccordian("I")}
                >
                  <div
                    class={
                      this.state.accordian && this.state.step === "I"
                        ? "accordion-button"
                        : "accordion-button collapsed"
                    }
                    data-bs-toggle="collapse"
                    data-bs-target="#pannel-E"
                    aria-expanded="false"
                  >
                    {pathname === "en" && "HOW TO BE WILDLISTED?"}
                    {pathname === "fr" && "COMMENT ETRE WILDLISTÉ ?"}
                    {pathname === "es" && "¿CÓMO ESTAR EN EL WILDLIST?"}
                  </div>
                  <div
                    id="pannel-E"
                    class={
                      this.state.accordian && this.state.step === "I"
                        ? "accordion-collapse collapse show"
                        : "accordion-collapse collapse"
                    }
                    data-bs-parent="#accordionPanel"
                  >
                    {pathname === "en" && (
                      <div class="accordion-body">
                        <p>
                          Our goal is to create an amazing community full of
                          people who help each other, have real conversations,
                          and contribute to the project while having fun and
                          playing to our games.
                        </p>
                        {/* <p>
                          YOU CAN NOW BE WHITELISTED FOR 200 GENESIS COLLECTION
                          WITH 1 WAY :
                        </p> */}
                        <p>
                          Go to the Discord and follow these steps :<br />
                          - Use SUPERWILD™ or SWC in your name
                          <br />- Use one SuperWild animals in your Profile
                          Picture you can find in the starter-pack channel
                        </p>
                        <p>
                          Or by producing art in the fan-art channel and
                          spreading positive vibes.
                        </p>
                      </div>
                    )}
                    {pathname === "fr" && (
                      <div class="accordion-body">
                        <p>
                          Notre objectif est de créer une communauté incroyable
                          composée de personnes qui s’entraident et contribuent
                          au projet tout en s’amusant. La meilleure façon d’être
                          wildlisté est d’aller sur le Discord et de suivre les
                          étapes suivantes : Utiliser SUPERWILD™ or SWC dans son
                          nom. Mettre un des Super Animal en photo de profil que
                          tu peux trouver sur le channel #starterpack. Et enfin
                          tu peux créer un fan-art et mettre l’ambiance.
                        </p>
                      </div>
                    )}
                    {pathname === "es" && (
                      <div class="accordion-body">
                        <p>
                          Nuestro objetivo es crear una comunidad increíble
                          llena de personas que se ayuden entre sí, tengan
                          conversaciones reales y contribuyan al proyecto
                          mientras se divierten y juegan a nuestros juegos.
                        </p>
                        <p>
                          Ahora puede estar en el wildlist para la primera
                          colecction con una manera : vaya a Discord y siga
                          estos pasos: - Use SUPERWILD ™ o SWC en su nombre -
                          Use un animal SuperWild en su imagen de perfil que
                          puede encontrar en el canal del paquete de inicio O
                          produciendo arte en el canal fan-art y difundiendo
                          vibraciones positivas.
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  class="accordion-item"
                  onClick={() => this.toggleAccordian("E")}
                >
                  <div
                    class={
                      this.state.accordian && this.state.step === "E"
                        ? "accordion-button"
                        : "accordion-button collapsed"
                    }
                    data-bs-toggle="collapse"
                    data-bs-target="#pannel-E"
                    aria-expanded="false"
                  >
                    {pathname === "en" && "WHAT WILL THE PRICE BE?"}
                    {pathname === "fr" && "QUEL SERA LE PRIX ?"}
                    {pathname === "es" && "¿CUAL SERA EL PRECIO?"}
                  </div>
                  <div
                    id="pannel-E"
                    class={
                      this.state.accordian && this.state.step === "E"
                        ? "accordion-collapse collapse show"
                        : "accordion-collapse collapse"
                    }
                    data-bs-parent="#accordionPnnel"
                  >
                    <div class="accordion-body">
                      {pathname === "en" && (
                        <p>
                          The price of the Whitelist sale will be 0.20 ETH and
                          the price of the public sale will be 0.25 ETH.
                        </p>
                      )}
                      {pathname === "fr" && (
                        <p>
                          Le prix de la vente Wildlist sera de 0.20 ETH, et le
                          prix de la vente publique sera de 0.25 ETH.
                        </p>
                      )}
                      {pathname === "es" && (
                        <p>
                          El precio de la venta de Wildlist será de 0,20 ETH y
                          el precio de la venta pública será de 0,25 ETH.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  class="accordion-item"
                  onClick={() => this.toggleAccordian("F")}
                >
                  <div
                    class={
                      this.state.accordian && this.state.step === "F"
                        ? "accordion-button"
                        : "accordion-button collapsed"
                    }
                    data-bs-toggle="collapse"
                    data-bs-target="#pannel-F"
                    aria-expanded="false"
                  >
                    {pathname === "en" &&
                      "WHAT ARE THE SECONDARY MARKET ROYALTY FEES FOR NFT HOLDERS?"}
                    {pathname === "fr" &&
                      "QUELLES SONT LES ROYALTIES DU MARCHE SECONDAIRE ?"}
                    {pathname === "es" &&
                      "¿CUÁLES SON LAS TARIFAS DE REGALÍAS DEL MERCADO SECUNDARIO PARA LOS TITULARES DE NFT?"}
                  </div>
                  <div
                    id="pannel-F"
                    class={
                      this.state.accordian && this.state.step === "F"
                        ? "accordion-collapse collapse show"
                        : "accordion-collapse collapse"
                    }
                    data-bs-parent="#accordionPnnel"
                  >
                    <div class="accordion-body">
                      {pathname === "en" && (
                        <p>
                          We retains 7,5% royalties on secondary market sales to
                          develop the other collections and build the animation
                          studio
                        </p>
                      )}
                      {pathname === "fr" && (
                        <p>
                          Nous prenons 7,5% sur les ventes du marché secondaires
                          afin de pouvoir développer les autres collections et
                          créer le studio d’animation.
                        </p>
                      )}
                      {pathname === "es" && (
                        <p>
                          Retenemos regalías del 7,5% sobre las ventas del
                          mercado secundario para desarrollar las otras
                          colecciones y construir el estudio de animación.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  class="accordion-item"
                  onClick={() => this.toggleAccordian("G")}
                >
                  <div
                    class={
                      this.state.accordian && this.state.step === "G"
                        ? "accordion-button"
                        : "accordion-button collapsed"
                    }
                    data-bs-toggle="collapse"
                    data-bs-target="#pannel-G"
                    aria-expanded="false"
                  >
                    {pathname === "en" &&
                      "WHEN DO YOU PLAN TO BUILD THE ANIMATION STUDIO AND RELEASE THE FIRST EPISODE OF THE SERIES?"}
                    {pathname === "fr" &&
                      "QUAND SERA CRÉÉ LE STUDIO D’ANIMATION ET QUAND SORTIRA LE PREMIER ÉPISODE ?"}
                    {pathname === "es" &&
                      "¿CUÁNDO PLANEA CONSTRUIR EL ESTUDIO DE ANIMACIÓN Y LANZAR EL PRIMER EPISODIO DE LA SERIE?"}
                  </div>
                  <div
                    id="pannel-G"
                    class={
                      this.state.accordian && this.state.step === "G"
                        ? "accordion-collapse collapse show"
                        : "accordion-collapse collapse"
                    }
                    data-bs-parent="#accordionPnnel"
                  >
                    <div class="accordion-body">
                      {pathname === "en" && (
                        <p>
                          As you can see on our roadmap, we plan to to build the
                          animation studio and release the first episode between
                          Q3 and Q4 of 2023.
                        </p>
                      )}
                      {pathname === "fr" && (
                        <p>
                          Comme tu peux le voir sur notre roadmap, on prévoit de
                          créer le studio d’animation et de lancer le premier
                          épisode entre le premier et dernier trimestre de 2023.
                        </p>
                      )}
                      {pathname === "es" && (
                        <p>
                          Como puede ver en nuestra hoja de ruta, planeamos
                          construir el estudio de animación y lanzar el primer
                          episodio entre el tercer y cuarto trimestre de 2023.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  class="accordion-item"
                  onClick={() => this.toggleAccordian("H")}
                >
                  <div
                    class={
                      this.state.accordian && this.state.step === "H"
                        ? "accordion-button"
                        : "accordion-button collapsed"
                    }
                    data-bs-toggle="collapse"
                    data-bs-target="#pannel-H"
                    aria-expanded="false"
                  >
                    {pathname === "en" && "CAN I JOIN THE TEAM/HELP?"}
                    {pathname === "fr" && "EST-CE QUE JE PEUX AIDER ?"}
                    {pathname === "es" && "¿PUEDO UNIRME AL EQUIPO/AYUDA?"}
                  </div>
                  <div
                    id="pannel-H"
                    class={
                      this.state.accordian && this.state.step === "H"
                        ? "accordion-collapse collapse show"
                        : "accordion-collapse collapse"
                    }
                    data-bs-parent="#accordionPnnel"
                  >
                    <div class="accordion-body">
                      {pathname === "en" && (
                        <p>
                          Sure ! We are always looking for talented people to
                          join the team ! 🚀 You can reach Throze, our Head of
                          Community on our Discord.
                        </p>
                      )}
                      {pathname === "fr" && (
                        <p>
                          Bien sûr ! Nous sommes toujours à la recherche de
                          talents pour nous rejoindre ! 🚀 Tu peux communiquer
                          avec Throze, notre head of community sur Discord.
                        </p>
                      )}
                      {pathname === "es" && (
                        <p>
                          ¡Por supuesto! ¡Siempre estamos buscando personas
                          talentosas para unirse al equipo! 🚀 Puedes
                          comunicarte con Throze, nuestro jefe de comunidad en
                          nuestro Discord
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Faq;
