import React, { Component } from "react";

class TheTeam extends Component {
  render() {
    const pathname =
      typeof window !== undefined
        ? localStorage.getItem("lang")
          ? localStorage.getItem("lang")
          : "en"
        : "en";
    return (
      <div
        class="content-container team-container bg-alter-container"
        id="team"
      >
        <div class="container">
          <div class="row">
            <div class="col-md-10 offset-md-1">
              <div class="heading text-center text-uppercase">
                <h5>
                  {pathname === "en" && <span>the team</span>}
                  {pathname === "fr" && <span>l’equipe</span>}
                  {pathname === "es" && <span>el equipo</span>}
                </h5>
              </div>
              <ul class="team-list d-flex flex-wrap justify-content-center">
                <li class="team-item">
                  <div class="team-box w-100 h-100 text-center d-flex flex-column">
                    <div class="team-img-box">
                      <img src="include/images/massilia.jpg" alt="" />
                    </div>
                    <b>massilia</b>
                    {pathname === "en" && <p>CMO - Founder</p>}
                    {pathname === "fr" && <p>CMO - Fondatrice</p>}
                    {pathname === "es" && <p>CMO - Fundadora</p>}
                    <ul class="social d-flex align-items-center justify-content-center mt-auto">
                      <li>
                        <a
                          href="https://www.instagram.com/mass.bmr/"
                          target="_blank"
                          rel="noopener"
                        >
                          <i class="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/massbmr"
                          target="_blank"
                          rel="noopener"
                        >
                          <i class="fab fa-twitter"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="team-item">
                  <div class="team-box w-100 h-100 text-center d-flex flex-column">
                    <div class="team-img-box">
                      <img src="include/images/julien.jpg" alt="" />
                    </div>
                    <b>Julien</b>
                    {pathname === "en" && <p>Art Director - Founder</p>}
                    {pathname === "fr" && <p>Directeur artistique Fondateur</p>}
                    {pathname === "es" && <p>Director artistico Fundador</p>}
                    <ul class="social d-flex align-items-center justify-content-center mt-auto">
                      <li>
                        <a
                          href="https://www.instagram.com/julienroche_/"
                          target="_blank"
                          rel="noopener"
                        >
                          <i class="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/CaLLMeSiiiicK"
                          target="_blank"
                          rel="noopener"
                        >
                          <i class="fab fa-twitter"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="team-item">
                  <div class="team-box w-100 h-100 text-center d-flex flex-column">
                    <div class="team-img-box">
                      <img src="include/images/joaquin.jpg" alt="" />
                    </div>
                    <b>joaquin</b>
                    {pathname === "en" && <p>Creative Director - Founder</p>}
                    {pathname === "fr" && (
                      <p>Directeur de création Fondateur</p>
                    )}
                    {pathname === "es" && (
                      <p>Director de créacion - Fundador</p>
                    )}
                    <ul class="social d-flex align-items-center justify-content-center mt-auto">
                      <li>
                        <a
                          href="https://www.instagram.com/jacome_giler/"
                          target="_blank"
                          rel="noopener"
                        >
                          <i class="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/jacome_joaquin1"
                          target="_blank"
                          rel="noopener"
                        >
                          <i class="fab fa-twitter"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="team-item">
                  <div class="team-box w-100 h-100 text-center d-flex flex-column">
                    <div class="team-img-box">
                      <img src="include/images/raul.jpg" alt="" />
                    </div>
                    <b>raul</b>
                    {pathname === "en" && <p>Amazing Artist</p>}
                    {pathname === "fr" && <p>Le super artiste </p>}
                    {pathname === "es" && <p>El artista</p>}
                    <ul class="social d-flex align-items-center justify-content-center mt-auto">
                      <li>
                        <a
                          href="https://www.instagram.com/raulgonzalez2d/"
                          target="_blank"
                          rel="noopener"
                        >
                          <i class="fab fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="team-item">
                  <div class="team-box w-100 h-100 text-center d-flex flex-column">
                    <div class="team-img-box">
                      <img src="include/images/charles.jpg" alt="" />
                    </div>
                    <b>Charles</b>
                    {pathname === "en" && <p>Developer</p>}
                    {pathname === "fr" && <p>Développeur blockchain</p>}
                    {pathname === "es" && <p>Desarrollador</p>}
                    <ul class="social d-flex align-items-center justify-content-center mt-auto">
                      {" "}
                      <li>
                        <a
                          href="https://www.linkedin.com/in/charles-diallo/"
                          target="_blank"
                          rel="noopener"
                        >
                          <i class="fab fa-linkedin"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="team-item">
                  <div class="team-box w-100 h-100 text-center d-flex flex-column">
                    <div class="team-img-box">
                      <img src="include/images/thomas.jpg" alt="" />
                    </div>
                    <b>Thomas</b>
                    {pathname === "en" && <p>Head of Community</p>}
                    {pathname === "fr" && <p>Chef de la communauté</p>}
                    {pathname === "es" && <p>Jefe de la comunidad</p>}
                    <ul class="social d-flex align-items-center justify-content-center mt-auto">
                      <li>
                        <a
                          href="https://www.instagram.com/throze/"
                          target="_blank"
                          rel="noopener"
                        >
                          <i class="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/ThrozeNFT"
                          target="_blank"
                          rel="noopener"
                        >
                          <i class="fab fa-twitter"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="team-item">
                  <div class="team-box w-100 h-100 text-center d-flex flex-column">
                    <div class="team-img-box">
                      <img src="include/images/freddy.jpg" alt="" />
                    </div>
                    <b>freddy</b>
                    {pathname === "en" && <p>Founder & Blockchain believer</p>}
                    {pathname === "fr" && (
                      <p>Fondateur & adepte de la blockchain</p>
                    )}
                    {pathname === "es" && <p>Foundador & experto blockchain</p>}
                    <ul class="social d-flex align-items-center justify-content-center mt-auto"></ul>
                  </div>
                </li>
                <li class="team-item">
                  <div class="team-box w-100 h-100 text-center d-flex flex-column">
                    <div class="team-img-box">
                      <img src="include/images/nadia.jpg" alt="" />
                    </div>
                    <b>nadia</b>
                    {pathname === "en" && <p>Founder & Blockchain believer</p>}
                    {pathname === "fr" && (
                      <p>Fondatrice & adepte de la blockchain</p>
                    )}
                    {pathname === "es" && (
                      <p>Foundadora & experta blockchain</p>
                    )}
                    <ul class="social d-flex align-items-center justify-content-center mt-auto"></ul>
                  </div>
                </li>
                <li class="team-item">
                  <div class="team-box w-100 h-100 text-center d-flex flex-column">
                    <div class="team-img-box">
                      <img src="include/images/belen.jfif" alt="" />
                    </div>
                    <b>belen</b>
                    {pathname === "en" && <p>Animation advisor</p>}
                    {pathname === "fr" && <p>Conseiller en animation</p>}
                    {pathname === "es" && <p>Consejero en animación</p>}
                    <ul class="social d-flex align-items-center justify-content-center mt-auto">
                      <li>
                        <a
                          href="https://belensdvart.com/"
                          target="_blank"
                          rel="noopener"
                        >
                          <i class="fas fa-globe"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="team-item">
                  <div class="team-box w-100 h-100 text-center d-flex flex-column">
                    <div class="team-img-box">
                      <img src="include/images/seth.jfif" alt="" />
                    </div>
                    <b>seth</b>
                    {pathname === "en" && <p>Animation advisor</p>}
                    {pathname === "fr" && <p>Conseilleur en animation</p>}
                    {pathname === "es" && <p>Consejero en animación</p>}
                    <ul class="social d-flex align-items-center justify-content-center mt-auto">
                      <li>
                        <a
                          href="https://sethgreenwoodart.com/"
                          target="_blank"
                          rel="noopener"
                        >
                          <i class="fas fa-globe"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TheTeam;
