import React, { Component, createRef } from "react";
import Slider from "react-slick";

class ImageGallery extends Component {
  slider1 = createRef(null);
  slider2 = createRef(null);
  slider3 = createRef(null);
  state = {
    nav1: null,
    nav2: null,
    nav3: null,
  };

  componentDidMount = () => {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
      nav3: this.slider3,
    });
  };

  render() {
    const settings = {
      //asNavFor: ".custom-slider.slider-two,.custom-slider.slider-three",
      slidesToShow: 3,
      arrows: false,
      dots: false,
      swipe: false,
      infinite: true,
      speed: 6000,
      autoplay: true,
      autoplaySpeed: 0,
      cssEase: "linear",
      draggable: false,
      accessibility: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    };

    const settings2 = {
      //asNavFor: ".custom-slider.slider-one,.custom-slider.slider-three",
      slidesToShow: 3,
      arrows: false,
      dots: false,
      swipe: false,
      infinite: true,
      speed: 5000,
      autoplay: true,
      autoplaySpeed: 0,
      cssEase: "linear",
      draggable: false,
      rtl: true,
      accessibility: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    };

    const settings3 = {
      rtl: false,
      //asNavFor: ".custom-slider.slider-one,.custom-slider.slider-two",
      slidesToShow: 3,
      arrows: false,
      dots: false,
      swipe: false,
      infinite: true,
      speed: 6000,
      autoplay: true,
      autoplaySpeed: 0,
      cssEase: "linear",
      draggable: false,
      accessibility: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    };
    const pathname =
      typeof window !== undefined
        ? localStorage.getItem("lang")
          ? localStorage.getItem("lang")
          : "en"
        : "en";
    return (
      <div class="content-container info-slider-container mt-0 overflow-hidden">
        <div class="container">
          <div class="row">
            <div class="col-md-12 offset-md-0 col-lg-10 offset-lg-1">
              <div class="heading-text text-center text-uppercase small-size">
                <h4>
                  {pathname === "en" && <span>and more to come</span>}
                  {pathname === "fr" && <span>et plus encore...</span>}
                  {pathname === "es" && <span>Y MaS POR VENIR</span>}
                </h4>
              </div>
              <div class="sliders w-100">
                <Slider
                  {...settings}
                  className="custom-slider slider-one w-100 pb-0"
                  //asNavFor={(this.state.nav2, this.state.nav3)}
                  ref={(slider) => (this.slider1 = slider)}
                >
                  <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img
                        src="include/images/base-lemur-design-1.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img
                        src="include/images/base-rhino-design-1.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  {/* <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img src="include/images/slider-img-3.png" alt="" />
                    </div>
                  </div> */}
                  <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img
                        src="include/images/base-lemur-design-2.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img
                        src="include/images/base-rhino-design-2.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  {/* <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img src="include/images/slider-img-3.png" alt="" />
                    </div>
                  </div> */}
                  <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img
                        src="include/images/base-lemur-design-3.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img
                        src="include/images/base-rhino-design-3.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  {/* <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img src="include/images/slider-img-3.png" alt="" />
                    </div>
                  </div> */}
                  <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img
                        src="include/images/base-lemur-design-4.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img
                        src="include/images/base-rhino-design-4.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  {/* <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img src="include/images/slider-img-3.png" alt="" />
                    </div>
                  </div> */}
                  <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img
                        src="include/images/base-lemur-design-4.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img
                        src="include/images/base-rhino-design-1.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  {/* <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img src="include/images/slider-img-3.png" alt="" />
                    </div>
                  </div> */}
                </Slider>

                <Slider
                  {...settings2}
                  className="custom-slider slider-two w-100 pb-0"
                  // asNavFor={(this.state.nav1, this.state.nav3)}
                  ref={(slider) => (this.slider2 = slider)}
                >
                  <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img
                        src="include/images/base-lemur-design-1.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img
                        src="include/images/base-rhino-design-1.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  {/* <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img src="include/images/slider-img-3.png" alt="" />
                    </div>
                  </div> */}
                  <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img
                        src="include/images/base-lemur-design-2.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img
                        src="include/images/base-rhino-design-2.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  {/* <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img src="include/images/slider-img-3.png" alt="" />
                    </div>
                  </div> */}
                  <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img
                        src="include/images/base-lemur-design-3.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img
                        src="include/images/base-rhino-design-3.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  {/* <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img src="include/images/slider-img-3.png" alt="" />
                    </div>
                  </div> */}
                  <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img
                        src="include/images/base-lemur-design-4.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img
                        src="include/images/base-rhino-design-4.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  {/* <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img src="include/images/slider-img-3.png" alt="" />
                    </div>
                  </div> */}
                  <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img
                        src="include/images/base-lemur-design-4.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img
                        src="include/images/base-rhino-design-1.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  {/* <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img src="include/images/slider-img-3.png" alt="" />
                    </div>
                  </div> */}
                </Slider>
                <Slider
                  {...settings3}
                  className="custom-slider slider-three w-100 pb-0"
                  //asNavFor={(this.state.nav1, this.state.nav2)}
                  ref={(slider) => (this.slider3 = slider)}
                >
                  <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img
                        src="include/images/base-lemur-design-1.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img
                        src="include/images/base-rhino-design-1.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  {/* <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img src="include/images/slider-img-3.png" alt="" />
                    </div>
                  </div> */}
                  <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img
                        src="include/images/base-lemur-design-2.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img
                        src="include/images/base-rhino-design-2.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  {/* <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img src="include/images/slider-img-3.png" alt="" />
                    </div>
                  </div> */}
                  <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img
                        src="include/images/base-lemur-design-3.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img
                        src="include/images/base-rhino-design-3.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  {/* <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img src="include/images/slider-img-3.png" alt="" />
                    </div>
                  </div> */}
                  <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img
                        src="include/images/base-lemur-design-4.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img
                        src="include/images/base-rhino-design-4.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  {/* <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img src="include/images/slider-img-3.png" alt="" />
                    </div>
                  </div> */}
                  <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img
                        src="include/images/base-lemur-design-4.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img
                        src="include/images/base-rhino-design-1.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  {/* <div class="custom-slide">
                    <div class="custom-slide-img w-100">
                      <img src="include/images/slider-img-3.png" alt="" />
                    </div>
                  </div> */}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ImageGallery;
