import React, { Component, createRef } from "react";
import { gsap } from "gsap";
// import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Pagination, EffectCoverflow } from "swiper";
class Legendaries extends Component {
  parallexRef = createRef(null);
  componentDidMount = () => {
    const parallex = [this.parallexRef];
    parallex.forEach((cb) =>
      gsap.to(cb, {
        y: -100,
        ease: "none",
        scrollTrigger: {
          trigger: cb,
          start: "top bottom",
          scrub: true,
          //markers:true,
        },
      })
    );
  };
  render() {
    const pathname =
      typeof window !== undefined
        ? localStorage.getItem("lang")
          ? localStorage.getItem("lang")
          : "en"
        : "en";
    return (
      <div class="content-container legendaries-container pt-0">
        <div class="container">
          <div class="row">
            <div class="col-md-12 position-relative">
              <div
                class="six-shape-bg is-parallax"
                ref={(el) => (this.parallexRef = el)}
              >
                <img src="include/images/Buffon's-Ara_Feather_3-1.png" alt="" />
              </div>
              <div class="heading-text text-center">
                <h4>
                  {pathname === "en" && <span>legendaries</span>}
                  {pathname === "fr" && <span>legendaires</span>}
                  {pathname === "es" && <span>legendarios</span>}
                </h4>
                {pathname === "en" && (
                  <p>
                    If you are lucky enough, you might be the owner of a unique
                    legendary Macaw.
                  </p>
                )}
                {pathname === "fr" && (
                  <p>
                    Si tu as de la chance, tu pourrais devenir le holder d’un
                    Macaw légendaire.
                  </p>
                )}
                {pathname === "es" && (
                  <p>
                    Si tienes la suerte, podrías ser el propietario de un
                    guacamayo legendario único.
                  </p>
                )}
              </div>
              <div class="gallery-slider overflow-hidden">
                <Swiper
                  className="swiper-container gallery"
                  allowSlideNext={false}
                  allowSlidePrev={false}
                  effect={"coverflow"}
                  modules={[Pagination, EffectCoverflow]}
                  loop={true}
                  speed={600}
                  centeredSlides={true}
                  slideShadows={false}
                  slidesPerView={"auto"}
                  centeredSlidesBounds={true}
                  pagination={true}
                  grabCursor={true}
                  coverflowEffect={{
                    rotate: 0,
                    stretch: 67,
                    depth: 150,
                    modifier: 2.25,
                    slideShadows: false,
                    scale: 0.9,
                  }}
                >
                  <div className="swiper-wrapper">
                    <SwiperSlide className="swiper-slide">
                      <div className="slider-image">
                        <img src="include/images/slider-1.png" alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className="swiper-slide">
                      <div className="slider-image">
                        <img src="include/images/slider-2.png" alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className="swiper-slide">
                      <div className="slider-image">
                        <img src="include/images/slider-3.png" alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className="swiper-slide">
                      <div className="slider-image">
                        <img src="include/images/slider-5.png" alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className="swiper-slide">
                      <div className="slider-image">
                        <img src="include/images/slider-4.png" alt="" />
                      </div>
                    </SwiperSlide>
                  </div>
                </Swiper>
              </div>
              <div class="row-btn text-center new-row-btn">
                <a
                  href="https://discord.com/invite/superwildclub"
                  target="_blank"
                  class="btn btn-default btn-md"
                >
                  <i class="fab fa-discord"></i>{" "}
                  {pathname === "en" && "join the club"}
                  {pathname === "fr" && "rejoins le club"}
                  {pathname === "es" && "ÚNETE AL CLUB"}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Legendaries;
