import React, { Component, createRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import $ from "jquery";
gsap.registerPlugin(ScrollTrigger);

class Video extends Component {
  mediaRef = createRef(null);

  componentDidMount = () => {
    gsap.timeline({
      scrollTrigger: {
        trigger: this.mediaRef,
        start: "top top",
        end: "bottom top",
        scrub: true,
      },
    });
  };

  render() {
    return (
      <div class="media-row-container" ref={(el) => (this.mediaRef = el)}>
        <div class="container">
          <div class="row">
            <div class="col-md-12 offset-md-0 col-lg-10 offset-lg-1">
              <div
                class="media-row"
                // data-os-animation="fadeInUp"
                // data-os-animation-delay="0.3s"
              >
                {" "}
                <div class="media-box pb-0 position-relative">
                  <div class="ratio ratio-16x9">
                    <iframe
                      src="https://www.youtube.com/embed/TgiFrUPsU-4"
                      title="YouTube video"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Video;
