import React, { Component, createRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

class TheProject extends Component {
  parallexRef = createRef(null);

  componentDidMount = () => {
    const parallex = [this.parallexRef];
    parallex.forEach((cb) =>
      gsap.to(cb, {
        y: -100,
        ease: "none",
        scrollTrigger: {
          trigger: cb,
          start: "top bottom",
          scrub: true,
          //markers:true,
        },
      })
    );
  };
  render() {
    const pathname =
      typeof window !== undefined
        ? localStorage.getItem("lang")
          ? localStorage.getItem("lang")
          : "en"
        : "en";
    return (
      <div class="content-container project-container" id="theProject">
        <div class="container">
          <div class="row">
            <div class="col-md-12 offset-md-0 col-lg-10 offset-lg-1">
              <div class="cc-content-box d-md-flex flex-md-wrap">
                <div class="cc-left position-relative">
                  <div
                    class="cobalt-feather-img is-parallax"
                    ref={(el) => (this.parallexRef = el)}
                  >
                    <img src="include/images/cobalt-feather.png" alt="" />
                  </div>
                  <div class="heading-text">
                    <h4>{pathname === "en" && <span>the project</span>}</h4>
                    <h4>{pathname === "fr" && <span>le projet</span>}</h4>
                    <h4>{pathname === "es" && <span>the project</span>}</h4>
                  </div>

                  {pathname === "en" && (
                    <div class="cc-left-text">
                      <p>
                        <b>there is a beginning to every great idea</b>
                      </p>
                      <p>
                        However, while an individual notion frequently serves as
                        the foundation for a project, success is achieved
                        through teamwork. And that is the power of
                        collaboration!
                      </p>
                      <p>
                        In order to reach our first milestone, a collaborative
                        animation studio, we need all of you !
                      </p>
                      <p>
                        You will be the minds behind the development and the
                        evolution of this idea. You will be decisive about
                        choosing the scenarios, settings, clothing styles and
                        even the situation of SuperWildClub’s Super Animals.
                      </p>
                      <p>
                        We selected Macaw for the first family. We worked on
                        different species of Macaw and we created personality
                        for each character which impacted significantly the
                        design.
                      </p>
                    </div>
                  )}
                  {pathname === "fr" && (
                    <div class="cc-left-text">
                      <p>
                        <b>chaque grande idée a un commencement</b>
                      </p>
                      <p>
                        Alors qu'une notion individuelle sert souvent de base à
                        un projet, le succès passe par le travail d'équipe. Et
                        c'est le pouvoir de la collaboration !
                      </p>
                      <p>
                        Afin d'atteindre notre première étape, le studio
                        d'animation collaboratif, nous avons besoin de toi et de
                        vous tous !
                      </p>
                      <p>
                        Tu seras le cerveau derrière le développement et
                        l'évolution du projet. Tu seras décisif quant au choix
                        des scénarios, des décors, des styles vestimentaires et
                        même de la situation des animaux de SuperWildClub.
                      </p>
                      <p>
                        Nous avons sélectionné les Macaws pour la première
                        famille. Nous avons travaillé sur différentes espèces et
                        nous avons créé une personnalité pour chaque personnage,
                        avec un impact significatif sur le design.
                      </p>
                    </div>
                  )}
                  {pathname === "es" && (
                    <div class="cc-left-text">
                      <p>
                        <b>Hay un comienzo para cada gran idea</b>
                      </p>
                      <p>
                        Sin embargo, mientras que una noción individual suele
                        servir como base para un proyecto, el éxito se logra a
                        través del trabajo en equipo. ¡Y ese es el poder de la
                        colaboración!
                      </p>
                      <p>
                        Para alcanzar nuestro primer hito, un estudio de
                        animación colaborativo, ¡los necesitamos a todos!
                      </p>
                      <p>
                        Serán las mentes detrás del desarrollo y la evolución de
                        esta idea. Serás decisivo a la hora de elegir los
                        escenarios, escenarios, estilos de ropa e incluso la
                        situación de los Súper Animales de SuperWildClub.
                      </p>
                      <p>
                        Seleccionamos Macaw para la primera familia. Trabajamos
                        en diferentes especies de guacamayos y creamos
                        personalidad para cada personaje que impactó
                        significativamente en el diseño.
                      </p>
                    </div>
                  )}
                  <div class="cc-btn d-none d-md-block">
                    <a
                      href="https://discord.com/invite/superwildclub"
                      target="_blank"
                      class="btn btn-default btn-lg"
                    >
                      <i class="fab fa-discord"></i>{" "}
                      {pathname === "en" && "join the club"}
                      {pathname === "fr" && "rejoins le club"}
                      {pathname === "es" && "ÚNETE AL CLUB"}
                    </a>
                  </div>
                </div>
                <div class="cc-right position-relative">
                  <div class="img">
                    <img src="include/images/macaw-peace.png" alt="" />
                  </div>
                </div>
              </div>
              <div class="cc-btn d-block d-md-none">
                <a
                  href="https://discord.com/invite/superwildclub"
                  target="_blank"
                  class="btn btn-default btn-lg btn-block"
                >
                  <i class="fab fa-discord"></i>{" "}
                  {pathname === "en" && "join the club"}
                  {pathname === "fr" && "rejoins le club"}
                  {pathname === "es" && "ÚNETE AL CLUB"}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TheProject;
