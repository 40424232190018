import React, { Component } from "react";

class HeaderMint extends Component {
  render() {
    const pathname =
      typeof window !== undefined
        ? localStorage.getItem("lang")
          ? localStorage.getItem("lang")
          : "en"
        : "en";
    return (
      <header id="header">
        <nav class="navbar navbar-expand-md">
          <div class="container-fluid">
            <div class="nav-inside d-flex align-items-center justify-content-between">
              {/* <button class="nav-icon" onClick={this.props.toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </button> */}

              <div class="header-right ms-auto  d-flex align-items-center">
                <div class="hr-item">
                  <ul class="select-lang-list d-flex align-items-center">
                    <li class={pathname === "en" ? "selected" : ""}>
                      <a
                        href="javascript:void(0)"
                        onClick={() => {
                          localStorage.setItem("lang", "en");
                          window.location.reload();
                        }}
                      >
                        ENG
                      </a>
                    </li>
                    <li class={pathname === "fr" ? "selected" : ""}>
                      <a
                        href="javascript:void(0)"
                        onClick={() => {
                          localStorage.setItem("lang", "fr");
                          window.location.reload();
                        }}
                      >
                        FR
                      </a>
                    </li>
                    <li class={pathname === "es" ? "selected" : ""}>
                      <a
                        href="javascript:void(0)"
                        onClick={() => {
                          localStorage.setItem("lang", "es");
                          window.location.reload();
                        }}
                      >
                        ES
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="hr-item d-none d-lg-block"></div>
                <div class="hr-item d-none d-lg-block">
                  <ul class="social-link d-flex align-items-center">
                    <li>
                      <a
                        href="https://www.instagram.com/superwildclub/"
                        target="_blank"
                        rel="noopener"
                      >
                        <i class="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/superwildclub"
                        target="_blank"
                        rel="noopener"
                      >
                        <i class="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://discord.com/invite/superwildclub"
                        target="_blank"
                        rel="noopener"
                      >
                        <i class="fab fa-discord"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default HeaderMint;
