import React, { Component, createRef } from "react";
import Slider from "react-slick";
import { gsap } from "gsap";
class Collections extends Component {
  // parallexRef = createRef(null);
  // componentDidMount = () => {
  //   const parallex = [this.parallexRef];
  //   parallex.forEach((cb) =>
  //     gsap.to(cb, {
  //       y: -100,
  //       ease: "none",
  //       scrollTrigger: {
  //         trigger: cb,
  //         start: "top bottom",
  //         scrub: true,
  //         //markers:true,
  //       },
  //     })
  //   );
  // };
  render() {
    const pathname =
      typeof window !== undefined
        ? localStorage.getItem("lang")
          ? localStorage.getItem("lang")
          : "en"
        : "en";
    const settings = {
      dots: false,
      arrows: true,
      variableWidth: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipe: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: true,
          },
        },
        {
          breakpoint: 480,
          settings: {
            arrows: true,
          },
        },
      ],
    };
    const settings1 = {
      dots: true,
      infinite: false,
      centerMode: false,
      variableWidth: false,
      arrows: false,
      fade: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipe: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            dots: true,
            arrows: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            dots: true,
            arrows: false,
          },
        },
      ],
    };
    return (
      <div
        class="content-container characters-container has-bg-container pb-0 add-overlay add-bacdrop-shadow  overflow-hidden position-relative"
        id="collections"
      >
        <div class="container">
          <div class="row">
            <div class="col-md-10 offset-md-1">
              <div class="heading-text text-center small">
                {pathname === "en" && <h6>6530 unique nft</h6>}
                {pathname === "fr" && <h6>6530 nft uniques</h6>}
                {pathname === "es" && <h6>6530 unICO NFT</h6>}
                <h4>{pathname === "en" && <span>the macaws</span>}</h4>
                <h4>{pathname === "fr" && <span>les macaws</span>}</h4>
                <h4>{pathname === "es" && <span>LOS GUACAMAYOS</span>}</h4>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 offset-md-0 col-lg-10 offset-lg-1">
              <Slider
                {...settings}
                className="full-width-slider add-custom-arrow wrapper-slider w-100"
              >
                <div class="fws-item">
                  <div class="fws-box d-flex flex-wrap">
                    <div class="fws-img-box">
                      <Slider
                        {...settings1}
                        className="fws-image-slider wrapper-inner-slider add-custom-dots"
                      >
                        <div class="fws-image-item">
                          <div class="fws-image">
                            <img
                              src="include/images/macaw-cobal-1.png"
                              alt=""
                            />
                          </div>
                        </div>
                        <div class="fws-image-item">
                          <div class="fws-image">
                            <img
                              src="include/images/macaw-cobal-2.png"
                              alt=""
                            />
                          </div>
                        </div>
                        <div class="fws-image-item">
                          <div class="fws-image">
                            <img
                              src="include/images/macaw-cobal-3.png"
                              alt=""
                            />
                          </div>
                        </div>
                        <div class="fws-image-item">
                          <div class="fws-image">
                            <img
                              src="include/images/macaw-cobal-4.png"
                              alt=""
                            />
                          </div>
                        </div>
                      </Slider>
                    </div>
                    <div class="fws-text-box">
                      <h5>CLINT</h5>
                      <h6>(2000 NFT)</h6>
                      <p>
                        {pathname === "en" &&
                          "The Mayor of SuperWild Island. Arrogant and precious, he always think of his interest before caring about the population."}
                        {pathname === "fr" &&
                          "Le maire de SuperWild. Arrogant et précieux, il n’hésite pas à faire passer ses propres intérêts avant ceux des habitants."}
                        {pathname === "es" &&
                          "El alcalde de SuperWild Island. Arrogante y precioso, siempre piensa en sus intereses antes que en la población."}
                      </p>
                      <b>
                        {" "}
                        {pathname === "en" && "JOB"}
                        {pathname === "fr" && "JOB"}
                        {pathname === "es" && "TRABAJO"}
                      </b>
                      <p>
                        {pathname === "en" &&
                          "Mayor and CEO of multiple companies."}
                        {pathname === "fr" &&
                          "Maire et CEO de plusieurs entreprises."}
                        {pathname === "es" &&
                          "Alcalde y CEO de múltiples empresas."}
                      </p>
                      <b>
                        {pathname === "en" && "HOBBY"}
                        {pathname === "fr" && "HOBBIE"}
                        {pathname === "es" && "HOBBY"}
                      </b>
                      <p>
                        {pathname === "en" &&
                          "Golfing, yachting and smoking cigars"}
                        {pathname === "fr" &&
                          "Faire du gold, du yacht et fumer des cigares"}
                        {pathname === "es" && "Golf, vela y fumar puros "}
                      </p>
                      <b>
                        {" "}
                        {pathname === "en" && "FAVORITE WORDS"}
                        {pathname === "fr" && "MOTS FAVORIS"}
                        {pathname === "es" && "PALABRAS FAVORITAS"}
                      </b>
                      <p>
                        {pathname === "en" && "Jesus - I beleive - certainly"}
                        {pathname === "fr" && "Jesus - Je crois - Certainement"}
                        {pathname === "es" && "Jesús - Creer – Por cierto"}
                      </p>
                      <b>
                        {pathname === "en" && "RARITY"}
                        {pathname === "fr" && "RARETÉ"}
                        {pathname === "es" && "RAREZA"}
                      </b>
                      <ul class="star-rating-row d-flex align-items-center">
                        <li>
                          <img src="include/images/star.png" alt="" />
                        </li>
                        <li>
                          <img src="include/images/star.png" alt="" />
                        </li>
                        <li>
                          <img src="include/images/star.png" alt="" />
                        </li>
                        <li class="disable">
                          <img src="include/images/star.png" alt="" />
                        </li>
                        <li class="disable">
                          <img src="include/images/star.png" alt="" />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="fws-item">
                  <div class="fws-box d-flex flex-wrap">
                    <div class="fws-img-box">
                      <Slider
                        {...settings1}
                        className="fws-image-slider wrapper-inner-slider add-custom-dots"
                      >
                        <div class="fws-image-item">
                          <div class="fws-image">
                            <img
                              src="include/images/macaw-blue-throated-1.jpg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div class="fws-image-item">
                          <div class="fws-image">
                            <img
                              src="include/images/macaw-blue-throated-2.jpg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div class="fws-image-item">
                          <div class="fws-image">
                            <img
                              src="include/images/macaw-blue-throated-3.jpg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div class="fws-image-item">
                          <div class="fws-image">
                            <img
                              src="include/images/macaw-blue-throated-4.jpg"
                              alt=""
                            />
                          </div>
                        </div>
                      </Slider>
                    </div>
                    <div class="fws-text-box">
                      <h5>FREDDO</h5>
                      <h6>(650 NFT)</h6>
                      <p>
                        {pathname === "en" &&
                          "Very talkative, he has always a story to tell. As a bartender, he also likes to gossip about all the things happening in town."}
                        {pathname === "fr" &&
                          "Très bavard, il a toujours une histoire à raconter. En tant que barman, il adore commérer à propos de tout ce qui se passe dans la ville."}
                        {pathname === "es" &&
                          "Muy hablador, siempre tiene una historia que contar. Como cantinero, también le gusta cotorrear sobre todo lo que sucede en la ciudad."}
                      </p>
                      <b>
                        {" "}
                        {pathname === "en" && "JOB"}
                        {pathname === "fr" && "JOB"}
                        {pathname === "es" && "TRABAJO"}
                      </b>
                      <p>
                        {pathname === "en" && "Owner of the MOJO bar"}
                        {pathname === "fr" && "Propriétaire du bar MOJO"}
                        {pathname === "es" && "Dueño del bar MOJO"}
                      </p>
                      <b>
                        {pathname === "en" && "HOBBY"}
                        {pathname === "fr" && "HOBBIE"}
                        {pathname === "es" && "HOBBY"}
                      </b>
                      <p>
                        {pathname === "en" && "Gossiping"}
                        {pathname === "fr" && "Commérage"}
                        {pathname === "es" && "Chismoso"}
                      </p>
                      <b>
                        {pathname === "en" && "FAVORITE WORDS"}
                        {pathname === "fr" && "MOTS FAVORIS"}
                        {pathname === "es" && "FAVORITE WORDS"}
                      </b>
                      <p>
                        {pathname === "en" && "Bro - Peace - Easy"}
                        {pathname === "fr" && "Frère - Relax - Tranquille"}
                        {pathname === "es" && "Hermano - Paz - Fácil"}
                      </p>
                      <b>
                        {pathname === "en" && "RARITY"}
                        {pathname === "fr" && "RARETÉ"}
                        {pathname === "es" && "RAREZA"}
                      </b>
                      <ul class="star-rating-row d-flex align-items-center">
                        <li>
                          <img src="include/images/star.png" alt="" />
                        </li>
                        <li>
                          <img src="include/images/star.png" alt="" />
                        </li>
                        <li>
                          <img src="include/images/star.png" alt="" />
                        </li>
                        <li>
                          <img src="include/images/star.png" alt="" />
                        </li>
                        <li class="disable">
                          <img src="include/images/star.png" alt="" />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="fws-item">
                  <div class="fws-box d-flex flex-wrap">
                    <div class="fws-img-box">
                      <Slider
                        {...settings1}
                        className="fws-image-slider wrapper-inner-slider add-custom-dots"
                      >
                        <div class="fws-image-item">
                          <div class="fws-image">
                            <img
                              src="include/images/macaw-buffon's-ara-1.jpg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div class="fws-image-item">
                          <div class="fws-image">
                            <img
                              src="include/images/macaw-buffon's-ara-2.jpg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div class="fws-image-item">
                          <div class="fws-image">
                            <img
                              src="include/images/macaw-buffon's-ara-3.jpg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div class="fws-image-item">
                          <div class="fws-image">
                            <img
                              src="include/images/macaw-buffon's-ara-4.jpg"
                              alt=""
                            />
                          </div>
                        </div>
                      </Slider>
                    </div>
                    <div class="fws-text-box">
                      <h5>JANET</h5>
                      <h6>(3700 NFT)</h6>
                      <p>
                        {pathname === "en" &&
                          "Creative and independent, she fights for her ideologies of freedom and equality. She is the best jewellery designer in town!"}
                        {pathname === "fr" &&
                          "Créative et indépendante, elle se bat pour ses idées de libertés et d’égalité. C’est la meilleur designer de la ville !"}
                        {pathname === "es" &&
                          "Creativa e independiente, lucha por sus ideologías de libertad e igualdad. ¡Es la mejor diseñadora de joyas de la ciudad!"}
                      </p>
                      <b>
                        {" "}
                        {pathname === "en" && "JOB"}
                        {pathname === "fr" && "JOB"}
                        {pathname === "es" && "TRABAJO"}
                      </b>
                      <p>
                        {pathname === "en" && "Jewellery designer."}
                        {pathname === "fr" && "Designer de bijou"}
                        {pathname === "es" && "Diseñador de joyas."}
                      </p>
                      <b>
                        {pathname === "en" && "HOBBY"}
                        {pathname === "fr" && "HOBBIE"}
                        {pathname === "es" && "HOBBY"}
                      </b>
                      <p>
                        {pathname === "en" && "Painting and ceramics."}
                        {pathname === "fr" && "Peinture et céramique"}
                        {pathname === "es" && "Pintura y cerámica."}
                      </p>
                      <b>
                        {pathname === "en" && "FAVORITE WORDS"}
                        {pathname === "fr" && "MOTS FAVORIS"}
                        {pathname === "es" && "PALABRAS FAVORITAS"}
                      </b>
                      <p>
                        {pathname === "en" && "Beautiful - Amazing - Oh my God"}
                        {pathname === "fr" &&
                          "Magnifiiiique - Incroyable - Oh mon Dieu"}
                        {pathname === "es" &&
                          "Hermoso - Increíble - Oh, Dios mío"}
                      </p>
                      <b>
                        {pathname === "en" && "RARITY"}
                        {pathname === "fr" && "RARETÉ"}
                        {pathname === "es" && "RAREZA"}
                      </b>
                      <ul class="star-rating-row d-flex align-items-center">
                        <li>
                          <img src="include/images/star.png" alt="" />
                        </li>
                        <li>
                          <img src="include/images/star.png" alt="" />
                        </li>
                        <li class="disable">
                          <img src="include/images/star.png" alt="" />
                        </li>
                        <li class="disable">
                          <img src="include/images/star.png" alt="" />
                        </li>
                        <li class="disable">
                          <img src="include/images/star.png" alt="" />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="fws-item">
                  <div class="fws-box d-flex flex-wrap">
                    <div class="fws-img-box">
                      <Slider
                        {...settings1}
                        className="fws-image-slider wrapper-inner-slider add-custom-dots"
                      >
                        <div class="fws-image-item">
                          <div class="fws-image">
                            <img src="include/images/macaw-pix-1.jpg" alt="" />
                          </div>
                        </div>
                        <div class="fws-image-item">
                          <div class="fws-image">
                            <img src="include/images/macaw-pix-2.jpg" alt="" />
                          </div>
                        </div>
                        <div class="fws-image-item">
                          <div class="fws-image">
                            <img src="include/images/macaw-pix-3.jpg" alt="" />
                          </div>
                        </div>
                        <div class="fws-image-item">
                          <div class="fws-image">
                            <img src="include/images/macaw-pix-4.jpg" alt="" />
                          </div>
                        </div>
                      </Slider>
                    </div>
                    <div class="fws-text-box">
                      <h5>LENNY</h5>
                      <h6>(180 NFT)</h6>
                      <p>
                        {pathname === "en" &&
                          "Nervous, nonchalant but with big sense of loyalty, he is respected and a leading figure in Superwild Island."}
                        {pathname === "fr" &&
                          "Impulsif, nonchalant mais loyal, il est respecté dans toute l’île de SuperWild."}
                        {pathname === "es" &&
                          "Nervioso, despreocupado, pero con un gran sentido de la lealtad, es respetado y una figura destacada en Superwild Island."}
                      </p>
                      <b>
                        {pathname === "en" && "JOB"}
                        {pathname === "fr" && "JOB"}
                        {pathname === "es" && "TRABAJO"}
                      </b>

                      <p>
                        {pathname === "en" && "Private driver."}
                        {pathname === "fr" && "Chauffeur privé"}
                        {pathname === "es" && "Conductor privado."}
                      </p>

                      <b>
                        {pathname === "en" && "HOBBY"}
                        {pathname === "fr" && "HOBBIE"}
                        {pathname === "es" && "HOBBY"}
                      </b>
                      <p>
                        {pathname === "en" &&
                          "Writing rap songs & playing ball"}
                        {pathname === "fr" &&
                          "Écrire des chansons de rap et jouer au basket "}
                        {pathname === "es" &&
                          "Escribir canciones de rap y jugar a la pelota"}
                      </p>
                      <b>
                        {pathname === "en" && "FAVORITE WORDS"}
                        {pathname === "fr" && "MOTS FAVORIS"}
                        {pathname === "es" && "FAVORITE WORDS"}
                      </b>

                      <p>
                        {pathname === "en" && "You piss me off"}
                        {pathname === "fr" && "Tu me fais chier"}
                        {pathname === "es" && "Me enfadas"}
                      </p>
                      <b>
                        {pathname === "en" && "RARITY"}
                        {pathname === "fr" && "RARETÉ"}
                        {pathname === "es" && "RAREZA"}
                      </b>
                      <ul class="star-rating-row d-flex align-items-center">
                        <li>
                          <img src="include/images/star.png" alt="" />
                        </li>
                        <li>
                          <img src="include/images/star.png" alt="" />
                        </li>
                        <li>
                          <img src="include/images/star.png" alt="" />
                        </li>
                        <li>
                          <img src="include/images/star.png" alt="" />
                        </li>
                        <li>
                          <img src="include/images/star.png" alt="" />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Slider>
              <div class="col-nspc-row position-relative">
                <div
                  class="nspc-bg"
                  // ref={(el) => (this.parallexRef = el)}
                >
                  <img src="include/images/shape-bg-01.png" alt="" />
                </div>
                <div class="nsingle-para-text">
                  <div class="nsingle-para-image">
                    <img src="include/images/cobalt_ceather_3-1.png" alt="" />
                  </div>
                  {pathname === "en" && <h4>the specs</h4>}
                  {pathname === "fr" && <h4>les specs</h4>}
                  {pathname === "es" && <h4>LAS ESPECIFICACIONES</h4>}
                  {pathname === "en" && (
                    <>
                      {" "}
                      <p>
                        Each Super Animal is unique. Some species are rarer than
                        others, and some of them are legendaries.
                      </p>
                      <p>
                        The Super Animal are stored as ERC-721A tokens on the
                        Ethereum blockchain and hosted on IPFS.
                      </p>
                    </>
                  )}
                  {pathname === "fr" && (
                    <>
                      {" "}
                      <p>
                        Chaque Super Animal est unique. Des macaws sont plus
                        rares que d’autres, et certains sont même légendaires.
                      </p>
                      <p>
                        Les Super Animal sont stockés sous forme de jetons
                        ERC-721A sur la blockchain Ethereum et hébergés sur
                        IPFS.
                      </p>
                    </>
                  )}
                  {pathname === "es" && (
                    <>
                      {" "}
                      <p>
                        Cada Súper Animal es único. Algunas especies son más
                        raras que otras, y algunas de ellas son legendarias.
                      </p>
                      <p>
                        Los Super Animal se almacenan como tokens ERC-721A en la
                        cadena de bloques de Ethereum y se alojan en IPFS.
                      </p>
                    </>
                  )}
                </div>
              </div>
              <div class="row-btn text-center new-row-btn">
                <a
                  href="https://discord.com/invite/superwildclub"
                  target="_blank"
                  class="btn btn-default btn-md"
                >
                  <i class="fab fa-discord"></i>{" "}
                  {pathname === "en" && "join the club"}
                  {pathname === "fr" && "rejoins le club"}
                  {pathname === "es" && "ÚNETE AL CLUB"}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Collections;
