import React, { Component } from "react";

class RoadMap extends Component {
  render() {
    const pathname =
      typeof window !== undefined
        ? localStorage.getItem("lang")
          ? localStorage.getItem("lang")
          : "en"
        : "en";
    return (
      <div class="content-container roadmap-container" id="roadmap">
        <div class="container">
          <div class="row">
            <div class="col-md-12 offset-md-0 col-lg-10 offset-lg-1">
              <div class="roadmap-heading d-flex align-items-center">
                <div class="heading-text">
                  <h4>
                    <span>roadmap</span>
                  </h4>
                </div>
                <div class="roadmap-thumbnail ms-auto">
                  <img src="include/images/superwild-club.png" alt="" />
                </div>
              </div>
              <ul class="block-card-list">
                <li class="bcl-item">
                  <div class="bcl-box w-100 h-100 d-flex flex-wrap">
                    <div class="bcl-icon-box d-flex align-items-center justify-content-center position-relative">
                      <img src="include/images/q-1.svg" alt="" />{" "}
                      <span>Q1-Q2 2022</span>
                    </div>
                    {pathname === "en" && (
                      <div class="bcl-text-box">
                        <b>superwild club is born</b>
                        <ul>
                          <li>
                            Artistic & graphic development of the Macaws, the
                            first SuperWild Animals collection.
                          </li>
                          <li>
                            Introducing ourselves to the community and
                            deployement of all the marketing assets such as
                            keynote, teaser, social media content.
                          </li>
                          <li>
                            Managing strategic alliances and partnerships.
                          </li>
                        </ul>
                      </div>
                    )}
                    {pathname === "fr" && (
                      <div class="bcl-text-box">
                        <b>le superwild club est né</b>
                        <ul>
                          <li>
                            Développement artistique et graphique des Macaws, la
                            première collection d’animaux SuperWild.
                          </li>
                          <li>
                            Introduction du projet et de l’équipe à la
                            communauté, et déploiement des supports marketing
                            tels que la keynote, le teaser, le contenu des
                            réseaux sociaux, ...
                          </li>
                          <li>
                            Mise en place de collaborations et de partenariats.
                          </li>
                        </ul>
                      </div>
                    )}
                    {pathname === "es" && (
                      <div class="bcl-text-box">
                        <b>NACE EL SUPERWILDCLUB</b>
                        <ul>
                          <li>
                            Desarrollo artístico y gráfico de los Guacamayos, la
                            primera colección de SuperWild Animals.
                          </li>
                          <li>
                            Presentarnos a la comunidad y desplegar todos los
                            activos de marketing, como el discurso principal, el
                            adelanto y el contenido de las redes sociales.
                          </li>
                          <li>
                            Gestión de alianzas y asociaciones estratégicas
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </li>
                <li class="bcl-item">
                  <div class="bcl-box w-100 h-100 d-flex flex-wrap">
                    <div class="bcl-icon-box d-flex align-items-center justify-content-center position-relative">
                      <img src="include/images/q-2.svg" alt="" />{" "}
                      <span>Q2-Q3 2022</span>
                    </div>
                    {pathname === "fr" && (
                      <div class="bcl-text-box">
                        <b>
                          adhères au superwild club et fais partie du futur !
                        </b>
                        <ul>
                          <li> Phase de mint et reveal des Macaws</li>
                          <li>
                            Les membres SuperWild choisiront la prochaine
                            famille qu’ils souhaitent voir apparaitre entre les
                            Rhinos et les Lémuriens.
                          </li>
                          <li>Mise en place du e-shop </li>
                          <li>Design du merchandising</li>
                        </ul>
                      </div>
                    )}
                    {pathname === "en" && (
                      <div class="bcl-text-box">
                        <b>
                          get your superwild club membership & be part of the
                          future
                        </b>
                        <ul>
                          <li> Mint phase and reveal of the Macaws</li>
                          <li>
                            SuperWild Members will choose the next family they
                            want to be revealed between the Rhino or the Lemur
                          </li>
                          <li>Building the e-shop </li>
                          <li>Designing the merchandising</li>
                        </ul>
                      </div>
                    )}
                    {pathname === "es" && (
                      <div class="bcl-text-box">
                        <b>
                          OBTÉN TU MEMBRESÍA SUPERWILD CLUB Y SE PARTE DEL
                          FUTURO
                        </b>
                        <ul>
                          <li>Fase de mint y revelación de los Guacamayos</li>
                          <li>
                            Los miembros de SuperWild elegirán la próxima
                            familia que quieren que se revele entre RINOCERONTE
                            o Lemur
                          </li>
                          <li>Creación de la tienda electrónica </li>
                          <li>Diseño del merchandising</li>
                        </ul>
                      </div>
                    )}
                  </div>
                </li>
                <li class="bcl-item">
                  <div class="bcl-box w-100 h-100 d-flex flex-wrap">
                    <div class="bcl-icon-box d-flex align-items-center justify-content-center position-relative">
                      <img src="include/images/q-3.svg" alt="" />{" "}
                      <span>Q3-Q4 2022</span>
                    </div>
                    {pathname === "en" && (
                      <div class="bcl-text-box">
                        <b>we keep going & build the team</b>
                        <ul>
                          <li>
                            Development and release the second collection chosen
                            by the community.
                          </li>
                          <li>
                            First interviews of animations specialist in order
                            to build the animation studio.
                          </li>
                          <li>
                            Writing the scenarios, storyboards for the first
                            episode.
                          </li>
                          <li>
                            Getting in touch with actors for the voice-over.
                          </li>
                          <li>
                            Rewards coming for the first SuperWild Animal
                            holders. Stay tuned !
                          </li>
                        </ul>
                      </div>
                    )}
                    {pathname === "fr" && (
                      <div class="bcl-text-box">
                        <b>on continue les efforts et on créé l’équipe</b>
                        <ul>
                          <li>
                            Développement et sortie de la 2ème collection,
                            choisie par la communauté
                          </li>
                          <li>
                            Premiers entretiens de profils afin de monter
                            l’équipe du studio d’animation.
                          </li>
                          <li>
                            Écriture du scénario et storyboards du premier
                            épisode.
                          </li>
                          <li>Contact d’acteurs pour les voix-off</li>
                          <li>
                            Des récompenses arrivent pour les holders des
                            premiers jours. Restez connectés !
                          </li>
                        </ul>
                      </div>
                    )}
                    {pathname === "es" && (
                      <div class="bcl-text-box">
                        <b>SEGUIMOS ADELANTE Y EMPEZAMOS A FORMAR EL EQUIPO</b>
                        <ul>
                          <li>
                            Desarrollo y lanzamiento de la segunda colección
                            elegida por la comunidad
                          </li>
                          <li>
                            Primeras entrevistas de especialistas en animaciones
                            para iniciar el estudio de animación.
                          </li>
                          <li>
                            Redacción de los escenarios, storyboards del primer
                            episodio
                          </li>
                          <li>
                            Ponerse en contacto con los actores para las voces
                            de los episodios
                          </li>
                          <li>
                            Recompensas próximas para los primeros poseedores de
                            SuperWild Animal. ¡Manténganse en contacto!
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </li>
                <li class="bcl-item">
                  <div class="bcl-box w-100 h-100 d-flex flex-wrap">
                    <div class="bcl-icon-box d-flex align-items-center justify-content-center position-relative">
                      <img src="include/images/q-4.svg" alt="" />{" "}
                      <span>Q1-Q2 2023</span>
                    </div>
                    {pathname === "en" && (
                      <div class="bcl-text-box">
                        <b>new collections</b>
                        <ul>
                          <li>
                            Development and release the third collection chosen
                            by the community.
                          </li>
                          <li>
                            SuperWild Studio focusing and working day and night
                            on the first episode.
                          </li>
                          <li>
                            Strategic alliances and partnerships in
                            metaverse/VR.
                          </li>
                        </ul>
                      </div>
                    )}
                    {pathname === "fr" && (
                      <div class="bcl-text-box">
                        <b>nouvelles collections</b>
                        <ul>
                          <li>
                            Développement et sortie de la 3ème collection,
                            choisie par la communauté
                          </li>
                          <li>
                            SuperWild Studio est concentré ! On travaille jour
                            et nuit sur le premier épisode.
                          </li>
                          <li>
                            Mise en place de collaborations et de partenariats
                            dans le Metaverse. /VR.
                          </li>
                        </ul>
                      </div>
                    )}
                    {pathname === "es" && (
                      <div class="bcl-text-box">
                        <b>NUEVAS COLECCIONES</b>
                        <ul>
                          <li>
                            Desarrollo y lanzamiento de la tercera colección
                            elegida por la comunidad.
                          </li>
                          <li>
                            Superwildclub Estudio enfocándose y trabajando día y
                            noche en el primer episodio.
                          </li>
                          <li>
                            Alianzas estratégicas y asociaciones en
                            metaverso/realidad virtual.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </li>
                <li class="bcl-item">
                  <div class="bcl-box w-100 h-100 d-flex flex-wrap">
                    <div class="bcl-icon-box d-flex align-items-center justify-content-center position-relative">
                      <img src="include/images/q-6.svg" alt="" />{" "}
                      <span>Q3-Q4 2023</span>
                    </div>
                    {pathname === "en" && (
                      <div class="bcl-text-box">
                        <b>and we go live !</b>
                        <ul>
                          <li>
                            Release of the first episode on streaming platforms.
                          </li>
                        </ul>
                      </div>
                    )}
                    {pathname === "fr" && (
                      <div class="bcl-text-box">
                        <b>et c’est parti !</b>
                        <ul>
                          <li>
                            Sortie du premier épisode sur les plateformes de
                            streaming.
                          </li>
                        </ul>
                      </div>
                    )}
                    {pathname === "es" && (
                      <div class="bcl-text-box">
                        <b>¡Y SALIMOS EN VIVO!</b>
                        <ul>
                          <li>
                            Lanzamiento del primer episodio en plataformas de
                            streaming.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </li>
                <li class="bcl-item">
                  <div class="bcl-box w-100 h-100 d-flex flex-wrap">
                    <div class="bcl-icon-box d-flex align-items-center justify-content-center position-relative">
                      <img src="include/images/q-5.svg" alt="" />{" "}
                      <span>Q1 2024</span>
                    </div>
                    {pathname === "fr" && (
                      <div class="bcl-text-box">
                        <b>en route vers le metaverse</b>
                        <ul>
                          <li>
                            Profite de ton Super Animal dans le metaverse/VR
                          </li>
                        </ul>
                      </div>
                    )}
                    {pathname === "en" && (
                      <div class="bcl-text-box">
                        <b>flying to the metaverse</b>
                        <ul>
                          <li>
                            Enjoy your super SuperWild Animal in the
                            metaverse/VR
                          </li>
                        </ul>
                      </div>
                    )}
                    {pathname === "es" && (
                      <div class="bcl-text-box">
                        <b>VOLANDO AL METAVERSO</b>
                        <ul>
                          <li>
                            Disfruta de tu súper SuperWild Animal en el
                            metaverso/VR
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RoadMap;
