import React, { Component } from "react";

class Icon extends Component {
  render() {
    const pathname =
      typeof window !== undefined
        ? localStorage.getItem("lang")
          ? localStorage.getItem("lang")
          : "en"
        : "en";
    return (
      <div class="content-container collaboration-container pb-0">
        <div class="container">
          <div class="row">
            <div class="col-md-12 offset-md-0 col-lg-10 offset-lg-1">
              <div class="heading-text large">
                <h4>
                  <span>
                    {pathname === "en" && "collaborations"}
                    {pathname === "fr" && "collaborations"}
                    {pathname === "es" && "COLABORACIONES"}
                  </span>
                  {/* {pathname==="en" && <span>collaborations</span>}
                  {pathname==="fr" && <span>collaborations</span>}
                  {pathname==="es" && <span>COLABORACIONES</span>} */}
                </h4>
              </div>
              {pathname === "en" && (
                <p class="center-text">Here our partner's to help the planet</p>
              )}
              {pathname === "fr" && (
                <p class="center-text">
                  Voici les partenaires avec qui nous aidons la planète
                </p>
              )}
              {pathname === "es" && (
                <p class="center-text">
                  Aquí nuestros socios para ayudar al planeta
                </p>
              )}
              <div class="row-btn text-start">
                <a
                  href="https://discord.com/invite/superwildclub"
                  target="_blank"
                  class="btn btn-default btn-md"
                >
                  <i class="fab fa-discord"></i>{" "}
                  {pathname === "en" && "join the club"}
                  {pathname === "fr" && "rejoins le club"}
                  {pathname === "es" && "ÚNETE AL CLUB"}
                </a>
              </div>
              <ul class="logo-list d-flex flex-wrap">
                <li class="logo-item">
                  <a
                    href="https://fundacioncalisur.org/"
                    target="_blank"
                    class="logo-img-box"
                  >
                    <img src="include/images/1.svg" alt="" />
                  </a>
                </li>
                <li class="logo-item">
                  <a
                    href="https://fundacionjambeli.org/"
                    class="logo-img-box"
                    target="_blank"
                  >
                    <img src="include/images/2.svg" alt="" />
                  </a>
                </li>
                <li class="logo-item">
                  <a
                    href="https://amiguitosdeloceano.com/"
                    class="logo-img-box"
                    target="_blank"
                  >
                    <img src="include/images/3.svg" alt="" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Icon;
