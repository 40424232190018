import React, { Component } from "react";

class Header extends Component {
  render() {
    const pathname =
      typeof window !== undefined
        ? localStorage.getItem("lang")
          ? localStorage.getItem("lang")
          : "en"
        : "en";
    const route = window.location.pathname;
    return (
      <header id="header">
        <nav class="navbar navbar-expand-md">
          <div class="container-fluid">
            <div class="nav-inside d-flex align-items-center justify-content-between">
              <button class="nav-icon" onClick={this.props.toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </button>
              <div class="collapse navbar-collapse" id="mainNav">
                <div class="navbar-inside">
                  <ul class="navbar-nav">
                    <li class="nav-item" onClick={this.props.toggleMenu}>
                      <a class="nav-link" href="#theProject">
                        {pathname === "en" && "the project"}
                        {pathname === "fr" && "le projet"}
                        {pathname === "es" && "el PROYECTO"}
                      </a>
                    </li>
                    <li class="nav-item" onClick={this.props.toggleMenu}>
                      <a class="nav-link" href="#collaborativeStudio">
                        {pathname === "en" && "collaborative studio"}
                        {pathname === "fr" && "studio collaboratif"}
                        {pathname === "es" && "ESTUDIO COLLABORATIVO"}
                      </a>
                    </li>
                    <li class="nav-item" onClick={this.props.toggleMenu}>
                      <a class="nav-link" href="#rewards">
                        {pathname === "en" && "rewards"}
                        {pathname === "fr" && "Recompenses"}
                        {pathname === "es" && "RECOMPENSAS"}
                      </a>
                    </li>
                    <li class="nav-item" onClick={this.props.toggleMenu}>
                      <a class="nav-link" href="#roadmap">
                        {pathname === "en" && "roadmap"}
                        {pathname === "fr" && "roadmap"}
                        {pathname === "es" && "roadmap"}
                      </a>
                    </li>
                    <li class="nav-item" onClick={this.props.toggleMenu}>
                      <a class="nav-link" href="#faq">
                        {pathname === "en" && "faq"}
                        {pathname === "fr" && "faq"}
                        {pathname === "es" && "PREGUNTAS FRECUENTES"}
                      </a>
                    </li>
                    <li class="nav-item" onClick={this.props.toggleMenu}>
                      <a class="nav-link" href="#team">
                        {pathname === "en" && "team"}
                        {pathname === "fr" && "equipe"}
                        {pathname === "es" && "EQUIPO"}
                      </a>
                    </li>
                    {/* <li class="nav-item" onClick={this.props.toggleMenu}>
                      <a class="nav-link" href="#team">
                        whitepaper
                      </a>
                    </li> */}
                  </ul>
                  <div class="nav-inside-content d-block d-lg-none text-center">
                    {/* <a href="#!" class="header-btn">
                      mint now
                    </a> */}
                    <ul class="social-link d-flex align-items-center">
                      <li>
                        <a
                          href="https://www.instagram.com/superwildclub/"
                          target="_blank"
                          rel="noopener"
                        >
                          <i class="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/superwildclub"
                          target="_blank"
                          rel="noopener"
                        >
                          <i class="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://discord.com/invite/superwildclub"
                          target="_blank"
                          rel="noopener"
                        >
                          <i class="fab fa-discord"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="header-right ms-auto d-flex align-items-center">
                <div class="hr-item">
                  <ul class="select-lang-list d-flex align-items-center">
                    <li class={pathname === "en" ? "selected" : ""}>
                      <a
                        href="javascript:void(0)"
                        onClick={() => {
                          localStorage.setItem("lang", "en");
                          window.location.reload();
                        }}
                      >
                        ENG
                      </a>
                    </li>
                    <li class={pathname === "fr" ? "selected" : ""}>
                      <a
                        href="javascript:void(0)"
                        onClick={() => {
                          localStorage.setItem("lang", "fr");
                          window.location.reload();
                        }}
                      >
                        FR
                      </a>
                    </li>
                    <li class={pathname === "es" ? "selected" : ""}>
                      <a
                        href="javascript:void(0)"
                        onClick={() => {
                          localStorage.setItem("lang", "es");
                          window.location.reload();
                        }}
                      >
                        ES
                      </a>
                    </li>
                  </ul>
                </div>
                {route === "/home-2" && (
                  <div class="hr-item d-none d-lg-block">
                    <a
                      href="javascript:void(0)"
                      class="header-btn"
                      onClick={() => this.props.history.push("/mint")}
                    >
                      mint now
                    </a>
                  </div>
                )}
                <div class="hr-item d-none d-lg-block">
                  <ul class="social-link d-flex align-items-center">
                    <li>
                      <a
                        href="https://www.instagram.com/superwildclub/"
                        target="_blank"
                        rel="noopener"
                      >
                        <i class="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/superwildclub"
                        target="_blank"
                        rel="noopener"
                      >
                        <i class="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://discord.com/invite/superwildclub"
                        target="_blank"
                        rel="noopener"
                      >
                        <i class="fab fa-discord"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default Header;
