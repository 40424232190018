import React, { Component, createRef } from "react";
import { gsap } from "gsap";

class CollaborativeBottom extends Component {
  parallexRef = createRef(null);
  componentDidMount = () => {
    const parallex = [this.parallexRef];
    parallex.forEach((cb) =>
      gsap.to(cb, {
        y: -100,
        ease: "none",
        scrollTrigger: {
          trigger: cb,
          start: "top bottom",
          scrub: true,
          //markers:true,
        },
      })
    );
  };
  render() {
    const pathname =
      typeof window !== undefined
        ? localStorage.getItem("lang")
          ? localStorage.getItem("lang")
          : "en"
        : "en";
    return (
      <div class="content-container howtobuy-container py-0 top-align">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              {/* {pathname==="en" && (
                <h5 class="section-title">
                  *we will be able to release the first episode of the serie
                  after 67% the sales of the first collections.
                </h5>
              )} */}
              {/* {pathname==="fr" && (
                <h5 class="section-title">
                  *Nous serons en mesure de sortir le premier episode de la
                  serie avec un minimum de 67% des premieres ventes.
                </h5>
              )} */}
              {/* {pathname==="es" && (
                <h5 class="section-title">
                  *Podremos lanzar el primer episodio de la serie despues del
                  67% de las ventas de las primeras colecciones.
                </h5>
              )} */}
              <div class="position-relative">
                <div class="howtobuy-bg">
                  <img src="include/images/shape-33.png" alt="" />
                </div>
                <div class="howtobuy-para-text position-relative">
                  <div class="howtobuy-image is-parallax">
                    <img src="include/images/Cobalt_Feather_2-2.png" alt="" />
                  </div>
                  <div class="heading-text text-center add-dark-line">
                    <h4>
                      {pathname === "en" && <span>how to buy</span>}
                      {pathname === "fr" && <span>comment acheter</span>}
                      {pathname === "es" && <span>Como COMPRAR</span>}
                    </h4>
                  </div>
                  {pathname === "en" && (
                    <p>
                      If you are new to NFT and don’t know how to buy one, you
                      can check the official guide edited by Coinbase
                    </p>
                  )}
                  {pathname === "fr" && (
                    <p>
                      Si tu es nouveau dans le monde des NFTs et que tu ne sais
                      pas comment en acheter, tu peux regarder le guide officiel
                      Coinbase :
                    </p>
                  )}
                  {pathname === "es" && (
                    <p>
                      Si eres nuevo en NFT y no sabes cómo comprar uno, puedes
                      consultar la guía oficial editada por Coinbase:
                    </p>
                  )}
                  <div class="row-btn text-center mar">
                    <a
                      href="https://www.coinbase.com/fr/learn/crypto-basics/how-to-buy-nft"
                      target="_blank"
                      class="btn btn-default white"
                    >
                      {pathname === "en" && "read the guide"}
                      {pathname === "fr" && "lire le guide"}
                      {pathname === "es" && "leer la guia"}
                    </a>
                  </div>
                  <div
                    class="howtobuy-image-1 is-parallax"
                    ref={(el) => (this.parallexRef = el)}
                  >
                    <img
                      src="include/images/Buffon's-Ara_Feather-4.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CollaborativeBottom;
