import React, { Component, createRef } from "react";
import { gsap } from "gsap";

class HeroSubSection extends Component {
  parallexRef1 = createRef(null);
  parallexRef2 = createRef(null);
  componentDidMount = () => {
    const parallex = [this.parallexRef1, this.parallexRef2];
    parallex.forEach((cb) =>
      gsap.to(cb, {
        y: -100,
        ease: "none",
        scrollTrigger: {
          trigger: cb,
          start: "top bottom",
          scrub: true,
          //markers:true,
        },
      })
    );
  };
  render() {
    return (
      <>
        <div class="count-block-row">
          <div
            class="cbr-img-left is-parallax"
            ref={(el) => (this.parallexRef1 = el)}
          >
            <img src="include/images/Cobalt_Feather_3-2.png" alt="" />
          </div>

          <h3>whitelist mint is live now</h3>

          <div class="row-btn text-center">
            <a
              href="javascript:void(0)"
              class="btn btn-default btn-md white"
              id="btn-min-width"
              onClick={() => this.props.history.push("/mint")}
            >
              mint now
            </a>
          </div>

          <div
            class="cbr-img-right is-parallax"
            ref={(el) => (this.parallexRef2 = el)}
          >
            <img src="include/images/Buffon's-Ara_Feather_3-2.png" alt="" />
          </div>
        </div>

        <h2 class="disclaimer-text">
          Disclaimer: All investments involve risk and may result in a profit as
          well as a total loss.
        </h2>
      </>
    );
  }
}
export default HeroSubSection;
